import {poJD} from "./po/po.js_desktop";
import {configData} from "./configData";
import {configService} from "./services/configService";
import {sanitizer} from "./helpers/sanitizer";
import uliBase from "./uliBase";
import uliClose from "./desktop/uliClose";
import uliInit from "./desktop/uliInit";
import uliPrefill from "./desktop/uliPrefill";
import uliCallbacks from "./desktop/uliCallbacks";
import uliListener from "./desktop/uliListener";
import uliLayout from "./desktop/uliLayout";
import uliError from "./desktop/uliError";
import uliTrigger from "./desktop/uliTrigger";
import ajax from "./ajax";
import passwordStrength from "./helpers/passwordStrength";
import setTracking from "./setTracking";

require('./polyfills/elementMatches');
require('./polyfills/elementClosest');
require('./polyfills/elementRemove');
require('./polyfills/arrayForEach');
require('./polyfills/windowCustomEvent');

// custom Event unified-login
var C24ulUserData = {
    "BROWSERBACK": false,
    "USERLOGIN": "",
    "USERPHONE": "",
    "USEREMAIL": "",
    "USERPOINTS": "",
    "STATE": "",
    "VIEW": "",
    "LOGINTYPE": "",
    "SSO_SERVER": "",
    "SSO_ENV": "",
    "SSO_SESSION": ""
};
var ulEvent = new CustomEvent("unified-login", {
    detail: {
        ul: C24ulUserData
    }
});

// Basic functions
var Check24 = uliBase;

// Get Client Hints
Check24.getClientHints = function() {
    try {
        navigator.userAgentData.getHighEntropyValues([
            "architecture",
            "model",
            "platform",
            "platformVersion",
            "fullVersionList"
        ]).then((clientHints) => {
            if (("fullVersionList" in clientHints) && clientHints.fullVersionList.length) {
                Check24.ul.userData.clientHints = clientHints;
                Check24.ul.clientHintsFound = true;
            } else {
                Check24.ul.clientHintsFound = false;
            }
        });
    } catch (e) {
        Check24.ul.clientHintsFound = false;
    }
};

// SSO PW-Strength Lib
Check24.PasswordStrength = passwordStrength;

// Unified Login Object
Check24.ul = {
    requestRunning: false,
    // DATA
    configData: configData,
    useTurnstile: false,
    turnstileMode: '0',
    turnstileLoading: false,
    state: "",
    stateOption: "",
    loginState: "",
    loginSession: "",
    initShowPoints: false,
    userData: {
        exists: null,
        email: null,
        phone: null,
        terms: false,
        lastLoginProvider: null,
        superAccount: false,
        points: false,
        passwordSet: false,
        loginProvider: [],
        loginType: null,
        initialExisted: null,
        clientHints: {
            browser: null,
            browserVersion: null,
            deviceName: null,
            os: null,
            osVersion: null
        }
    },
    clientHintsFound: false,
    pointsChecked: false,
    termsChanged: false,
    crossDomain: false,
    userChange: false,
    userSession: null,
    existsTriggered: false,
    pwReset2FA: false,
    pwResetData: {
        id: null,
        password: null,
        mcode: null,
        ecode: null,
        zipcode: null,
        birthday: null,
        loginInstead: "no",
        questions: null,
        method: null,
        recipient: null,
        sq: null
    },
    tanData: {
        id: null,
        code: null,
        context: null,
        method: null,
        methodOverwrite: false,
        valid: false,
        pw: null,
        firstname: null,
        lastname: null,
        consecutiveTan: null,
        type: "email",
        key: null
    },
    tanResendTimer: null,
    tanResendTimeout: null,
    loginProcessData: {
        id: null,
        recipient: null,
        response: null
    },
    tanLoginProcessStarted: false,
    loginChallengeData: {
        id: null,
        login: null,
        loginType: null,
        type: null,
        response: null,
        questions: {
            zipcode: null,
            birthday: null
        },
        questionMode: null,
        responseMode: null,
        zipcode: null,
        birthday: null
    },
    email: "",
    phone: "",
    userLoginEmail: null,
    userLoginPhone: null,
    userLoginType: null,
    userLoginPhoneTriggered: false,
    userGender: null,
    userFirstName: null,
    userLastName: null,
    userName: null,
    userState: null,
    forcePoints: false,
    prefilled: false,
    hotline: "089 - 24 24 12 30",
    hotlineClean: "08924241230",
    initState: null,
    initOption: null,
    loginPath: "",
    server: "",
    ssoEnv: "",
    view: "",
    passwordLengthMin: 8,
    passwordLengthMax: 50,
    registerList: [
        poJD.registerList.item_1,
        poJD.registerList.item_2,
        poJD.registerList.item_3,
    ],
    pwInfoListStrong: [
        poJD.pw_reset.secure_password_strong_item_1,
        poJD.pw_reset.secure_password_strong_item_2,
        poJD.pw_reset.secure_password_strong_item_3,
        poJD.pw_reset.secure_password_strong_item_4
    ],
    loginData: null,
    convertedPhone: null,
    pwInputDeleteEnabled: false,
    pointsXtreme: {
        title: "",
        content: ""
    },
    browserBackOccured: false,
    splitInput: {
        commandActive: false,
        shiftActive: false
    },

    // INIT
    initialized: false,
    uliReseted: false,
    init: uliInit,
    userPrefill: uliPrefill,
    initListener: function() {
        if (configService.getEnv() === "default") {
            if (window.addEventListener) {
                window.addEventListener("message", Check24.ul.handleDocMsg, false);
                window.addEventListener("resize", Check24.ul.resetHeight, false);
            } else if (window.attachEvent) {
                window.attachEvent("onmessage", Check24.ul.handleDocMsg);
                window.attachEvent("resize", Check24.ul.resetHeight);
            }
        } else {
            window.removeEventListener('unified-login-push', Check24.ul.listener.browserBackListener, false);
            window.addEventListener('unified-login-push', Check24.ul.listener.browserBackListener, false);
        }
    },
    trigger: uliTrigger,

    // INITVIEWS
    loginInit: false,
    loginPrefill: false,
    loginType: null,
    loginEmail: null,
    loginPhone: null,
    login: function() {
        if (!Check24.ul.loginInit) {
            Check24.addEvent("#cl_login", "keypress", function(e) {
                if (e.keyCode === 9) {
                    e.preventDefault();
                }
            });
        }
        Check24.ul.resetDefaults();
        Check24.ul.registerTriggered = false;
        Check24.ul.registercheckTriggered = false;
        Check24.ul.userData.initialExisted = null;
        Check24.ul.userData.loginType = null;
        Check24.ul.existsTriggered = false;

        Check24.change("value", "#c24-uli-login-btn", poJD.login.proceed_button);
        Check24.removeEvent(".c24-uli-login", "submit", Check24.ul.listener.pwListener);
        Check24.addEvent(".c24-uli-login", "submit", Check24.ul.listener.loginListener);

        Check24.ul.loginPrefill = false;
        if (!Check24.ul.loginInit) {
            if (Check24.ul.userLoginType === null) {
                if (Check24.ul.userLoginEmail !== null) {
                    Check24.change("value", "#cl_login", Check24.ul.userLoginEmail);
                    Check24.change("type", "#cl_login", "email");
                } else if (Check24.ul.userLoginPhone !== null) {
                    Check24.change("value", "#cl_login", Check24.ul.userLoginPhone);
                    Check24.change("type", "#cl_login", "tel");
                }
            }
        }
        Check24.ul.loginInit = true;

        if (Check24.getValue("#cl_login") !== "") {
            Check24.document.querySelector("#cl_login").parentElement.classList.add("active");
        } else {
            Check24.document.querySelector("#cl_login").parentElement.classList.remove("active");
        }

        if (configService.getSocialRegistration() === "true") {
            Check24.removeEvent(".c24-uli-cl-google", "click", Check24.ul.listener.googleListener);
            Check24.addEvent(".c24-uli-cl-google", "click", Check24.ul.listener.googleListener);
        }
    },
    pwInit: false,
    pw: function() {
        if (!Check24.ul.pwInit) {
            Check24.ul.pwInit = true;
        }
        if (Check24.ul.userData.terms === true) {
            Check24.hide(".c24-uli-agb-wrapper");
        } else {
            Check24.show(".c24-uli-agb-wrapper");
        }
        window.setTimeout(function() {
            Check24.document.querySelector("#cl_pw_login").focus({preventScroll:true});
        }, 0);

        Check24.ul.tanLoginProcessStarted = false;

        Check24.removeClass(".c24-uli-pw", "c24-uli-haspw");
        if (Check24.ul.userData.loginProvider.includes("google") && Check24.ul.userData.passwordSet !== false) {
            Check24.addClass(".c24-uli-pw", "c24-uli-haspw");
            Check24.removeEvent(".c24-uli-cl-google", "click", Check24.ul.listener.googleListener);
            Check24.addEvent(".c24-uli-cl-google", "click", Check24.ul.listener.googleListener);
            Check24.change("html", ".c24-uli-top-title", poJD.social.top_title);
        }

        Check24.removeEvent(".c24-uli-login", "submit", Check24.ul.listener.loginListener);
        Check24.addEvent(".c24-uli-login", "submit", Check24.ul.listener.pwListener);

        Check24.ul.resetHeight();
    },
    loginOtherInit: false,
    loginOther: function() {
        if (!Check24.ul.loginOtherInit) {
            Check24.ul.loginOtherInit = true;
        }
        if (Check24.ul.loginEmail === "other") {
            Check24.ul.loginPhone = null;
            Check24.change("value", "#cl_login_ph", Check24.ul.email);
            Check24.change("html", ".c24-uli-user-data", sanitizer.html(Check24.ul.email));
            Check24.removeClass(".c24-uli-login", "c24-uli-login-phone");
        } else if (Check24.ul.loginPhone=== "other") {
            Check24.ul.loginEmail = null;
            Check24.change("value", "#cl_login_ph", Check24.ul.phone);
            Check24.change("html", ".c24-uli-user-data", sanitizer.html(Check24.ul.phone));
            Check24.addClass(".c24-uli-login", "c24-uli-login-phone");
        }

        Check24.ul.resetHeight();
        Check24.removeEvent(".c24-uli-login-other", "submit", Check24.ul.listener.loginOtherListener);
        Check24.addEvent(".c24-uli-login-other", "submit", Check24.ul.listener.loginOtherListener);
    },
    social: function(type) {
        Check24.removeEvent(".c24-uli-cl-google", "click", Check24.ul.listener.googleListener);
        Check24.addEvent(".c24-uli-cl-google", "click", Check24.ul.listener.googleListener);

        if (Check24.ul.userData.terms === true) {
            Check24.hide(".c24-uli-agb-wrapper");
        } else {
            Check24.show(".c24-uli-agb-wrapper");
        }

        var login = Check24.getValue("#cl_login_ph");
        if (type === "facebook") {
            Check24.removeEvent(".c24-uli-social-form", "submit", Check24.ul.listener.socialListener);
            if (Check24.ul.loginType === "phone") {
                Check24.change("html", ".c24-uli-social-login", poJD.social.login.mobile);
                Check24.change("html", ".c24-uli-login-wrapper", sanitizer.html(login));
                Check24.ul.pwResetData.id = login;
                Check24.ul.pwResetMode = "phone";
                Check24.addEvent(".c24-uli-social-form", "submit", Check24.ul.listener.socialListener);
            } else {
                Check24.change("html", ".c24-uli-social-login", poJD.social.login.email);
                Check24.change("html", ".c24-uli-login-wrapper", sanitizer.html(login));
                Check24.ul.pwResetData.id = login;
                Check24.ul.pwResetMode = "email";
                Check24.addEvent(".c24-uli-social-form", "submit", Check24.ul.listener.socialListener);
            }
        } else {
            Check24.change("html", ".c24-uli-top-title", poJD.social.top_title);
        }
        Check24.ul.resetHeight();
    },
    registerTriggered: false,
    registercheckTriggered: false,
    registerAnonym: false,
    registerInit: false,
    registerMode: null,
    register: function() {
        if (!Check24.ul.registerInit) {
            Check24.ul.registerInit = true;
        }
        var loginElement    = Check24.document.getElementById('cl_login_ph');
        var passwordElement = Check24.document.getElementById('cl_pw_register');
        var wording         = Check24.document.getElementById('c24-uli-cl-pw-strength-value');
        var bar             = Check24.document.getElementById('c24-uli-cl-pw-strength-status');
        Check24.change("value", "#cl_pw_register", "");
        Check24.change("value", "#cl_ul_pw_register_repeat", "");
        Check24.PasswordStrength.init(wording, bar, loginElement, passwordElement);
        Check24.change("html", ".c24-uli-cl-pw-strength-value", "");
        Check24.ul.registerTriggered = true;
        Check24.ul.registerAnonym = false;
        Check24.removeClass(".c24-uli-cl-box", "active");
        Check24.addClass(".c24-uli-cl-r-known", "active");
        Check24.show(".c24-uli-agb-wrapper");

        if (Check24.ul.loginType === "phone") {
            Check24.show([".c24-uli-bottom-toggle", ".c24-uli-bottom-l-email"]);
            Check24.hide(".c24-uli-bottom-l-phone");
            Check24.ul.registerMode = "secure";
            Check24.change("html", ".c24-uli-login-type", poJD.register.login.mobile);
        } else {
            Check24.show([".c24-uli-bottom-toggle", ".c24-uli-bottom-l-phone"]);
            Check24.hide(".c24-uli-bottom-l-email");
            if (configService.getRegisterType() === "secure") {
                Check24.ul.registerMode = "secure";
            } else {
                Check24.ul.registerMode = null;
            }
            Check24.change("html", ".c24-uli-login-type", poJD.register.login.email);
        }

        Check24.removeEvent(".c24-uli-register-form", "submit", Check24.ul.listener.registerListener);
        Check24.addEvent(".c24-uli-register-form", "submit", Check24.ul.listener.registerListener);
    },
    registercheck: function(mode) {
        Check24.removeEvent(".c24-uli-registercheck-form", "submit", Check24.ul.listener.registercheckListener);
        Check24.addEvent(".c24-uli-registercheck-form", "submit", Check24.ul.listener.registercheckListener);
    },
    pwRepeat: function(id, idRepeat) {
        var pw       = Check24.getValue(id);
        var pwRepeat = Check24.getValue(idRepeat);
        return pw !== "" && pwRepeat !== "" && pw === pwRepeat;
    },
    pwResetRecipient: null,
    pwResetMode: null,
    pwResetInit: false,
    pwReset: function(mode) {
        if (!Check24.ul.pwResetInit) {
            Check24.ul.pwResetInit = true;
        }
        var loginElement    = Check24.document.getElementById('cl_login_ph');
        var passwordElement = Check24.document.getElementById('cl_pwr_pw');
        var wording         = Check24.document.getElementById('c24-uli-cl-pw-strength-value-pwr');
        var bar             = Check24.document.getElementById('c24-uli-cl-pw-strength-status-pwr');

        Check24.change("html", ".c24-uli-user-data", loginElement.value);

        if (mode === "check") {
            Check24.addClass(".c24-uli-cl-pwreset-trigger .c24-uli-loading", "loading");
            Check24.ul.ajax.pwResetTan(Check24.ul.pwResetMode, Check24.ul.pwResetData, Check24.ul.loginType, Check24.ul.callback.pwReset);
            return;
        }

        Check24.hide(".c24-uli-cl-pwreset-sq-trigger");
        if (Check24.ul.pwResetData.questions && Check24.ul.pwResetData.questions.length > 0) {
            var data = Check24.ul.pwResetData.questions[Check24.ul.sqCurrentIndex];
            Check24.ul.sqCurrent.id = data.question_id;
            Check24.ul.sqCurrent.product = data.product;
            Check24.change("html", ".c24-uli-sq-question", data.question);
            Check24.show(".c24-uli-cl-pwreset-sq-trigger", "inline");
        }
        Check24.PasswordStrength.init(wording, bar, loginElement, passwordElement);
        Check24.change("html", ".c24-uli-cl-pw-strength-value", "");
        Check24.change("value", ".c24-uli-pwr-mtan .c24-uli-input-splitted", "");
        Check24.change("value", ".c24-uli-pwr-etan .c24-uli-input-splitted", "");
        if (mode === "mcode") {
            Check24.ul.pwReset2FA = false;
            window.setTimeout(function() {
                Check24.document.querySelector(".c24-uli-pwr-mtan .c24-uli-input-splitted:first-of-type").focus({preventScroll:true});
            }, 0);
        } else if (mode === "ecode") {
            Check24.ul.pwReset2FA = true;
            window.setTimeout(function() {
                Check24.document.querySelector(".c24-uli-pwr-etan .c24-uli-input-splitted:first-of-type").focus({preventScroll:true});
            }, 0);
        }
        Check24.change("value", ".c24-uli-input-sq", "");
        Check24.ul.pwResetMode = mode;
        Check24.removeEvent(".c24-uli-pwr", "submit", Check24.ul.listener.pwResetListener);
        Check24.addEvent(".c24-uli-pwr", "submit", Check24.ul.listener.pwResetListener);
    },
    sqCurrentIndex: 0,
    sqCurrent: {
        id: null,
        product: null,
        answer: null
    },
    renewInit: false,
    renewMode: null,
    renew: function(mode) {
        if (!Check24.ul.renewInit) {
            Check24.ul.renewInit = true;
        }
        var loginElement    = Check24.document.getElementById('cl_login_ph');
        var passwordElement = Check24.document.getElementById('cl_pw_renew');
        var wording         = Check24.document.getElementById('c24-uli-cl-pw-strength-value-renew');
        var bar             = Check24.document.getElementById('c24-uli-cl-pw-strength-status-renew');

        Check24.change("html", ".c24-uli-user-data", loginElement.value);
        Check24.PasswordStrength.init(wording, bar, loginElement, passwordElement);
        Check24.change("html", ".c24-uli-cl-pw-strength-value", "");
        Check24.ul.renewMode = mode;
        Check24.removeEvent(".c24-uli-renew-form", "submit", Check24.ul.listener.renewListener);
        Check24.addEvent(".c24-uli-renew-form", "submit", Check24.ul.listener.renewListener);
    },
    tanContext: null,
    tan: function() {
        Check24.ul.hideResentTanCodeText();
        Check24.change("value", ".c24-uli-tan .c24-uli-input-splitted", "");
        window.setTimeout(function() {
            Check24.document.querySelector(".c24-uli-tan .c24-uli-input-splitted:first-of-type").focus({preventScroll:true});
        }, 0);
        Check24.hide(".c24-uli-offline-error");
        Check24.ul.ajax.tanNew(Check24.ul.tanData, Check24.ul.callback.tanNew);
        Check24.ul.initTanResendTimeout();
        Check24.removeEvent(".c24-uli-tan-form", "submit", Check24.ul.listener.tanValidateListener);
        Check24.addEvent(".c24-uli-tan-form", "submit", Check24.ul.listener.tanValidateListener);
        Check24.hide([".c24-uli-bottom-r-sameemail", ".c24-uli-bottom-r-samephone"]);
    },
    pointsAction: function() {
        Check24.hide(".c24-uli-points-error");
        Check24.removeClass(".c24-uli-points-radio", "c24-uli-error-points");
        Check24.removeEvent(".c24-uli-points-form", "submit", Check24.ul.listener.pointsActionListener);
        Check24.addEvent(".c24-uli-points-form", "submit", Check24.ul.listener.pointsActionListener);
    },
    setLoginData: function() {
        Check24.ul.resetHeight();

        Check24.ul.tanContext = "editphone";

        Check24.removeEvent(".c24-uli-sld-form", "submit", Check24.ul.listener.setLoginDataListener);
        Check24.addEvent(".c24-uli-sld-form", "submit", Check24.ul.listener.setLoginDataListener);
    },
    mfasq: function() {
        Check24.ul.resetHeight();

        Check24.removeEvent(".c24-uli-mfasq-form", "submit", Check24.ul.listener.mfasqListener);
        Check24.addEvent(".c24-uli-mfasq-form", "submit", Check24.ul.listener.mfasqListener);
    },
    currentLoginCodeInput: null,
    loginProcess: function(mode) {
        Check24.ul.resetHeight();
        Check24.removeEvent(".c24-uli-lptan-form", "submit", Check24.ul.listener.loginProcessListener);
        Check24.removeEvent(".c24-uli-lptan-form", "submit", Check24.ul.listener.loginCodeListener);
        if (mode === "send") {
            Check24.addEvent(".c24-uli-lptan-form", "submit", Check24.ul.listener.loginProcessListener);
        } else {
            Check24.change("value", ".c24-uli-loginprocess-tan .c24-uli-input-splitted", "");
            window.setTimeout(function() {
                Check24.document.querySelector(".c24-uli-loginprocess-tan .c24-uli-input-splitted:first-of-type").focus({preventScroll:true});
            }, 0);
            Check24.addEvent(".c24-uli-lptan-form", "submit", Check24.ul.listener.loginCodeListener);
        }
    },
    loginChallenge: (type) => {
        if (type !== "start" && type !== "submit" && type !== "zipcode" && type !== "birthday") {
            console.log("loginchallenge: invalid type: ", type);
            return;
        }

        Check24.removeEvent('#c24-uli-form-user-lc', 'submit', Check24.ul.listener.loginChallengeStartListener);
        Check24.removeEvent('#c24-uli-form-user-lc', 'submit', Check24.ul.listener.loginChallengeSubmitListener);
        Check24.removeEvent('#c24-uli-form-user-lc', "submit", Check24.ul.listener.loginChallengeQuestionListener);
        if (type === "start") {
            Check24.addEvent('#c24-uli-form-user-lc', 'submit', Check24.ul.listener.loginChallengeStartListener);
        } else if (type === "submit") {
            Check24.change("value", ".c24-uli-loginchallenge .c24-uli-input-splitted", "");
            window.setTimeout(function() {
                Check24.document.querySelector(".c24-uli-loginchallenge .c24-uli-input-splitted:first-of-type").focus({preventScroll:true});
            }, 0);
            Check24.addEvent('#c24-uli-form-user-lc', 'submit', Check24.ul.listener.loginChallengeSubmitListener);
        } else if (type === "zipcode") {
            Check24.change("value", "#cl_zipcode_lc", "");
            Check24.ul.loginChallengeData.responseMode = type;
            Check24.addEvent('#c24-uli-form-user-lc', "submit", Check24.ul.listener.loginChallengeQuestionListener);
        } else if (type === "birthday") {
            Check24.change("value", "#cl_birthday_lc", "");
            Check24.ul.loginChallengeData.responseMode = type;
            Check24.addEvent('#c24-uli-form-user-lc', "submit", Check24.ul.listener.loginChallengeQuestionListener);
        }
        Check24.ul.resetHeight();
    },
    initLoginChallenge: (data) => {
        if (data.data && data.data.loginchallenge_id) {
            Check24.ul.loginChallengeData.id = data.data.loginchallenge_id;
            if (data.data.challenge_options && data.data.challenge_options[0] && data.data.challenge_options[0]["type"] && data.data.challenge_options[0]["recipient"]) {

                // challenge type for otp login challenge
                if (data.data.challenge_options[0]["type"] === "security_question_one_of_zip_and_birthday") {
                    Check24.ul.layout.setSpinner(null, Check24.document.querySelector("#c24-uli-lptan-btn"));
                    Check24.ul.loginChallengeData.type = data.data.challenge_options[0]["type"];
                    var login = Check24.getValue("#cl_login_ph");
                    var type = "email";
                    if (Check24.hasClass(".c24-uli-login", "c24-uli-login-phone")) {
                        type = "phone";
                    }
                    Check24.ul.ajax.loginChallengeStart(Check24.ul.loginChallengeData.id, Check24.ul.loginChallengeData.type, login, type, Check24.ul.callback.loginChallengeStart);
                    return;
                }

                Check24.hide(['.c24-uli-lc-text-email', '.c24-uli-lc-text-phone', '.c24-uli-lc-multiple-rcpt-box', '.c24-uli-lc-single-rcpt-text']);
                var recipient_element_list = Check24.document.querySelectorAll(".c24-uli-lc-multiple-rcpt-box .c24-uli-points-radio-ele");
                recipient_element_list.forEach(function(elem) {
                    elem.parentNode.removeChild(elem);
                });

                // Only one possible login challenge device
                if (data.data.challenge_options.length === 1) {
                    Check24.show('.c24-uli-lc-single-rcpt-text');
                    Check24.ul.loginChallengeData.type = data.data.challenge_options[0]["type"];
                    if (Check24.ul.loginChallengeData.type.indexOf("email") !== -1) {
                        Check24.change('html', '.c24-uli-lc-single-rcpt-text .c24-uli-lc-text-type', poJD.callback.login_challenge.email);
                    } else {
                        Check24.change('html', '.c24-uli-lc-single-rcpt-text .c24-uli-lc-text-type', poJD.callback.login_challenge.sms);
                    }
                    Check24.hide(".c24-uli-lc-multiple-rcpt-box .c24-uli-points-radio");

                    // Multiple possible login challenge devices
                } else if (data.data.challenge_options.length > 1) {
                    Check24.show('.c24-uli-lc-multiple-rcpt-box');
                    var recipient_list = Check24.document.querySelector('.c24-uli-lc-multiple-rcpt-box .c24-uli-points-radio');

                    data.data.challenge_options.forEach(function(challenge_option, index) {
                        if (!challenge_option["recipient"] || !challenge_option["type"]) return;

                        var new_element = document.createElement("div");

                        new_element.classList.add("c24-uli-points-radio-ele", "clearfix")
                        new_element.innerHTML =
                            "<input id=\"c24-uli-lc-recipient-" +  index + "\" type=\"radio\" name=\"c24-uli-lc-recipient\" class=\"c24-uli-radio-points\" data-type=\"" + challenge_option["type"] + "\" />" +
                            "<label for=\"c24-uli-lc-recipient-" +  index + "\">" +
                            "    <span><span></span></span>" +
                            "    <p><span>" + poJD.callback.login_challenge.recipient + "</span></p>" +
                            "</label>";

                        var recipient_input = new_element.querySelector("input.c24-uli-radio-points");
                        var recipient_text = new_element.querySelector(".c24-uli-lc-user-data");
                        var recipient_type_text = new_element.querySelector(".c24-uli-lc-text-type");

                        if (index === 0) {
                            recipient_input.checked = true;
                            Check24.ul.loginChallengeData.type = recipient_input.dataset.type;
                        }

                        if (challenge_option["type"].indexOf("email") !== -1) {
                            recipient_type_text.innerHTML = poJD.callback.login_challenge.email;
                        } else {
                            recipient_type_text.innerHTML = poJD.callback.login_challenge.sms;
                        }
                        recipient_text.innerText = challenge_option["recipient"];

                        new_element.addEventListener('click', function(event) {
                            var radio_element = event.target.closest(".c24-uli-points-radio-ele");
                            if(!radio_element) return;

                            var input_element = radio_element.querySelector('input.c24-uli-radio-points');
                            Check24.ul.loginChallengeData.type = input_element.dataset.type;
                        });

                        recipient_list.appendChild(new_element);
                    });
                    Check24.show(".c24-uli-lc-multiple-rcpt-box .c24-uli-points-radio");
                }
            }
        }
        Check24.ul.init("loginchallenge", "start");
    },

    // MESSAGING
    customEvent: function(type, session) {
        var points = Check24.ul.checkPoints();
        C24ulUserData.BROWSERBACK = (type === "browserback");
        C24ulUserData.USEREMAIL = Check24.ul.email;
        C24ulUserData.USERPHONE = Check24.ul.phone;
        C24ulUserData.USERPOINTS = points;
        C24ulUserData.STATE = session ? session : type;
        C24ulUserData.USERLOGIN = Check24.ul.initShowPoints ?
            (Check24.ul.userLoginType === "check24_phone" ? "phone" : "email") :
            (Check24.hasClass(".c24-uli-login", "c24-uli-login-phone") ? "phone" : "email");
        C24ulUserData.VIEW = Check24.ul.view;
        C24ulUserData.LOGINTYPE = Check24.ul.loginPath;
        C24ulUserData.SSO_SERVER = Check24.ul.server;
        C24ulUserData.SSO_ENV = Check24.ul.ssoEnv;
        C24ulUserData.SSO_SESSION = session ? session : "";
        if (ulEvent instanceof Event) {
            window.dispatchEvent(ulEvent);
        }
    },
    postMessage: {
        sendMsg: function(index, msg) {
            if (configService.getEnv() === "default") {
                if (index === "docHeight") {
                    if (msg > 0) {
                        parent.postMessage(JSON.stringify({"ul": {"docHeight": msg}}), '*');
                    }
                } else {
                    parent.postMessage(JSON.stringify(index), '*');
                }
            }
        }
    },
    handleDocMsg: function(e) {
        if (e.data === "resetHeight") {
            Check24.ul.resetHeight();
        } else if (e.data === "browserback") {
            Check24.ul.listener.browserBackListener();
        } else if (e.data === "layerClosed" && Check24.ul.state === "setlogindata") {
            Check24.ul.closeCommunicaton(Check24.ul.loginData);
            Check24.removeTanSessionData();
        }
    },
    viewChangeMsg: function(context, view) {
        if (window._mtm) {
            try {
                _mtm.push({
                    'event': 'uli_view',
                    'uli_context': context,
                    'uli_context_view': view,
                    'uli_api_product': configService.getApiProduct(),
                    'uli_sso_env': configService.getSsoEnv(),
                    'uli_login_type': Check24.ul.loginType
                });
            } catch (e) {
            }
        }
        var msg = {
            "uli_view": {
                "context": context,
                "view": view
            }
        };
        if (configService.getEnv() === "default") {
            parent.postMessage(JSON.stringify(msg), '*');
        } else {
            var uliViewEvent = new CustomEvent("unified-login-view", {
                detail: {
                    view: msg
                }
            });
            if (uliViewEvent instanceof Event) {
                window.dispatchEvent(uliViewEvent);
            }
        }
    },
    updateView: function() {
        var points = Check24.ul.checkPoints();
        if (configService.getEnv() === "default") {
            Check24.ul.postMessage.sendMsg({
                "ul": {
                    "USERLOGIN": Check24.hasClass(".c24-uli-login", "c24-uli-login-phone") ? "phone" : "email",
                    "USERPHONE": Check24.ul.phone,
                    "USEREMAIL": Check24.ul.email,
                    "USERPOINTS": points,
                    "STATE": "",
                    "VIEW": Check24.ul.view,
                    "LOGINTYPE": Check24.ul.loginPath,
                    "SSO_SERVER": Check24.ul.server,
                    "SSO_ENV": Check24.ul.ssoEnv,
                    "SSO_SESSION": Check24.ul.loginSession
                }
            });
        } else {
            if (typeof C24ulpf !== 'undefined' && typeof C24ulpf.getUserData === "function") {
                C24ulpf.getUserData(
                    {
                        "USERLOGIN": Check24.hasClass(".c24-uli-login", "c24-uli-login-phone") ? "phone" : "email",
                        "USERPHONE": Check24.ul.phone,
                        "USEREMAIL": Check24.ul.email,
                        "USERPOINTS": points,
                        "STATE": "",
                        "VIEW": Check24.ul.view,
                        "LOGINTYPE": Check24.ul.loginPath,
                        "SSO_SERVER": Check24.ul.server,
                        "SSO_ENV": Check24.ul.ssoEnv,
                        "SSO_SESSION": Check24.ul.loginSession
                    }
                );
            }
            Check24.ul.customEvent("");
        }
    },
    closeCommunicaton: uliClose,
    afterBrowserBack: function() {
        var points = Check24.ul.checkPoints();
        if (configService.getEnv() === "default") {
            Check24.ul.postMessage.sendMsg({
                "ul": {
                    "browserBack": true,
                    "USERLOGIN": Check24.hasClass(".c24-uli-login", "c24-uli-login-phone") ? "phone" : "email",
                    "USERPHONE": Check24.ul.phone,
                    "USEREMAIL": Check24.ul.email,
                    "USERPOINTS": points,
                    "STATE": "browserback",
                    "VIEW": Check24.ul.view,
                    "LOGINTYPE": Check24.ul.loginPath,
                    "SSO_SERVER": "",
                    "SSO_ENV": "",
                    "SSO_SESSION": ""
                }
            });
        } else {
            var stateEle = document.querySelector("#c24-uli-user-state");
            if (stateEle) {
                stateEle.value = "browserBack";
            }
            if (typeof C24ulpf !== "undefined" && typeof C24ulpf.browserBack === "function") {
                C24ulpf.browserBack({
                    "browserBack": true,
                    "USERLOGIN": Check24.hasClass(".c24-uli-login", "c24-uli-login-phone") ? "phone" : "email",
                    "USERPHONE": Check24.ul.phone,
                    "USEREMAIL": Check24.ul.email,
                    "USERPOINTS": points,
                    "STATE": "browserback",
                    "VIEW": Check24.ul.view,
                    "LOGINTYPE": Check24.ul.loginPath,
                    "SSO_SERVER": "",
                    "SSO_ENV": "",
                    "SSO_SESSION": ""
                })
            }
            Check24.ul.server = "";
            Check24.ul.ssoEnv = "";
            Check24.ul.customEvent("browserback");
        }
        return false;
    },
    globalError: function() {
        var points = Check24.ul.checkPoints();
        var userEleLogin = document.querySelector("#c24-uli-user-login");
        if (userEleLogin) {
            if (Check24.hasClass(".c24-uli-login", "c24-uli-login-phone")) {
                userEleLogin.value = "phone";
            } else {
                userEleLogin.value = "email";
            }
        }
        var userEleEmail = document.querySelector("#c24-uli-user-email");
        if (userEleEmail) {
            userEleEmail.value = Check24.ul.email;
        }
        if (typeof C24ulpf !== 'undefined' && typeof C24ulpf.setUserEmail === "function") {
            C24ulpf.setUserEmail(Check24.ul.email);
        }
        var userElePhone = document.querySelector("#c24-uli-user-phone");
        if (userElePhone) {
            userElePhone.value = Check24.ul.phone;
        }
        if (typeof C24ulpf !== 'undefined' && typeof C24ulpf.setUserPhone === "function") {
            C24ulpf.setUserPhone(Check24.ul.phone);
        }
        var userElePoints = document.querySelector("#c24-uli-user-points");
        if (userElePoints) {
            userElePoints.value = points;
        }
        if (typeof C24ulpf !== 'undefined' && typeof C24ulpf.setUserPoints === "function") {
            C24ulpf.setUserPoints(points);
        }
        var stateEle = document.querySelector("#c24-uli-user-state");
        if (stateEle) {
            stateEle.value = "anonymous";
        }
        if (typeof C24ulpf !== 'undefined' && typeof C24ulpf.userAnonymous === "function") {
            C24ulpf.userAnonymous();
        }
        if (typeof C24ulpf !== 'undefined' && typeof C24ulpf.userAnonymous === "function") {
            C24ulpf.userAnonymous();
        }
        if (typeof C24ulpf !== 'undefined' && typeof C24ulpf.getUserData === "function") {
            C24ulpf.getUserData(
                {
                    "USERLOGIN": Check24.hasClass(".c24-uli-login", "c24-uli-login-phone") ? "phone" : "email",
                    "USERPHONE": Check24.ul.phone,
                    "USEREMAIL": Check24.ul.email,
                    "USERPOINTS": points,
                    "STATE": "anonymous",
                    "VIEW": Check24.ul.view,
                    "LOGINTYPE": Check24.ul.loginPath,
                    "SSO_SERVER": "",
                    "SSO_ENV": "",
                    "SSO_SESSION": ""
                }
            );
        }
        Check24.ul.server = "";
        Check24.ul.ssoEnv = "";
        Check24.ul.customEvent("anonymous");
    },
    error: uliError,

    // FUNCTIONS
    resetDefaults: function() {
        Check24.ul.pwResetData = {
            id: null,
            password: null,
            mcode: null,
            ecode: null,
            zipcode: null,
            birthday: null,
            questions: null,
            method: null,
            recipient: null,
            sq: null
        };
        Check24.ul.tanData = {
            id: null,
            code: null,
            context: null,
            method: null,
            methodOverwrite: false,
            valid: false,
            pw: null,
            firstname: null,
            lastname: null,
            consecutiveTan: null,
            type: "email",
            key: null
        };
        Check24.ul.loginChallengeData = {
            id: null,
            type: null,
            response: null,
            questions: {
                zipcode: null,
                birthday: null
            },
            questionMode: null,
            responseMode: null,
            zipcode: null,
            birthday: null
        };
        Check24.ul.loginProcessData = {
            id: null,
            recipient: null,
            response: null
        };
        Check24.ul.tanLoginProcessStarted = false;
    },
    setContext: function() {
        var contexts = [
            "kb",
            "loginlayer",
            "as"
        ];
        var context = configService.getContext();
        if (contexts.indexOf(context) !== -1) {
            Check24.addClass(".c24-uli-wrapper", "c24-uli-" + context);
        } else {
            Check24.addClass(".c24-uli-wrapper", "c24-uli-as");
        }
    },
    setText: function() {
        var configData = Check24.ul.configData.data;
        Check24.change("html", ".c24-uli-title-name", configData.title);
        Check24.change("html", ".c24-uli-login-faq", configData.faq);
        Check24.change("html", ".c24-uli-item-name", configData.item);
        if (
            configData.hasOwnProperty('title_full') &&
            configData.title_full !== null &&
            configData.title_full !== ''
        ) {
            Check24.change("html", ".c24-uli-title", Check24.ul.configData.data["title_full"]);
        }

        if (configService.getContext() !== "loginlayer" && configService.getContext() !== "kb") {
            if (Check24.ul.configData.data["trust_email"]) {
                Check24.change("html", ".c24-uli-product-wrapper-email",Check24.ul.configData.data["trust_email"]);
            }
            if (Check24.ul.configData.data["trust_phone"]) {
                Check24.change("html", ".c24-uli-product-wrapper-phone", Check24.ul.configData.data["trust_phone"]);
            }
        }
    },
    changeUser: function() {
        Check24.ul.loginInit    = true;
        Check24.ul.userChange   = true;
        Check24.ul.loginPrefill = true;
        var login = Check24.getValue("#cl_login_ph");
        Check24.change("value", "#cl_login", login);
        Check24.ul.init("login", "nocookie");
    },
    checkPoints: function() {
        var points = false;
        if (configService.getPoints() !== false && !Check24.ul.userData.points) {
            points = "no";
            if (configService.getPoints() === "radio") {
                var radios = Check24.document.querySelectorAll(".c24-uli-radio-points-yes");
                radios.forEach(function (el) {
                    if (el.checked == true) {
                        points = "yes";
                    }
                });
            }
            if (configService.getPoints() === "checkbox") {
                var checkboxes = Check24.document.querySelectorAll(".c24-uli-checkbox-points");
                checkboxes.forEach(function (el) {
                    if (el.checked == true) {
                        points = "yes";
                    }
                });
            }
        }
        if (Check24.ul.userData.points === true) {
            points = true;
        }
        return points;
    },
    validateRadioPoints: false,
    validatePoints: function() {
        Check24.ul.validateRadioPoints = false;
        if (configService.getPoints() === "radio") {
            var radios = Check24.document.querySelectorAll(".c24-uli-radio-points");
            radios.forEach(function (ele) {
                if (ele.checked === true) {
                    Check24.ul.validateRadioPoints = true;
                }
            });
            return Check24.ul.validateRadioPoints;
        }
        if (configService.getPoints() === "checkbox") {
            return true;
        }
    },
    initTanResendTimeout: function() {
        if (Check24.ul.tanResendTimeout) {
            window.clearTimeout(Check24.ul.tanResendTimeout);
        }
        Check24.ul.tanResendTimer = 60;
        Check24.addClass(".c24-uli-tan-resend-button", "c24-uli-disabled-text");
        Check24.ul.updateResendTanCodeText();
    },
    updateResendTanCodeText: function() {
        if (Check24.ul.tanResendTimer !== null) {
            if (Check24.ul.tanResendTimer > 0) {
                Check24.change("html", ".c24-uli-tan-resend-timer", poJD.tan.wait_for_resend);
                Check24.change("html", ".c24-uli-tan-resend-wait-time", Check24.ul.tanResendTimer);
                Check24.ul.tanResendTimer--;
                Check24.ul.tanResendTimeout= window.setTimeout(Check24.ul.updateResendTanCodeText, 1000);
            } else {
                Check24.change("html", ".c24-uli-tan-resend-timer", poJD.tan.resend_possible);
                Check24.ul.tanResendTimer = null;
                Check24.removeClass(".c24-uli-tan-resend-button", "c24-uli-disabled-text");
            }
        }
        Check24.ul.resetHeight();
    },
    showResetTanCodeText: function(method) {
        if (
            Check24.ul.pwResetData.method === "account_email" ||
            method === "email" ||
            (method === "mcode" && !Check24.hasClass(".c24-uli-login", "c24-uli-login-phone") && Check24.ul.pwResetData.method !== "account_sms") ||
            (method === "ecode" && Check24.hasClass(".c24-uli-login", "c24-uli-login-phone") && Check24.ul.pwResetData.method !== "account_sms")
        ) {
            Check24.change("html", ".c24-uli-tan-resend-success", poJD.tan.ecode_was_sent);
        } else if (
            Check24.ul.pwResetData.method === "account_sms" ||
            method === "sms" ||
            (method === "mcode" && Check24.hasClass(".c24-uli-login", "c24-uli-login-phone") && Check24.ul.pwResetData.method !== "account_email") ||
            (method === "ecode" && !Check24.hasClass(".c24-uli-login", "c24-uli-login-phone") && Check24.ul.pwResetData.method !== "account_email")
        ) {
            Check24.change("html", ".c24-uli-tan-resend-success", poJD.tan.scode_was_sent);
        }
        if (Check24.ul.tanResendTimer === null) {
            Check24.hide(".c24-uli-tan-resend-timer");
            Check24.show(".c24-uli-tan-resend-success");
        } else {
            Check24.hide(".c24-uli-tan-resend-success");
            Check24.show(".c24-uli-tan-resend-timer");
        }
        Check24.ul.resetHeight();
    },
    hideResentTanCodeText: function() {
        Check24.hide([".c24-uli-tan-resend-success", ".c24-uli-tan-resend-timer"]);
        Check24.ul.resetHeight();
    },

    // IFRAME HEIGHT FUNCTIONS
    resetHeight: function() {
        if (configService.getEnv() === "default") {
            var height = Check24.ul.getDocHeight();
            Check24.ul.postMessage.sendMsg("docHeight", height);
        }
    },
    getDocHeight: function(ele) {
        var element = Check24.document.querySelector(ele ? ele : ".c24-uli-wrapper");
        return Math.max(element.offsetHeight);
    },

    // VIEWLAYOUT
    layout: uliLayout,

    // LISTENER
    listener: uliListener,

    // AJAX EPS
    ajax: ajax,

    // CALLBACKS
    callback: uliCallbacks,

    // MATOMO TRACKING
    setTracking: setTracking
};

export default Check24;