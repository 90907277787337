import {getBasePath} from "../../getBasePath";
import {browserBackEnum} from "../enum/browserBackEnum";

const defaultConfig = {
    apiProduct: "",
    pwResetType: "tan",
    pwResetRef: "https://www.check24.de/",
    socialLoginCallbackUrl: "https://www.check24.de/",
    socialLoginClosingUrl: getBasePath() + "html/closing.html",
    socialLoginBaseUrl: "https://kundenbereich.check24.de/",
    points: false,
    browserBack: browserBackEnum.DEFAULT,
    basePathTest: "",
    transactionType: "none",
    showAnonymous: "true",
    mainWidth: "",
    helpWidth: "",
    responsive: "false",
    border: "default",
    background: "",
    customParam: undefined,
    basePath: "current",
    env: "default",
    deviceoutput: "desktop",
    context: "as",
    mode: null,
    registerType: null,
    ssoEnv: process.env.ENVIRONMENT,
    ssoCore: "",
    challenge: "",
    configKey: null,
    csrfValidation: null,
    cookieDomain: null,
    pwMode: "default",
    hideInitialBackButton: null,
    uliReset: null,
    setLoginData: "true",
    transferToken: "",
    loginMode: "default",
    forceLogin: "false",
    registerUsername: "false",
    socialRegistration: "false"
};

class ConfigService {
    constructor() {
        this.config = {};
    }

    init(config) {
        this.config = Object.assign({}, defaultConfig, config);
        // Calculate cookie domain from base path
        if (this.config.cookieDomain === null && this.config.basePathTest !== "") {
            let url = new URL(this.config.basePathTest);
            this.config.cookieDomain = "." + url.origin.split(/\./).slice(-2).join(".");
        }
    }

    getApiProduct() {
        return this.config.apiProduct;
    }

    getPwResetType() {
        return this.config.pwResetType;
    }

    getPwResetRef() {
        return this.config.pwResetRef;
    }

    getSocialLoginCallbackUrl() {
        return this.config.socialLoginCallbackUrl;
    }

    getSocialLoginClosingUrl() {
        return this.config.socialLoginClosingUrl;
    }

    getSocialLoginBaseUrl() {
        return this.config.socialLoginBaseUrl;
    }

    getPoints() {
        return this.config.points;
    }

    getBrowserBack() {
        return this.config.browserBack;
    }

    getBasePathTest() {
        return this.config.basePathTest;
    }

    getTransactionType() {
        return this.config.transactionType;
    }

    getShowAnonymous() {
        return this.config.showAnonymous;
    }

    getMainWidth() {
        return this.config.mainWidth;
    }

    getHelpWidth() {
        return this.config.helpWidth;
    }

    getResponsive() {
        return this.config.responsive;
    }

    getBorder() {
        return this.config.border;
    }

    getBackground() {
        return this.config.background;
    }

    getCustomParam() {
        return this.config.customParam;
    }

    getBasePath() {
        return this.config.basePath;
    }

    getEnv() {
        return this.config.env;
    }

    getDeviceoutput() {
        return this.config.deviceoutput;
    }

    getContext() {
        return this.config.context;
    }

    getMode() {
        return this.config.mode;
    }

    setMode(mode) {
        this.config.mode = mode;
    }

    getRegisterType() {
        return this.config.registerType;
    }

    getSsoEnv() {
        return this.config.ssoEnv;
    }

    getSsoCore() {
        return this.config.ssoCore;
    }

    getChallenge() {
        return this.config.challenge;
    }

    getConfigKey() {
        return this.config.configKey;
    }

    getCsrfValidation() {
        return this.config.csrfValidation;
    }

    getCookieDomain() {
        return this.config.cookieDomain;
    }

    getPwMode() {
        return this.config.pwMode;
    }

    getHideInitialBackButton() {
        return this.config.hideInitialBackButton;
    }

    getUliReset() {
        return this.config.uliReset;
    }

    getLoginDataSet() {
        return this.config.setLoginData;
    }

    getTransferToken() {
        return this.config.transferToken;
    }

    getLoginMode() {
        return this.config.loginMode;
    }

    getForceLogin() {
        return this.config.forceLogin;
    }

    setForceLogin(value) {
        this.config.forceLogin = value;
    }

    getRegisterUsername() {
        return this.config.registerUsername;
    }

    getSocialRegistration() {
        return this.config.socialRegistration;
    }
}

export const configService = new ConfigService();
