class ConfigData {

    constructor() {
        this.data = {};
    }

    init(json) {
        var response = JSON.parse(json);
        this.data = response.data;
    }
}

export const configData = new ConfigData();
