import {configService} from "../services/configService";
import Check24 from "../unifiedlogin_mobile";

export default function() {
    var points = Check24.ul.checkPoints();
    if (configService.getEnv() === "default") {
        Check24.ul.postMessage.sendMsg({
            "ul": {
                "USERLOGIN": Check24.ul.initShowPoints ?
                    (Check24.ul.userLoginType === "check24_phone" ? "phone" : "email") :
                    (Check24.hasClass(".c24-uli-login", "c24-uli-login-phone") ? "phone" : "email"),
                "USERPHONE": Check24.ul.phone,
                "USEREMAIL": Check24.ul.email,
                "USERPOINTS": points,
                "STATE": Check24.ul.loginState,
                "VIEW": Check24.ul.view,
                "LOGINTYPE": Check24.ul.loginPath,
                "SSO_SERVER": Check24.ul.server,
                "SSO_ENV": Check24.ul.ssoEnv,
                "SSO_SESSION": Check24.ul.loginSession,
                "TRANSFER_TOKEN": configService.getTransferToken()
            }
        });
    } else {
        var userEleLogin = document.querySelector("#c24-uli-user-login");
        if (userEleLogin) {
            if (Check24.hasClass(".c24-uli-login", "c24-uli-login-phone")) {
                userEleLogin.value = "phone";
            } else {
                userEleLogin.value = "email";
            }
        }
        var userEleEmail = document.querySelector("#c24-uli-user-email");
        if (userEleEmail) {
            userEleEmail.value = Check24.ul.email;
        }
        if (typeof C24ulpf !== 'undefined' && typeof C24ulpf.setUserEmail === "function") {
            C24ulpf.setUserEmail(Check24.ul.email);
        }
        var userElePhone = document.querySelector("#c24-uli-user-phone");
        if (userElePhone) {
            userElePhone.value = Check24.ul.phone;
        }
        if (typeof C24ulpf !== 'undefined' && typeof C24ulpf.setUserPhone === "function") {
            C24ulpf.setUserPhone(Check24.ul.phone);
        }
        var userElePoints = document.querySelector("#c24-uli-user-points");
        if (userElePoints) {
            userElePoints.value = points;
        }
        if (typeof C24ulpf !== 'undefined' && typeof C24ulpf.setUserPoints === "function") {
            C24ulpf.setUserPoints(points);
        }
        var stateEle = document.querySelector("#c24-uli-user-state");
        if (stateEle) {
            stateEle.value = Check24.ul.loginState;
        }
        if (typeof C24ulpf !== 'undefined' && typeof C24ulpf.userLoggedIn === "function") {
            C24ulpf.userLoggedIn(Check24.ul.loginState);
        }
        if (typeof C24ulpf !== 'undefined' && typeof C24ulpf.getUserData === "function") {
            C24ulpf.getUserData(
                {
                    "USEREMAIL": Check24.ul.email,
                    "USERPHONE": Check24.ul.phone,
                    "USERPOINTS": points,
                    "STATE": Check24.ul.loginState,
                    "USERLOGIN": Check24.ul.initShowPoints ?
                        (Check24.ul.userLoginType === "check24_phone" ? "phone" : "email") :
                        (Check24.hasClass(".c24-uli-login", "c24-uli-login-phone") ? "phone" : "email"),
                    "VIEW": Check24.ul.view,
                    "LOGINTYPE": Check24.ul.loginPath,
                    "SSO_SERVER": Check24.ul.server,
                    "SSO_ENV": Check24.ul.ssoEnv,
                    "SSO_SESSION": Check24.ul.loginSession,
                    "TRANSFER_TOKEN": configService.getTransferToken()
                }
            );
        }
        Check24.ul.customEvent("loggedin", Check24.ul.loginSession);
    }

    try {
        if (window._mtm) {
            Check24.ul.state = 'process';
            Check24.ul.stateOption = 'success';
            _mtm.push({
                'event': 'uli_view',
                'uli_context': 'process',
                'uli_context_view': 'success',
                'uli_api_product': configService.getApiProduct(),
                'uli_sso_env': configService.getSsoEnv(),
                'uli_login_type': Check24.ul.loginType
            });
        }
    } catch (e) {
    }

    Check24.ul.layout.reset("hard");
}