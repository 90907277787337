import {configService} from "../services/configService";
import {sanitizer} from "../helpers/sanitizer";
import {poJD} from "../po/po.js_desktop";
import Check24 from "../unifiedlogin_desktop";

export default {
    exists: function(json) {
        if (
            'points' === Check24.ul.state
            && 'loggedin' === Check24.ul.loginState
            && true === Check24.ul.prefilled
        ) {
            return;
        }

        var data = JSON.parse(json);
        Check24.ul.userData.lastLoginProvider = null;
        Check24.ul.userData.loginProvider = [];
        Check24.ul.userData.superAccount  = false;
        Check24.ul.registerTriggered = false;
        Check24.ul.browserBackOccured = false;
        if (data) {
            if (data.hasOwnProperty('n') && data.n === 'email') {
                Check24.ul.error.set('#cl_login', '.c24-uli-error-email', Check24.ul.error.msg.validate.email);
                return;
            }
            if (data.hasOwnProperty('o') && data.o === true && !data.d) {
                Check24.ul.error.set('#cl_login', '.c24-uli-error-email', Check24.ul.error.msg.validate.blacklistedEmail);
                return;
            }
            if (!Check24.ul.existsTriggered) {
                Check24.ul.existsTriggered = true;
                Check24.ul.userData.initialExisted = data.d;
            }
            // trigger register phone in case of new phone account with checked email
            if (Check24.ul.registercheckTriggered) {
                if (!data.d) {
                    Check24.ul.init("register", "phone");
                    return;
                } else {
                    // overwrite login data for existing email-account coming from registercheck
                    var email = Check24.getValue("#cl_email_registercheck");
                    Check24.change("value", "#cl_login_ph", email);
                    Check24.change("html", ".c24-uli-user-data", sanitizer.html(email));
                    Check24.change("html", ".c24-uli-login-wrapper", sanitizer.html(email));
                    Check24.ul.email = Check24.ul.userData.email;
                    Check24.ul.phone = Check24.ul.userData.phone;
                    Check24.removeClass(".c24-uli-login", "c24-uli-login-phone");
                }
            }
            if (data.d && configService.getForceLogin() === "true") {
                Check24.addClass(".c24-uli-wrapper", "c24-uli-force-login");
            } else {
                Check24.removeClass(".c24-uli-wrapper", "c24-uli-force-login");
                if (!data.d) {
                    // reset forceLogin mode
                    configService.setForceLogin("false");
                }
            }
            Check24.ul.userData.exists = data.d;
            Check24.ul.userData.points = data.l;
            Check24.ul.userData.passwordSet = data.k;
            Check24.ul.userPoints = (data.l === true) ? "yes" : "no";
            Check24.ul.userData.superAccount = data.e;
            Check24.ul.userData.terms = data.g;
            if (data.n && data.n.title && data.n.title !== "") {
                Check24.ul.pointsXtreme.title = data.n.title;
            } else {
                Check24.ul.pointsXtreme.title = "";
            }
            if (data.n && data.n.content && data.n.content !== "") {
                Check24.ul.pointsXtreme.content = data.n.content;
            } else {
                Check24.ul.pointsXtreme.content = "";
            }
            if (data.loginServer) {
                Check24.ul.server = data.loginServer;
            }
            if (data.loginEnv) {
                Check24.ul.ssoEnv = data.loginEnv;
            }
        }
        var login = Check24.getValue("#cl_login_ph");
        if (Check24.hasClass(".c24-uli-login", "c24-uli-login-phone")){
            Check24.ul.loginType = "phone";
            Check24.ul.loginPhone = "phone";
            Check24.ul.phone = login;
        } else {
            Check24.ul.loginType = "email";
            Check24.ul.loginEmail = "email";
            Check24.ul.email = login;
            if (Check24.ul.userLoginEmail !== Check24.getValue("#cl_login")) {
                Check24.ul.userGender = null;
                Check24.ul.userFirstName = null;
                Check24.ul.userLastName = null;
                Check24.ul.userLoginType = null;
            }
        }
        if (data && data.a === 200) {
            Check24.ul.userData.tsToken = null;
            if (data.b) {
                Check24.change("value", "#cl_login_ph",  data.c);
                Check24.change("html", ".c24-uli-user-data", sanitizer.html(data.c));
                Check24.change("html", ".c24-uli-login-wrapper", sanitizer.html(data.c));
                Check24.ul.userData.email = data.c;
            }
            if (Check24.ul.loginOtherInit) {
                if (Check24.ul.loginEmail === "other") {
                    Check24.addClass(".c24-uli-login", "c24-uli-login-phone");
                }
                if (Check24.ul.loginPhone === "other") {
                    Check24.removeClass(".c24-uli-login", "c24-uli-login-phone");
                }
                Check24.ul.loginOtherInit = false;
            }
            if (data.f) {
                Check24.ul.userData.lastLoginProvider = data.f;
            }
            if (data.m) {
                Check24.ul.userData.loginProvider = data.m;
                if (configService.getMode() === "pwreset") {
                    var login = Check24.getValue("#cl_login_ph");
                    Check24.change("html", ".c24-uli-pwreset .c24-uli-login-wrapper-explicit", sanitizer.html(login));
                    if (Check24.ul.loginType === "phone") {
                        Check24.change("html", ".c24-uli-login-wrapper", poJD.callback.exists.mobile_number);
                        Check24.ul.pwResetData.id = login;
                        Check24.ul.userLoginType = "check24_phone";
                    } else {
                        Check24.ul.userLoginType = "check24";
                        Check24.change("html", ".c24-uli-login-wrapper", poJD.callback.exists.email_address);
                        Check24.ul.pwResetData.id = login;
                    }
                    Check24.ul.init("pwreset", "check");
                } else {
                    Check24.removeClass(".c24-uli-wrapper", "c24-uli-login-code");
                    if (
                        (Check24.ul.userData.loginProvider.includes("check24_tan_email") || Check24.ul.userData.loginProvider.includes("check24_tan_phone")) &&
                        !Check24.ul.userData.loginProvider.includes("google")
                    ) {

                        if (Check24.ul.loginType === "email" && Check24.ul.userData.loginProvider.includes("check24_tan_email")) {
                            Check24.addClass(".c24-uli-wrapper", "c24-uli-login-code");
                            Check24.ul.userData.loginProviderOTP = "check24_tan_email";
                        }
                        if (Check24.ul.loginType === "phone" && Check24.ul.userData.loginProvider.includes("check24_tan_phone")) {
                            Check24.addClass(".c24-uli-wrapper", "c24-uli-login-code");
                            Check24.ul.userData.loginProviderOTP = "check24_tan_phone";
                        }
                    }
                    if (Check24.ul.userData.loginProvider.includes("google")) {
                        if (Check24.ul.userData.passwordSet === false) {
                            Check24.ul.init("social", "google");
                        } else {
                            Check24.ul.init("pw", "start");
                        }
                    } else if (Check24.ul.userData.lastLoginProvider === "facebook" && Check24.ul.userData.passwordSet === false) {
                        Check24.ul.init("social", Check24.ul.userData.lastLoginProvider);
                    } else {
                        Check24.ul.init("pw", "start");
                    }
                }
            } else {
                Check24.ul.init("pw", "start");
            }
        } else if (data && data.a === 429) {
            Check24.ul.error.set('', '.c24-uli-error-top-text', poJD.callback.exists.error);
            Check24.show('.c24-uli-error-top');
            Check24.ul.resetHeight();
            return false;
        } else if (data && data.a === 499) {
            Check24.ul.error.set('', '.c24-uli-error-top-text', poJD.callback.exists.error);
            Check24.show('.c24-uli-error-top');
            Check24.ul.resetHeight();
            return false;
        } else {
            var login = Check24.getValue("#cl_login_ph");
            Check24.change("html", ".c24-uli-user-data", sanitizer.html(login));
            if (Check24.hasClass(".c24-uli-login", "c24-uli-login-phone")){
                Check24.ul.phone = login;
                if (Check24.ul.userData.exists === false && data.j > 0) {
                    Check24.ul.ajax.exists(login, "phone", Check24.ul.userData, Check24.ul.callback.existsOther);
                } else {
                    Check24.ul.init("registercheck", "email");
                    // Check24.ul.init("register", "phone");
                }
            } else {
                Check24.ul.email = login;
                if (data.i && data.j > 0) {
                    Check24.ul.loginEmail = "other";
                    Check24.ul.init("loginOther", "phone");
                } else {
                    if (Check24.validate.emailStrict(login)) {
                        Check24.ul.init("register", "email");
                    } else {
                        Check24.ul.error.set('#cl_login', '.c24-uli-error-email', Check24.ul.error.msg.validate.email);
                    }
                }
            }
            if (data.n && data.n.content && data.n.content !== "") {
                Check24.ul.pointsXtreme.content = data.n.content;
            } else {
                Check24.ul.pointsXtreme.content = "";
            }
        }
        var points = Check24.ul.checkPoints();
        if (configService.getEnv() === "default") {
            Check24.ul.postMessage.sendMsg({
                "ul": {
                    "USERLOGIN": Check24.hasClass(".c24-uli-login", "c24-uli-login-phone") ? "phone" : "email",
                    "USERPHONE": Check24.ul.phone,
                    "USEREMAIL": Check24.ul.email,
                    "USERPOINTS": points,
                    "STATE": "",
                    "VIEW": Check24.ul.view,
                    "LOGINTYPE": Check24.ul.loginPath,
                    "SSO_SERVER": Check24.ul.server,
                    "SSO_ENV": Check24.ul.ssoEnv,
                    "SSO_SESSION": Check24.ul.loginSession,
                    "TRANSFER_TOKEN": configService.getTransferToken()
                }
            });
        } else {
            var userEleEmail = document.querySelector("#c24-uli-user-email");
            if (userEleEmail) {
                userEleEmail.value = Check24.ul.email;
            }
            if (typeof C24ulpf !== 'undefined' && typeof C24ulpf.setUserEmail === "function") {
                C24ulpf.setUserEmail(Check24.ul.email);
            }
            var userElePhone = document.querySelector("#c24-uli-user-phone");
            if (userElePhone) {
                userElePhone.value = Check24.ul.phone;
            }
            if (typeof C24ulpf !== 'undefined' && typeof C24ulpf.setUserPhone === "function") {
                C24ulpf.setUserPhone(Check24.ul.phone);
            }
            if (typeof C24ulpf !== 'undefined' && typeof C24ulpf.getUserData === "function") {
                C24ulpf.getUserData(
                    {
                        "USERLOGIN": Check24.hasClass(".c24-uli-login", "c24-uli-login-phone") ? "phone" : "email",
                        "USERPHONE": Check24.ul.phone,
                        "USEREMAIL": Check24.ul.email,
                        "USERPOINTS": points,
                        "STATE": "",
                        "VIEW": Check24.ul.view,
                        "LOGINTYPE": Check24.ul.loginPath,
                        "SSO_SERVER": Check24.ul.server,
                        "SSO_ENV": Check24.ul.ssoEnv,
                        "SSO_SESSION": Check24.ul.loginSession,
                        "TRANSFER_TOKEN": configService.getTransferToken()
                    }
                );
            }
            Check24.ul.customEvent("");
        }
    },
    existsOther: function(json) {
        var data = JSON.parse(json);
        if (data.j > 0) {
            if (Check24.ul.userLoginPhoneTriggered) {
                Check24.ul.init("loginOther", "phone");
            } else {
                Check24.ul.init("loginOther", "email");
            }
            Check24.ul.loginPhone = "other";
        } else {
            Check24.ul.init("register", "phone");
        }
    },
    existsSimple: function(json) {
        var data = JSON.parse(json);
        if (data.a === 200) {
            Check24.ul.userData.lastLoginProvider = data.f;
            Check24.ul.userData.loginProvider     = data.m;
            Check24.ul.userData.superAccount      = data.e;
            if (data.g) {
                Check24.ul.userData.terms = data.g;
            }
            Check24.ul.init("register", "sameemail");
        } else {
            Check24.ul.init("login", "nocookie");
            console.log("User exists error");
        }
    },
    login: function(json) {
        Check24.ul.layout.removeSpinner();
        var data = JSON.parse(json);
        var errorText = Check24.hasClass(".c24-uli-login", "c24-uli-login-phone") ?
            Check24.ul.error.msg.login.failPhone :
            Check24.ul.error.msg.login.fail;
        if (data.status === 200) {
            if (data.points_xtreme && data.points_xtreme.title && data.points_xtreme.title !== "") {
                Check24.ul.pointsXtreme.title = data.points_xtreme.title;
            }
            if (data.points_xtreme && data.points_xtreme.content && data.points_xtreme.content !== "") {
                Check24.ul.pointsXtreme.content = data.points_xtreme.content;
            } else {
                Check24.ul.pointsXtreme.content = "";
            }
            if (data.loginServer) {
                Check24.ul.server = data.loginServer;
            }
            if (data.loginEnv) {
                Check24.ul.ssoEnv = data.loginEnv;
            }
            Check24.ul.loginPath = "login";
            if (
                (
                    (configService.getLoginDataSet() === "true" && data.loginPhoneVerified === false && Check24.ul.tanData.context !== "user_login_mfa") ||
                    (Check24.ul.registercheckTriggered && Check24.ul.tanData.context !== "user_login_mfa")
                )
                && Check24.checkSldVisible()
            ) {
                Check24.ul.loginData = data;
                Check24.ul.loginState = (data && data["session"]) ? data["session"] : "loggedin";
                Check24.ul.loginSession = (data && data["session"]) ? data["session"] : "";
                if (Check24.ul.userData.phone) {
                    Check24.change("html", ".c24-uli-sld-text p", poJD.sld.with_number);
                }
                if (Check24.ul.registercheckTriggered) {
                    var phoneNumber = Check24.ul.userData.phone;
                    Check24.change("value", ".c24-uli-input-sld-prefix", phoneNumber.substring(0, phoneNumber.indexOf(' ')));
                    Check24.change("value", ".c24-uli-input-sld", phoneNumber.substring(phoneNumber.indexOf(' ') + 1));
                    Check24.ul.convertedPhone = phoneNumber;
                    Check24.ul.tanData.id = phoneNumber;
                    Check24.ul.tanData.pw = null;
                    Check24.ul.tanData.context = "user_edit_login-tan";
                    Check24.ul.tanData.method = "sms";
                    Check24.ul.tanContext = "editphone";
                    Check24.ul.init("tan", "new");
                } else {
                    if (data.loginPhone && data.loginPhone !== "") {
                        var phoneNumber = data.loginPhone;
                        Check24.change("value", ".c24-uli-input-sld-prefix", phoneNumber.substring(0, phoneNumber.indexOf(' ')));
                        Check24.change("value", ".c24-uli-input-sld", phoneNumber.substring(phoneNumber.indexOf(' ') + 1));
                    }
                    Check24.ul.init("setlogindata", "");
                }
            } else {
                Check24.ul.callback.close(data);
            }
            Check24.removeTanSessionData();
        } else if (typeof data.data !== 'undefined' && data.data !== null && typeof data.data.login_status !== 'undefined' && (data.data.login_status === 'last' || data.data.login_status === 'blocked')) {
            if (data.data.login_status === 'last' && Check24.ul.tanLoginProcessStarted) {
                Check24.ul.init("pw", "start");
                return;
            }
            var login = Check24.getValue("#cl_login_ph");
            Check24.change("html", ".c24-uli-pwreset .c24-uli-login-wrapper-explicit", sanitizer.html(login));
            if (Check24.ul.loginType === "phone") {
                Check24.change("html", ".c24-uli-pwreset .c24-uli-login-wrapper", poJD.trigger.pwreset.mobile);
                Check24.ul.pwResetData.id = login;
            } else {
                Check24.change("html", ".c24-uli-pwreset .c24-uli-login-wrapper", poJD.trigger.pwreset.email);
                Check24.ul.pwResetData.id = login;
            }
            Check24.ul.init("pwreset", "check");
        } else if (typeof data.data !== 'undefined' && data.data !== null && typeof data.data.login_status !== 'undefined' && data.data.login_status === 'fraud') {
            Check24.ul.error.set('', '.c24-uli-error-top-text', Check24.ul.error.msg.login.fraud);
            Check24.show(".c24-uli-error-top");
            Check24.removeEvent(".c24-uli-cl-pwreset-trigger-text", "click");
            Check24.addEvent(".c24-uli-cl-pwreset-trigger-text", "click", function() {
                Check24.ul.pwResetData = {
                    id: null,
                    password: null,
                    mcode: null,
                    ecode: null,
                    zipcode: null,
                    birthday: null,
                    loginInstead: "no",
                    questions: null,
                    method: null,
                    recipient: null,
                    sq: null
                };
                var login = Check24.getValue("#cl_login_ph");
                Check24.change("html", ".c24-uli-pwreset .c24-uli-login-wrapper-explicit", sanitizer.html(login));
                if (Check24.ul.loginType === "phone") {
                    Check24.change("html", ".c24-uli-pwreset .c24-uli-login-wrapper", poJD.trigger.pwreset.mobile);
                    Check24.ul.pwResetData.id = login;
                } else {
                    Check24.change("html", ".c24-uli-pwreset .c24-uli-login-wrapper", poJD.trigger.pwreset.email);
                    Check24.ul.pwResetData.id = login;
                }
                Check24.ul.init("pwreset", "check");
            });
        } else if (data.status === 657) {
            // 2FA needed
            Check24.ul.mfaSqEnabled = false;
            Check24.ul.mfaRecipent = null;
            Check24.ul.mfaSq = null;
            Check24.ul.tanData.id = Check24.getValue("#cl_login_ph");
            Check24.ul.tanData.context = "user_login_mfa";
            Check24.ul.tanData.method = "sms";
            Check24.ul.tanData.type = Check24.ul.loginType;
            Check24.ul.tanContext = "user_login_mfa";
            if (data.hasOwnProperty("data")) {
                var apiData = data;
                var options = data.data;
                if (options.hasOwnProperty("secure_method_options")) {
                    var secOpt = options.secure_method_options;
                    secOpt.forEach(function (opt) {
                        if (opt.securemethod_type === "sms") {
                            Check24.ul.mfaRecipent = opt.securemethod_recipient;
                        }
                        if (opt.securemethod_type === "securityquestion" && opt.securemethod_recipient !== "") {
                            if (apiData.hasOwnProperty("securequest") && apiData.securequest !== null && apiData.securequest.questions.length > 0) {
                                apiData.securequest.questions.forEach(function (quest) {
                                    if (opt.securemethod_recipient === quest.question_id) {
                                        Check24.ul.mfaSqEnabled = true;
                                        Check24.ul.mfaSq = {
                                            question: quest.question,
                                            product: quest.product,
                                            validator: quest.validator,
                                            id: quest.question_id
                                        }
                                    }
                                });
                            }

                        }
                    });
                }
            }
            Check24.ul.init("tan", "new");
        } else if (data.status === 683) {
            // login challenge needed
            Check24.ul.initLoginChallenge(data);
        } else if (data.status === 684) {
            // login challenge response is invalid
            Check24.show('.c24-uli-cl_lc-error-code');
            Check24.ul.error.set('.c24-uli-loginchallenge .c24-uli-input-splitted');
        } else if (data.status === 685) {
            // unexpected login challenge response
            Check24.ul.error.set('', '.c24-uli-error-top-text', poJD.callback.login_challenge.unexpected_challenge_error);
            Check24.ul.loginPrefill = true;
            Check24.ul.init("login", "");
            Check24.show(".c24-uli-error-top");
        } else if (data.status === 401 || data.status === 688) {
            Check24.ul.error.set('#cl_pw_login', '.c24-uli-error-pw', errorText);
            Check24.ul.error.set('.c24-uli-loginprocess .c24-uli-input-splitted', '.c24-uli-error-lptan', Check24.ul.error.msg.login.failCode);
        } else {
            // Unexpected error
            Check24.ul.error.set('', '.c24-uli-error-top-text', poJD.callback.login_challenge.other_error);
            Check24.ul.loginPrefill = true;
            Check24.ul.init("login", "");
            Check24.show(".c24-uli-error-top");
        }
        Check24.ul.resetHeight();
    },
    loginRenew: function(json) {
        var data = JSON.parse(json);
        if (data.status === 200) {
            if (data.loginServer) {
                Check24.ul.server = data.loginServer;
            }
            if (data.loginEnv) {
                Check24.ul.ssoEnv = data.loginEnv;
            }
            Check24.ul.loginPath = "login";
            Check24.removeTanSessionData();
            Check24.ul.callback.close(data);
        } else {
            Check24.ul.ajax.deactivate(Check24.ul.tanData, Check24.ul.callback.deactivate);
        }
    },
    loginChallengeStart: (json) => {
        var data = JSON.parse(json);
        if (data.status === 200) {
            if (
                data.securequest && data.securequest.questions && data.securequest.questions.length > 0
            ) {
                Check24.ul.loginChallengeData.questions = {
                    zipcode: null,
                    birthday: null
                };
                Check24.ul.loginChallengeData.questionMode = data.securequestmode;
                for (var i = 0; i < data.securequest.questions.length; i++) {
                    if (data.securequest.questions[i].question_id === "10001") {
                        Check24.ul.loginChallengeData.questions.zipcode = data.securequest.questions[i];
                    } else if (data.securequest.questions[i].question_id === "10002") {
                        Check24.ul.loginChallengeData.questions.birthday = data.securequest.questions[i];
                    }
                }
                if (Check24.ul.loginChallengeData.questions.zipcode !== null) {
                    Check24.ul.init("loginchallenge", "zipcode");
                } else {
                    Check24.ul.init("loginchallenge", "birthday");
                }
            } else {
                Check24.ul.init("loginchallenge", "submit");
            }
        } else {
            Check24.ul.error.set('', '.c24-uli-error-top-text', poJD.callback.login_challenge_start.error);
            Check24.ul.loginPrefill = true;
            Check24.ul.init("login", "");
            Check24.show(".c24-uli-error-top");
            Check24.ul.layout.removeSpinner();
            Check24.ul.resetHeight();
        }
    },
    loginChallengeQuestionValidate: function(json) {
        var data = JSON.parse(json);
        if (data.status === 200) {
            if (data.data) {
                if (data.data.status_code && data.data.status_code === 200) {
                    if (data.data && data.data.api_data && data.data.api_data.user_security_token && data.data.api_data.user_security_token !== "") {
                        var login = Check24.getValue("#cl_login_ph");
                        Check24.ul.ajax.login(
                            login,
                            "",
                            Check24.ul.loginType,
                            Check24.ul.loginProcessData.response,
                            Check24.ul.loginProcessData.id,
                            Check24.ul.userData,
                            Check24.ul.loginChallengeData.id,
                            data.data.api_data.user_security_token,
                            Check24.ul.callback.login,
                            undefined
                        );
                    }
                } else if (data.data.status_code && (data.data.status_code === 659 || data.data.status_code === 509)) {
                    if (Check24.ul.loginChallengeData.responseMode === "zipcode") {
                        Check24.ul.error.set('#cl_zipcode_lc', '.c24-uli-error-zipcode', Check24.ul.error.msg.sq.blocked);
                    } else {
                        Check24.ul.error.set('#cl_birthday_lc', '.c24-uli-error-birthday', Check24.ul.error.msg.sq.blocked);
                    }
                    if (Check24.ul.hotline !== "" && Check24.ul.hotline !== "undefined") {
                        Check24.change("html", ".c24-uli-sq-contact", Check24.ul.hotline);
                        Check24.change("href", ".c24-uli-sq-contact", "tel:" + Check24.ul.hotlineClean);
                    }
                } else {
                    if (data.data && data.data.api_data && data.data.api_data.remaining_attempts) {
                        if (data.data.api_data.remaining_attempts === 1) {
                            if (Check24.ul.loginChallengeData.responseMode === "zipcode") {
                                Check24.ul.error.set('#cl_zipcode_lc', '.c24-uli-error-zipcode', Check24.ul.error.msg.sq.lastChance);
                            } else {
                                Check24.ul.error.set('#cl_birthday_lc', '.c24-uli-error-birthday', Check24.ul.error.msg.sq.lastChance);
                            }
                        } else {
                            if (Check24.ul.loginChallengeData.responseMode === "zipcode") {
                                Check24.ul.error.set('#cl_zipcode_lc', '.c24-uli-error-zipcode', Check24.ul.error.msg.pwReset.wrongAnswer);
                            } else {
                                Check24.ul.error.set('#cl_birthday_lc', '.c24-uli-error-birthday', Check24.ul.error.msg.pwReset.wrongAnswer);
                            }
                        }
                    }
                }
            }
        } else {
            if (Check24.ul.loginChallengeData.responseMode === "zipcode") {
                Check24.ul.error.set('#cl_zipcode_lc', '.c24-uli-error-zipcode', Check24.ul.error.msg.sq.blocked);
            } else {
                Check24.ul.error.set('#cl_birthday_lc', '.c24-uli-error-birthday', Check24.ul.error.msg.sq.blocked);
            }
            if (Check24.ul.hotline !== "" && Check24.ul.hotline !== "undefined") {
                Check24.change("html", ".c24-uli-sq-contact", Check24.ul.hotline);
                Check24.change("href", ".c24-uli-sq-contact", "tel:" + Check24.ul.hotlineClean);
            }
        }
    },
    convertMobile: function(json) {
        var data = JSON.parse(json);
        if (Check24.ul.userLoginPhone !== null) {
            Check24.ul.userLoginPhoneTriggered = true;
        }
        if (data && data.status === 200 && data.converted) {
            if (data.isMobile) {
                if (Check24.ul.userLoginPhone !== Check24.getValue("#cl_login")) {
                    Check24.ul.userGender = null;
                    Check24.ul.userFirstName = null;
                    Check24.ul.userLastName = null;
                    Check24.ul.userLoginType = null;
                }
                Check24.document.querySelector("#cl_login").value = data.converted;
                Check24.change("html", ".c24-uli-user-data", data.converted);
                Check24.change("html", ".c24-uli-login-wrapper", poJD.callback.convert_mobile.mobile_number);
                Check24.change("value", "#cl_login_ph", data.converted);
                Check24.ul.userData.phone = data.converted;
                Check24.ul.userData.exists = null;
                Check24.addClass(".c24-uli-login", "c24-uli-login-phone");
                Check24.ul.ajax.exists(data.converted, "phone", Check24.ul.userData, Check24.ul.callback.exists);
            } else {
                if (!Check24.ul.convertMobileStart) {
                    Check24.ul.error.set('#cl_login', '.c24-uli-error-email', Check24.ul.error.msg.validate.email_or_phone);
                    Check24.ul.error.set('#cl_phone_login_other', '.c24-uli-error-email', Check24.ul.error.msg.validate.email_or_phone);
                } else {
                    Check24.ul.init("login", "nocookie");
                }
            }
        } else {
            Check24.ul.error.set('#cl_login', '.c24-uli-error-email', Check24.ul.error.msg.validate.email_or_phone);
            Check24.ul.error.set('#cl_phone_login_other', '.c24-uli-error-email', Check24.ul.error.msg.validate.email_or_phone);
            Check24.addClass('.c24-uli-login input[type="tel"]', 'c24-uli-input-error');
        }
        Check24.ul.convertMobileStart = false;
        Check24.ul.resetHeight();
    },
    convertMobileSld: function(json) {
        var data = JSON.parse(json);
        if (data && data.status === 200 && data.converted && data.isMobile) {
            Check24.ul.convertedPhone = data.converted;
            Check24.ul.tanData.id = data.converted;
            Check24.ul.tanData.pw = null;
            Check24.ul.tanData.context = "user_edit_login-tan";
            Check24.ul.tanData.method = "sms";
            Check24.ul.tanContext = "editphone";
            Check24.ul.init("tan", "new");
            if (Check24.ul.registercheckTriggered) {
                Check24.change("html", ".c24-uli-user-data", data.converted);
            }
        } else {
            Check24.ul.error.set('#cl_sld_prefix', '.c24-uli-error-sld', "");
            Check24.ul.error.set('#cl_sld', '.c24-uli-error-sld', Check24.ul.error.msg.validate.phone);
        }
        Check24.ul.resetHeight();
    },
    convertMobileMfasq: function(json) {
        var data = JSON.parse(json);
        if (data && data.status === 200 && data.converted) {
            var sqData = {
                mode: "user_login_mfa",
                sq: data.converted,
                id: Check24.ul.email !== "" ? Check24.ul.email : Check24.ul.phone
            };
            var sqCurrent = {
                product: "check24_sso",
                id: Check24.ul.mfaSq.id
            };
            Check24.ul.ajax.sqValidate(sqData, sqCurrent, Check24.ul.email !== "" ? "email" : "phone", Check24.ul.callback.sqValidateMfa);
        } else {
            Check24.ul.error.set('#cl_mfasq_prefix', '.c24-uli-error-mfasq', "");
            Check24.ul.error.set('#cl_mfasq', '.c24-uli-error-mfasq', Check24.ul.error.msg.validate.phone);
        }
        Check24.ul.resetHeight();
    },
    pwReset: function(json) {
        var data = JSON.parse(json);
        if (data && data.data && data.data.status_code && data.data.status_code !== 200) {
            if (data.data.status_code === 690) {
                Check24.removeClass(".c24-uli-cl-pwreset-trigger .c24-uli-loading", "loading");
                var methods = data.data.api_data.methods;
                Check24.ul.pwResetData.methods = methods;
                if (methods.length > 1) {
                    methods.forEach(function(method) {
                        var recipient = method.recipient;
                        if (method.method === "account_email") {
                            if (!Check24.hasClass(".c24-uli-login", "c24-uli-login-phone")) {
                                recipient = Check24.getValue("#cl_login_ph");
                            }
                            Check24.ul.pwResetData.recipient = recipient;
                            Check24.document.querySelector(".c24-uli-choose-email").checked = true;
                            Check24.change("html", ".c24-uli-choose-email-account", recipient);
                            Check24.ul.pwResetData.method = "account_email";
                        } else if (method.method === "account_sms") {
                            if (Check24.hasClass(".c24-uli-login", "c24-uli-login-phone")) {
                                recipient = Check24.getValue("#cl_login_ph");
                            }
                            Check24.change("html", ".c24-uli-choose-sms-account", recipient);
                        }
                    });
                    Check24.ul.init("pwreset", "choose");
                } else {
                    Check24.ul.pwResetData.method = methods[0]["method"];
                    Check24.ul.pwResetData.recipient = Check24.getValue("#cl_login_ph");
                    if (methods[0]["method"] === "account_email") {
                        Check24.ul.init("pwreset", "email");
                    } else if (methods[0]["method"] === "account_sms") {
                        Check24.ul.init("pwreset", "phone");
                    }
                }
            } else if (data.data.status_code === 668) {
                if (Check24.hasClass(".c24-uli-login", "c24-uli-login-phone")) {
                    Check24.change("html", ".c24-uli-login-wrapper", poJD.callback.pw_reset.mobile_number);
                } else {
                    Check24.change("html", ".c24-uli-login-wrapper", poJD.callback.pw_reset.email_address);
                }
                Check24.ul.init("pwreset", "mcode");
            } else if (data.data.status_code === 657) {
                Check24.ul.pwResetRecipient = data.data.api_data.recipient;
                if (
                    data.hasOwnProperty("securequest") && data.securequest.hasOwnProperty("questions") &&
                    data.securequest.questions.length > 0
                ) {
                    Check24.ul.pwResetData.questions = data.securequest.questions;
                    if (
                        data.secure_product_hotline &&
                        data.secure_product_hotline[data.securequest.questions[0]['product']] &&
                        data.secure_product_hotline[data.securequest.questions[0]['product']] !== ""
                    ) {
                        var hotline = data.secure_product_hotline[data.securequest.questions[0]['product']];
                        if (hotline !== "" && hotline !== "undefined") {
                            Check24.ul.hotline = hotline;
                            Check24.ul.hotlineClean = hotline.replace(/[\- ]/g, '');
                            Check24.change("html", ".c24-uli-sq-contact", Check24.ul.hotline);
                            Check24.change("href", ".c24-uli-sq-contact", "tel:" + Check24.ul.hotlineClean);
                        }
                    }
                }
                Check24.ul.init("pwreset", "ecode");
            } else if (data.data.status_code === 663) {
                if (data.data.api_data.zipcode && data.data.api_data.birthday) {
                    Check24.show(".c24-uli-pwr-toggle-trigger");
                } else {
                    Check24.hide(".c24-uli-pwr-toggle-trigger");
                }
                if (data.data.api_data.zipcode) {
                    Check24.ul.init("pwreset", "zipcode");
                } else if (data.data.api_data.birthday) {
                    Check24.ul.init("pwreset", "birthday");
                } else {
                    console.log("api_data error");
                }
            } else if (data.data.status_code === 501) {
                console.log("unknown user");
            } else if (data.data.status_code === 658) {
                Check24.ul.error.set('.c24-uli-pwr-mtan .c24-uli-input-splitted', '.c24-uli-error-mtan', Check24.ul.error.msg.pwReset.wrongCode);
                Check24.ul.error.set('.c24-uli-pwr-etan .c24-uli-input-splitted', '.c24-uli-error-etan', Check24.ul.error.msg.pwReset.wrongCode);
            } else if (data.data.status_code === 664) {
                Check24.ul.error.set('#cl_zipcode', '.c24-uli-error-zipcode', Check24.ul.error.msg.pwReset.wrongZipcode);
                Check24.ul.error.set('#cl_birthday', '.c24-uli-error-birthday', Check24.ul.error.msg.pwReset.wrongBirthday);
            } else if (data.data.status_code === 659 || data.data.status_code === 509) {
                Check24.ul.error.set('.c24-uli-pwr-mtan .c24-uli-input-splitted', '.c24-uli-error-mtan', Check24.ul.error.msg.login.blocked);
                Check24.ul.error.set('.c24-uli-pwr-etan .c24-uli-input-splitted', '.c24-uli-error-etan', Check24.ul.error.msg.login.blocked);
                Check24.ul.error.set('#cl_zipcode', '.c24-uli-error-zipcode', Check24.ul.error.msg.login.blocked);
                Check24.ul.error.set('#cl_birthday', '.c24-uli-error-birthday', Check24.ul.error.msg.login.blocked);
                Check24.ul.error.set('#cl_sq', '.c24-uli-error-sq', Check24.ul.error.msg.login.blocked);
                Check24.change("html", ".c24-uli-pwreset .c24-uli-error-top-text", Check24.ul.error.msg.login.blocked);
                Check24.show('.c24-uli-pwreset .c24-uli-error-top');
                Check24.change("html", ".c24-uli-social-facebook .c24-uli-error-top-text", Check24.ul.error.msg.login.blocked);
                Check24.show('.c24-uli-social-facebook .c24-uli-error-top');
                Check24.ul.layout.removeSpinner();
            }
        } else if (data.status === 200) {
            Check24.ul.init("pwreset", "login");
        }
        Check24.ul.resetHeight();
    },
    sqValidate: function(json) {
        var data = JSON.parse(json);
        if (data.status === 200) {
            if (data.data) {
                if (data.data.status_code && data.data.status_code === 200) {
                    if (data.data.api_data && data.data.api_data.user_security_token && data.data.api_data.user_security_token !== "") {
                        Check24.ul.pwResetData.ecode = data.data.api_data.user_security_token;
                        Check24.ul.ajax.pwResetTan(Check24.ul.pwResetMode, Check24.ul.pwResetData, Check24.ul.loginType, Check24.ul.callback.pwReset);
                        Check24.ul.initTanResendTimeout();
                    }
                } else if (data.data.status_code && (data.data.status_code === 659 || data.data.status_code === 509)) {
                    Check24.ul.error.set('#cl_sq', '.c24-uli-error-sq', Check24.ul.error.msg.sq.blocked);
                    if (Check24.ul.hotline !== "" && Check24.ul.hotline !== "undefined") {
                        Check24.change("html", ".c24-uli-sq-contact", Check24.ul.hotline);
                        Check24.change("href", ".c24-uli-sq-contact", "tel:" + Check24.ul.hotlineClean);
                    }
                } else if (data.data.status_code && data.data.status_code === 659) {
                    Check24.show('.c24-uli-error-top');
                    Check24.change("html", ".c24-uli-error-top-text", Check24.ul.error.msg.login.blocked);
                } else {
                    if (data.data.api_data.remaining_attempts) {
                        if (data.data.api_data.remaining_attempts === 1) {
                            Check24.ul.error.set('#cl_sq', '.c24-uli-error-sq', Check24.ul.error.msg.sq.lastChance);
                        } else {
                            Check24.ul.error.set('#cl_sq', '.c24-uli-error-sq', Check24.ul.error.msg.pwReset.wrongAnswer);
                        }
                    }
                }
            }
        } else {
            Check24.ul.error.set('#cl_sq', '.c24-uli-error-sq', Check24.ul.error.msg.sq.blocked);
            if (Check24.ul.hotline !== "" && Check24.ul.hotline !== "undefined") {
                Check24.change("html", ".c24-uli-sq-contact", data.hotline);
                Check24.change("href", ".c24-uli-sq-contact", "tel:" + data.hotlineClean);
            }
        }
    },
    sqValidateMfa: function(json) {
        var data = JSON.parse(json);
        if (data.status === 200) {
            if (data.data) {
                if (data.data.status_code && data.data.status_code === 200) {
                    Check24.ul.mfaSq.token = data.data.api_data.user_security_token;
                    Check24.ul.tanData.code = Check24.ul.mfaSq.token;
                    Check24.ul.tanData.context = "user_login_mfa";
                    Check24.ul.ajax.tanValidate(Check24.ul.tanData, Check24.ul.callback.tanValidate);
                } else if (data.data.status_code && (data.data.status_code === 659 || data.data.status_code === 509)) {
                    Check24.ul.error.set('#cl_mfasq_prefix', '.c24-uli-error-mfasq', '');
                    Check24.ul.error.set('#cl_mfasq', '.c24-uli-error-mfasq', Check24.ul.error.msg.sq.blocked);
                } else {
                    if (data.data.api_data.remaining_attempts) {
                        if (data.data.api_data.remaining_attempts === 1) {
                            Check24.ul.error.set('#cl_mfasq_prefix', '.c24-uli-error-mfasq', '');
                            Check24.ul.error.set('#cl_mfasq', '.c24-uli-error-mfasq', Check24.ul.error.msg.sq.lastChance);
                        } else {
                            Check24.ul.error.set('#cl_mfasq_prefix', '.c24-uli-error-mfasq', '');
                            Check24.ul.error.set('#cl_mfasq', '.c24-uli-error-mfasq', Check24.ul.error.msg.pwReset.wrongAnswer);
                        }
                    }
                }
            }
        } else {
            Check24.ul.error.set('#cl_mfasq_prefix', '.c24-uli-error-mfasq', '');
            Check24.ul.error.set('#cl_mfasq', '.c24-uli-error-mfasq', Check24.ul.error.msg.sq.blocked);
        }
    },
    pwResetLogin: function(json) {
        var data = JSON.parse(json);
        if (data.status === 200) {
            if (data.points_xtreme && data.points_xtreme.title && data.points_xtreme.title !== "") {
                Check24.ul.pointsXtreme.title = data.points_xtreme.title;
            } else {
                Check24.ul.pointsXtreme.title = "";
            }
            if (data.points_xtreme && data.points_xtreme.content && data.points_xtreme.content !== "") {
                Check24.ul.pointsXtreme.content = data.points_xtreme.content;
            } else {
                Check24.ul.pointsXtreme.content = "";
            }
            if (data.loginServer) {
                Check24.ul.server = data.loginServer;
            }
            if (data.loginEnv) {
                Check24.ul.ssoEnv = data.loginEnv;
            }
            Check24.ul.loginPath = "login";
            Check24.removeTanSessionData();
            // trigger phone collector if registercheck triggered
            if (Check24.ul.registercheckTriggered && Check24.checkSldVisible()) {
                var phoneNumber = Check24.ul.userData.phone;
                Check24.change("value", ".c24-uli-input-sld-prefix", phoneNumber.substring(0, phoneNumber.indexOf(' ')));
                Check24.change("value", ".c24-uli-input-sld", phoneNumber.substring(phoneNumber.indexOf(' ') + 1));
                Check24.ul.convertedPhone = phoneNumber;
                Check24.ul.tanData.id = phoneNumber;
                Check24.ul.tanData.pw = null;
                Check24.ul.tanData.context = "user_edit_login-tan";
                Check24.ul.tanData.method = "sms";
                Check24.ul.tanContext = "editphone";
                Check24.ul.init("tan", "new");
                return;
            }
            var mtmData = {
                'event': 'uli_pw_reset',
                'uli_api_product': configService.getApiProduct(),
                'uli_sso_env': configService.getSsoEnv(),
                'uli_login_type': Check24.ul.loginType
            };
            try {
                if (window._mtm) {
                    _mtm.push(mtmData);
                }
            } catch (e) {
            }
            Check24.ul.callback.close(data);
        } else {
            Check24.ul.ajax.pwResetPw(Check24.ul.pwResetData, Check24.ul.loginType, Check24.ul.callback.pwResetPw);
        }
    },
    pwResetPw: function(json) {
        var data = JSON.parse(json);
        if (data.status === 200) {
            if (data.points_xtreme && data.points_xtreme.title && data.points_xtreme.title !== "") {
                Check24.ul.pointsXtreme.title = data.points_xtreme.title;
            } else {
                Check24.ul.pointsXtreme.title = "";
            }
            if (data.points_xtreme && data.points_xtreme.content && data.points_xtreme.content !== "") {
                Check24.ul.pointsXtreme.content = data.points_xtreme.content;
            } else {
                Check24.ul.pointsXtreme.content = "";
            }
            if (data.loginServer) {
                Check24.ul.server = data.loginServer;
            }
            if (data.loginEnv) {
                Check24.ul.ssoEnv = data.loginEnv;
            }
            Check24.ul.loginPath = "login";
            Check24.removeTanSessionData();

            var pwResetType = Check24.ul.pwReset2FA ? '2fa' : Check24.ul.loginType;
            var mtmData = {
                'event': 'uli_pw_reset',
                'uli_api_product': configService.getApiProduct(),
                'uli_sso_env': configService.getSsoEnv(),
                'uli_login_type': pwResetType
            };
            try {
                if (window._mtm) {
                    _mtm.push(mtmData);
                }
            } catch (e) {
            }

            // trigger phone collector if registercheck triggered
            if (Check24.ul.registercheckTriggered && Check24.checkSldVisible()) {
                var phoneNumber = Check24.ul.userData.phone;
                Check24.change("value", ".c24-uli-input-sld-prefix", phoneNumber.substring(0, phoneNumber.indexOf(' ')));
                Check24.change("value", ".c24-uli-input-sld", phoneNumber.substring(phoneNumber.indexOf(' ') + 1));
                Check24.ul.convertedPhone = phoneNumber;
                Check24.ul.tanData.id = phoneNumber;
                Check24.ul.tanData.pw = null;
                Check24.ul.tanData.context = "user_edit_login-tan";
                Check24.ul.tanData.method = "sms";
                Check24.ul.tanContext = "editphone";
                Check24.ul.init("tan", "new");
                return;
            }
            Check24.ul.callback.close(data);
        } else if (data.status === 499) {
            // password to short or to long
            Check24.ul.error.set("#cl_pwr_pw", ".c24-uli-error-pw", Check24.ul.error.msg.pw.shortPw);
            Check24.ul.error.set("#cl_pwr_pw_repeat", ".c24-uli-error-pw-repeat", Check24.ul.error.msg.pw.shortPw);
            return false;
        } else if (data.status === 511 || data.status === 682) {
            Check24.ul.error.set("#cl_pwr_pw", ".c24-uli-error-pwr-pw", Check24.ul.error.msg.pw.other);
            Check24.ul.error.set('#cl_pwr_pw_repeat', '.c24-uli-error-pwr-pw-repeat', Check24.ul.error.msg.pw.other);
        } else {
            Check24.change("html", ".c24-uli-error-top-text", Check24.ul.error.msg.login.blocked);
            Check24.show('.c24-uli-error-top');
        }
    },
    pwResetLoginInstead: function(json) {
        var data = JSON.parse(json);
        if (data.status === 200) {
            if (data.points_xtreme && data.points_xtreme.title && data.points_xtreme.title !== "") {
                Check24.ul.pointsXtreme.title = data.points_xtreme.title;
            } else {
                Check24.ul.pointsXtreme.title = "";
            }
            if (data.points_xtreme && data.points_xtreme.content && data.points_xtreme.content !== "") {
                Check24.ul.pointsXtreme.content = data.points_xtreme.content;
            } else {
                Check24.ul.pointsXtreme.content = "";
            }
            if (data.loginServer) {
                Check24.ul.server = data.loginServer;
            }
            if (data.loginEnv) {
                Check24.ul.ssoEnv = data.loginEnv;
            }
            Check24.ul.loginPath = "login";
            Check24.removeTanSessionData();
            var pwResetType = 'login_instead';
            var mtmData = {
                'event': 'uli_pw_reset',
                'uli_api_product': configService.getApiProduct(),
                'uli_sso_env': configService.getSsoEnv(),
                'uli_login_type': pwResetType
            };
            try {
                if (window._mtm) {
                    _mtm.push(mtmData);
                }
            } catch (e) {
            }
            Check24.ul.callback.close(data);
        } else {
            Check24.change("html", ".c24-uli-error-top-text", Check24.ul.error.msg.login.blocked);
            Check24.show('.c24-uli-error-top');
        }
    },
    register: function(json) {
        var data = JSON.parse(json);
        if (data.status === 200) {
            if (data.points_xtreme && data.points_xtreme.title && data.points_xtreme.title !== "") {
                Check24.ul.pointsXtreme.title = data.points_xtreme.title;
            } else {
                Check24.ul.pointsXtreme.title = "";
            }
            if (data.points_xtreme && data.points_xtreme.content && data.points_xtreme.content !== "") {
                Check24.ul.pointsXtreme.content = data.points_xtreme.content;
            } else {
                Check24.ul.pointsXtreme.content = "";
            }
            if (data.loginServer) {
                Check24.ul.server = data.loginServer;
            }
            if (data.loginEnv) {
                Check24.ul.ssoEnv = data.loginEnv;
            }
            Check24.ul.loginPath = "register";
            Check24.removeTanSessionData();
            Check24.ul.callback.close(data);
        } else if (data.status === 499) {
            // password to short or to long
            Check24.ul.error.set("#cl_pw_register", ".c24-uli-error-pw", Check24.ul.error.msg.pw.shortPw);
            Check24.ul.error.set("#cl_pw_register_repeat", ".c24-uli-error-pw-repeat", Check24.ul.error.msg.pw.shortPw);
            return false;
        } else if (data.status === 668) {
            Check24.ul.tanData.id = Check24.getValue("#cl_login_ph");
            Check24.ul.tanData.pw = Check24.getValue("#cl_pw_register");
            Check24.ul.tanData.context = "user_new";
            Check24.ul.tanData.method = "email";
            Check24.ul.tanContext = "new";
            Check24.ul.init("tan", "new");
        } else if (data.status === 683) {
            // login challenge needed
            Check24.ul.userPassword = Check24.getValue("#cl_pw_register");
            Check24.ul.initLoginChallenge(data);
        } else if (data.status === 503) {
            Check24.ul.init("register");
            Check24.ul.error.set("#cl_pw_register", ".c24-uli-error-pw", Check24.ul.error.msg.pw.other);
            Check24.ul.error.set('#cl_ul_pw_register_repeat', '.c24-uli-error-pw-repeat', Check24.ul.error.msg.pw.other);
            Check24.ul.resetHeight();
        } else {
            var login = Check24.getValue("#cl_login_ph");
            var password = Check24.getValue("#cl_pw_register");
            Check24.ul.ajax.login(login, password, Check24.ul.loginType, "", "", Check24.ul.userData, undefined, undefined, Check24.ul.callback.login, undefined);
        }
    },
    close: function(data) {
        Check24.ul.loginState = (data && data["session"]) ? data["session"] : "loggedin";
        Check24.ul.loginSession = (data && data["session"]) ? data["session"] : "";
        if (Check24.ul.userPoints === "no" && (configService.getPoints() === "checkbox" || configService.getPoints() === "radio")) {
            Check24.ul.forcePoints = true;
            Check24.ul.init("points", configService.getPoints());
            return false;
        }
        Check24.ul.closeCommunicaton();
    },
    tanNew: function(json) {
        var data = JSON.parse(json);
        if (data.status !== 200) {
            Check24.ul.error.set(".c24-uli-tan .c24-uli-input-splitted", ".c24-uli-error-tan", Check24.ul.error.msg.login.blocked);
            console.log("tan_new failed");
            return false;
        }
    },
    tanValidate: function(json) {
        var data = JSON.parse(json);
        if (data.status === 200) {
            if (data.data && data.data.validation_successful && data.data.validation_successful === true) {
                Check24.ul.tanData.code = Check24.exists("#cl_tan") ? Check24.getValue("#cl_tan") : "";
                if (Check24.ul.tanContext === "new") {
                    var email = Check24.ul.registercheckTriggered ? Check24.ul.userData.email : '';
                    Check24.ul.ajax.userNew(Check24.ul.tanData, Check24.ul.tanContext, email, Check24.ul.callback.userNew);
                } else if (Check24.ul.tanContext === "editphone") {
                    Check24.ul.ajax.editLogin(Check24.ul.convertedPhone, Check24.getValue("#cl_login"), Check24.ul.tanData.code, Check24.ul.callback.editLogin);
                } else if (Check24.ul.tanContext === "user_login_mfa") {
                    var login = Check24.getValue("#cl_login_ph");
                    var enableMfa = true;
                    var disableMfaInput = Check24.document.querySelector("#c24-uli-disable-mfa");
                    if (disableMfaInput.checked === true) {
                        enableMfa = false;
                    }
                    if (typeof Check24.ul.mfaSq.token !== "undefined") {
                        Check24.ul.tanData.code = Check24.ul.mfaSq.token;
                    }
                    Check24.ul.ajax.login(login, Check24.ul.userPassword, Check24.ul.loginType, Check24.ul.tanData.code, "", Check24.ul.userData, undefined, undefined, Check24.ul.callback.login, undefined, enableMfa);
                } else {
                    Check24.ul.init("renew", "pw");
                }
            } else {
                Check24.ul.error.set(".c24-uli-tan .c24-uli-input-splitted", ".c24-uli-error-tan", Check24.ul.error.msg.pwReset.wrongCode);
            }
        } else if (data.status === 429) {
            Check24.ul.error.set(".c24-uli-tan .c24-uli-input-splitted", ".c24-uli-error-tan", Check24.ul.error.msg.pwReset.wrongCode);
        } else if (data.status === 659) {
            Check24.ul.error.set(".c24-uli-tan .c24-uli-input-splitted", ".c24-uli-error-tan", Check24.ul.error.msg.login.blocked);
            Check24.ul.error.set(".c24-uli-mfasq .c24-uli-input-mfasq", ".c24-uli-error-mfasq", Check24.ul.error.msg.login.blocked);
        } else {
            Check24.ul.error.set(".c24-uli-tan .c24-uli-input-splitted", ".c24-uli-error-tan", Check24.ul.error.msg.login.blocked);
            Check24.ul.error.set(".c24-uli-mfasq .c24-uli-input-mfasq", ".c24-uli-error-mfasq", Check24.ul.error.msg.login.blocked);
        }
        Check24.ul.resetHeight();
    },
    userNew: function(json) {
        var data = JSON.parse(json);
        if (data.status === 200) {
            if (data.points_xtreme && data.points_xtreme.title && data.points_xtreme.title !== "") {
                Check24.ul.pointsXtreme.title = data.points_xtreme.title;
            } else {
                Check24.ul.pointsXtreme.title = "";
            }
            if (data.points_xtreme && data.points_xtreme.content && data.points_xtreme.content !== "") {
                Check24.ul.pointsXtreme.content = data.points_xtreme.content;
            } else {
                Check24.ul.pointsXtreme.content = "";
            }
            if (data.loginServer) {
                Check24.ul.server = data.loginServer;
            }
            if (data.loginEnv) {
                Check24.ul.ssoEnv = data.loginEnv;
            }
            Check24.ul.loginPath = "register";
            Check24.removeTanSessionData();
            // trigger phone collector if registercheck triggered
            if (Check24.ul.registercheckTriggered && Check24.ul.userData.exists !== null && Check24.checkSldVisible()) {
                var phoneNumber = Check24.ul.userData.phone;
                Check24.change("value", ".c24-uli-input-sld-prefix", phoneNumber.substring(0, phoneNumber.indexOf(' ')));
                Check24.change("value", ".c24-uli-input-sld", phoneNumber.substring(phoneNumber.indexOf(' ') + 1));
                Check24.ul.convertedPhone = phoneNumber;
                Check24.ul.tanData.id = phoneNumber;
                Check24.ul.tanData.pw = null;
                Check24.ul.tanData.context = "user_edit_login-tan";
                Check24.ul.tanData.method = "sms";
                Check24.ul.tanContext = "editphone";
                Check24.ul.init("tan", "new");
                return;
            }
            Check24.ul.callback.close(data);
        } else if (data.status === 499) {
            // password to short or to long
            Check24.ul.error.set("#cl_pw_register", ".c24-uli-error-pw", Check24.ul.error.msg.pw.shortPw);
            Check24.ul.error.set("#cl_pw_register_repeat", ".c24-uli-error-pw-repeat", Check24.ul.error.msg.pw.shortPw);
            return false;
        } else if (data.status === 683) {
            // login challenge needed
            Check24.ul.userPassword = Check24.getValue("#cl_pw_register");
            Check24.ul.initLoginChallenge(data);
        } else if (data.status === 503) {
            Check24.ul.init("renew", "pw");
            Check24.ul.error.set("#cl_pw_renew", ".c24-uli-error-pw", Check24.ul.error.msg.pw.other);
            Check24.ul.error.set('#cl_pw_renew_repeat', '.c24-uli-error-pw-repeat', Check24.ul.error.msg.pw.other);
            Check24.ul.resetHeight();
        } else {
            // try login because password may be the same -> throws error in the SSO-core
            Check24.change("html", ".c24-uli-error-top-text", Check24.ul.error.msg.login.blocked);
            Check24.show('.c24-uli-error-top');
            return false;
        }
    },
    deactivate: function(json) {
        var data = JSON.parse(json);
        if (data.status !== 200) {
            Check24.ul.error.set('', '.c24-uli-error-top-text', poJD.error.msg.deactivate.blocked);
            Check24.show(".c24-uli-error-top");
            return false;
        } else {
            Check24.ul.tanData.consecutiveTan = data.data.consecutive_tan_code;
            Check24.ul.ajax.userNew(Check24.ul.tanData, Check24.ul.tanContext, '', Check24.ul.callback.userNew);
        }
    },
    validate: function(json) {
        var data = JSON.parse(json);
        Check24.ul.loginPrefill = false;
        if (data.status !== 200) {
            console.log("user_validate failed");
            Check24.ul.init("login","");
            return false;
        } else {
            if (data.hasOwnProperty("c24session_state")) {
                Check24.ul.userState = data.c24session_state;
            }

            Check24.ul.userLoginType = Check24.ul.userLoginType !== null ? Check24.ul.userLoginType :
                (data.hasOwnProperty("last_login_provider") && data.last_login_provider !== "" ? data.last_login_provider : null);

            Check24.ul.userLoginEmail = Check24.ul.userLoginEmail !== null ? Check24.ul.userLoginEmail :
                (data.hasOwnProperty("login_email") && data.login_email !== "" ? data.login_email : null);

            Check24.ul.userLoginPhone = Check24.ul.userLoginPhone !== null ? Check24.ul.userLoginPhone :
                (data.hasOwnProperty("login_phone") && data.login_phone !== "" ? data.login_phone : null);

            if (data.hasOwnProperty("firstname")) {
                Check24.ul.userFirstName = data.firstname;
            }
            if (data.hasOwnProperty("lastname")) {
                Check24.ul.userLastName = data.lastname;
            }
            if (data.hasOwnProperty("gender")) {
                Check24.ul.userGender = data.gender;
            }
            if (data.hasOwnProperty("loginEnv")) {
                Check24.ul.ssoEnv = data.loginEnv;
            }
            if (data.hasOwnProperty("points_user")) {
                Check24.ul.userPoints = data.points_user;
            }
            if (data.hasOwnProperty("points_xtreme")) {
                Check24.ul.pointsXtreme.title = data.points_xtreme.hasOwnProperty("title") ? data.points_xtreme.title : "";
                Check24.ul.pointsXtreme.content = data.points_xtreme.hasOwnProperty("content") ? data.points_xtreme.content : "";
            }
            if (configService.getMode() === "pwreset") {
                Check24.ul.init("login", "");
            } else {
                if (
                    Check24.ul.userState === "active" &&
                    Check24.ul.userPoints !== "yes" &&
                    (configService.getPoints() === "checkbox" || configService.getPoints() === "radio")
                ) {
                    if (data.login_email) {
                        Check24.ul.email = data.login_email;
                    }
                    if (data.login_phone) {
                        Check24.ul.phone = data.login_phone;
                    }
                    Check24.ul.forcePoints = true;
                    Check24.ul.init("points", configService.getPoints());
                    Check24.ul.loginState = "loggedin";
                    Check24.ul.initShowPoints = true;
                } else {
                    Check24.ul.init("login", "");
                }
            }
        }
    },
    editLogin: function(json) {
        var data = JSON.parse(json);
        if (data.status !== 200) {
            Check24.ul.error.set('', '.c24-uli-error-top-text', poJD.error.msg.deactivate.blocked);
            Check24.show(".c24-uli-error-top");
            return false;
        } else {
            Check24.ul.callback.close(Check24.ul.loginData);
        }
    },
    loginProcess: function(json) {
        var data = JSON.parse(json);
        if (typeof data.data !== 'undefined' && typeof data.data.login_status !== 'undefined' && data.data.login_status === 'blocked') {
            var login = Check24.getValue("#cl_login_ph");
            Check24.change("html", ".c24-uli-pwreset .c24-uli-login-wrapper-explicit", sanitizer.html(login));
            if (Check24.ul.loginType === "phone") {
                Check24.change("html", ".c24-uli-pwreset .c24-uli-login-wrapper", poJD.trigger.pwreset.mobile);
                Check24.ul.pwResetData.id = login;
            } else {
                Check24.change("html", ".c24-uli-pwreset .c24-uli-login-wrapper", poJD.trigger.pwreset.email);
                Check24.ul.pwResetData.id = login;
            }
            Check24.ul.init("pwreset", "check");
        } else {
            if (data.recipient) {
                Check24.ul.loginProcessData.recipient = data.recipient;
            }
            if (data.login_process_id) {
                Check24.ul.loginProcessData.id = data.login_process_id;
            }
            Check24.ul.tanLoginProcessStarted = true;
            Check24.ul.init("loginprocess", "tan");
        }
    },
    tsError: function() {
        Check24.ul.error.set('', '.c24-uli-error-top-text', poJD.error.msg.deactivate.validate);
        Check24.show(".c24-uli-error-top");
        Check24.ul.resetHeight();
    }
}