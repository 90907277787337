import {sanitizer} from "../helpers/sanitizer";
import Check24 from "../unifiedlogin_desktop";

export default function() {
    if (
        Check24.ul.userLoginType === null ||
        (Check24.ul.userLoginEmail === null && Check24.ul.userLoginPhone === null) ||
        (Check24.ul.userLoginPhone !== null && Check24.ul.userLoginType === "check24_phone" && !Check24.validate.phone(Check24.ul.userLoginPhone)) ||
        (Check24.ul.userLoginEmail !== null && Check24.ul.userLoginType === "check24" && !Check24.validate.email(Check24.ul.userLoginEmail))
    ) {
        return;
    }

    var prefilledLogin = Check24.ul.userLoginType === "check24_phone" && Check24.ul.userLoginPhone !== null ?
        Check24.ul.userLoginPhone :
        (Check24.ul.userLoginEmail !== null ? Check24.ul.userLoginEmail : "");
    Check24.change("value", "#cl_login", prefilledLogin);

    if (!Check24.ul.useTurnstile) {
        if (Check24.ul.userLoginPhone !== null && Check24.ul.userLoginType === "check24_phone") {
            Check24.ul.convertMobileStart = true;
            Check24.ul.prefilled = true;
            Check24.ul.ajax.convertMobile(Check24.ul.userLoginPhone, "", Check24.ul.callback.convertMobile);
        } else {
            if (Check24.ul.userLoginEmail !== null && Check24.validate.email(Check24.ul.userLoginEmail)) {
                Check24.ul.prefilled = true;
                Check24.removeClass(".c24-uli-login", "c24-uli-login-phone");
                Check24.change("value", "#cl_login_ph", Check24.ul.userLoginEmail);
                Check24.change("html", ".c24-uli-user-data", sanitizer.html(Check24.ul.userLoginEmail));
                Check24.change("html", ".c24-uli-login-wrapper", sanitizer.html(Check24.ul.userLoginEmail));
                Check24.ul.ajax.exists(Check24.ul.userLoginEmail, "email", Check24.ul.userData, Check24.ul.callback.exists);
            }
        }
    } else {
        Check24.ul.prefilled = true;
        Check24.ul.init("login", "");
        setTimeout(function(){
            var event = new CustomEvent("uli_loginListener");
            Check24.ul.layout.setSpinner(event, Check24.document.querySelector('#c24-uli-login-btn'));
            Check24.ul.listener.loginListener(event);
            Check24.show(".c24-uli-turnstile iframe");
        },200);
    }
}
