class Sanitizer {

    /**
     * Replace special HTML characters
     */
    html(s) {
        if (typeof s !== "string") {
            return s;
        }
        const map = {
            '&': '&amp;',
            '<': '&lt;',
            '>': '&gt;',
            '"': '&quot;',
            "'": '&#x27;',
            "/": '&#x2F;',
        };
        const reg = /[&<>"'/]/ig;
        return s.replace(reg, (match) => (map[match]));
    }

    tan(s) {
        if (typeof s === "undefined" || s === null) {
            return s;
        }
        if (typeof s !== "string") {
            return "";
        }
        return s.replace(/[^0-9a-zA-Z]/g, "");
    }

    product(s) {
        if (typeof s === "undefined" || s === null) {
            return s;
        }
        if (typeof s !== "string") {
            return null;
        }
        let product = s.replace(/[^a-z0-9_-]/ig, "");
        if (product !== "") {
            return product;
        }
        return null;
    }

    pixel(s) {
        if (typeof s === "undefined" || s === null) {
            return s;
        }
        if (typeof s !== "string") {
            return "";
        }
        let pixel = s.replace(/[^a-z0-9%/.]/ig, "");
        if (pixel !== "") {
            return pixel;
        }
        return null;
    }

    allowedWords(s, words) {
        if (typeof s === "undefined" || s === null) {
            return s;
        }
        let word;
        for (word of words) {
            if (s === word) {
                return s;
            }
        }
        return null;
    }

    url(s) {
        if (typeof s === "undefined" || s === null) {
            return s;
        }
        if (typeof s !== "string") {
            return null;
        }
        try {
            let url = new URL(s);
            return url.toString();
        } catch (e) {
            return null;
        }
    }

    email(s) {
        if (typeof s === "undefined" || s === null) {
            return s;
        }
        if (typeof s !== "string") {
            return null;
        }
        let re = /^([^<>()\[\]\\.,;:\s@"!%]+(\.[^<>()\[\]\\.,;:\s@"!%]+)*)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,13}))$/;
        if (re.test(s)) {
            return s;
        }
        return null;
    }

    phone(s) {
        if (typeof s === "undefined" || s === null) {
            return s;
        }
        if (typeof s !== "string") {
            return null;
        }
        let re = /[+]*[(]{0,1}[0-9]{1,5}[)]{0,1}[\/]{0,1}[-\s\./0-9]*/g;
        if (re.test(s)) {
            return s;
        }
        return null;
    }

    domain(s) {
        if (typeof s === "undefined" || s === null) {
            return s;
        }
        if (typeof s !== "string") {
            return null;
        }
        let re = /^[a-zA-Z0-9.:-]+$/;
        if (re.test(s)) {
            return s;
        }
        return null;
    }

}

export const sanitizer = new Sanitizer();