import Check24 from "../unifiedlogin_mobile";
import {poJM} from "../po/po.js_mobile";

export default {
    msg: {
        validate: {
            email: poJM.error.msg.validate.email,
            phone: poJM.error.msg.validate.mobile,
            phoneUsed: poJM.error.msg.validate.mobile_used,
            email_or_phone: poJM.error.msg.validate.email_or_phone,
            blacklistedEmail: poJM.error.msg.validate.blacklisted_email
        },
        noValue: {
            email: poJM.error.msg.no_value.email,
            phone: poJM.error.msg.no_value.phone,
            emailOrPhone: poJM.error.msg.noValue.email_or_phone,
            pw: poJM.error.msg.no_value.pw,
            code: poJM.error.msg.no_value.code,
            sq: poJM.error.msg.no_value.sq,
            firstname: poJM.error.msg.no_value.firstname,
            lastname: poJM.error.msg.no_value.lastname
        },
        login: {
            last: poJM.error.msg.login.last,
            blocked: "<span class=\"c24-uli-error-icon\">" +
                "<div class=\"c24-uli-exclamation-mark\"></div>" +
                "</span>" +
                "<span class=\"c24-uli-error-text\">" + poJM.error.msg.login.blocked + "</span>",
            fraud:  "<span class=\"c24-uli-error-icon\">" +
                "<div class=\"c24-uli-exclamation-mark\"></div>" +
                "</span>" +
                "<span class=\"c24-uli-error-text\">" + poJM.error.msg.login.fraud + "</span>",
            fail: poJM.error.msg.login.fail,
            failPhone: poJM.error.msg.login.fail_phone,
            failCode: poJM.error.msg.login.fail_code
        },
        pwReset: {
            noCode: poJM.error.msg.pw_reset.no_code,
            wrongCode: poJM.error.msg.pw_reset.wrong_code,
            noZipcode: poJM.error.msg.pw_reset.no_zip,
            falseZipcode: poJM.error.msg.pw_reset.false_zip,
            wrongZipcode: poJM.error.msg.pw_reset.wrong_zip,
            noPw: poJM.error.msg.pw_reset.no_pw,
            shortPw: poJM.error.msg.pw_reset.short_pw,
            noBirthday: poJM.error.msg.pw_reset.no_birthday,
            falseBirthday: poJM.error.msg.pw_reset.false_birthday,
            wrongBirthday: poJM.error.msg.pw_reset.wrong_birthday,
            wrongAnswer: poJM.error.msg.pw_reset.wrong_answer,
        },
        loginChallenge: {
            noZipcode: poJM.error.msg.login_challenge.no_zip,
            falseZipcode: poJM.error.msg.login_challenge.false_zip,
            wrongZipcode: poJM.error.msg.login_challenge.wrong_zip,
            noBirthday: poJM.error.msg.login_challenge.no_birthday,
            falseBirthday: poJM.error.msg.login_challenge.false_birthday,
            wrongBirthday: poJM.error.msg.login_challenge.wrong_birthday,
        },
        register: {
            shortPw: poJM.error.msg.register.short_pw
        },
        pw: {
            length: poJM.error.msg.pw.length,
            repeat: poJM.error.msg.pw.repeat,
            repeatEmpty: poJM.error.msg.pw.repeat_empty,
            shortPw: poJM.error.msg.pw.short_pw,
            other: poJM.error.msg.pw.other,
            strong: poJM.error.msg.pw.strong
        },
        sq: {
            lastChance: poJM.error.msg.sq.last_chance,
            blocked: poJM.error.msg.sq.blocked
        }
    },
    set: function(inputEle, errorEle, msg) {
        Check24.ul.layout.removeSpinner();
        if (inputEle !== "") {
            var input = Check24.document.querySelector(inputEle);
            var parentEle = input.parentElement;

            var childEles = parentEle.children;
            for (var ele = 0; ele < childEles.length; ele++) {
                if (childEles[ele].classList && childEles[ele].classList.contains("c24-uli-input-inner-label")) {
                    var label = childEles[ele];
                }
            }
            if (input) {
                Check24.addClass(inputEle, "c24-uli-input-error");
            }
            if (label) {
                label.classList.add("c24-uli-label-error");
            }
        }
        if (errorEle !== "" && msg !== "") {
            Check24.change("html", errorEle, msg);
            Check24.show(errorEle);
        }
        Check24.ul.resetHeight();
    },
    unset: function(inputEle, errorEle) {
        if (inputEle && errorEle) {
            Check24.removeClass(inputEle, "c24-uli-input-error");
            Check24.removeClass(".c24-uli-input-inner-label", "c24-uli-label-error");
            Check24.hide(errorEle);
        } else {
            Check24.removeClass("input", "c24-uli-input-error");
            Check24.hide(".c24-uli-error");
            Check24.change("html", ".c24-uli-cl-pw-strength-value", "");
            var elements = Check24.document.querySelectorAll(".c24-uli-cl-pw-strength-status");
            for (var i = 0; i < elements.length; i++) {
                elements[i].style.width = "0";
            }
        }
        Check24.hide(".c24-uli-points-error");
        Check24.removeClass(".c24-uli-points-radio", "c24-uli-error-points");
        Check24.ul.resetHeight();
    }
}