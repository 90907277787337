import {sendRequest} from "./sendRequest";
import {configService} from "./services/configService";
import Check24 from "./unifiedlogin_mobile";

export default {
    existsParams: {
        a: null,
        b: null,
        c: null,
        d: configService.getApiProduct(),
        e: configService.getPoints(),
        f: configService.getTransactionType(),
        g: configService.getCustomParam(),
    },
    tsListener: function(e) {
        try {
            var eventName = '';
            var data = {
                name: e.data.name ?? '',
                token: e.data.value ?? '',
                error: false
            }
            switch (data.name) {
                case 'uli_tsStart':
                    Check24.ul.turnstileLoading = true;
                    eventName = 'uli_ts_start';
                    break;
                case 'uli_tsToken':
                    Check24.ul.turnstileLoading = false;
                    Check24.ul.userData.tsToken = data.token;
                    Check24.ul.ajax.existsRequest();
                    eventName = 'uli_ts_success';
                    break;
                case 'uli_tsInteractiveCallbackStart':
                    eventName = 'uli_ts_challenge';
                    break;
                case 'uli_tsInteractiveCallbackEnd':
                    break;
                case 'uli_tsError':
                    Check24.ul.turnstileLoading = false;
                    Check24.ul.callback.tsError();
                    Check24.hide('.c24-uli-turnstile iframe');
                    eventName = 'uli_ts_fail';
                    break;
                case 'size':
                    Check24.ul.layout.updateTsFrame(e.data.size ?? '');
                    break;
                default:
                    break;
            }

            try {
                if (window._mtm && eventName !== '') {
                    _mtm.push({
                        'event': eventName,
                        'uli_api_product': configService.getApiProduct(),
                        'deviceoutput': configService.getDeviceoutput(),
                        'uli_login_type': Check24.ul.loginType
                    });
                }
            } catch (e) {
            }
        } catch (e) {
            console.log('pm failed');
        }
    },
    existsRequest: function() {
        sendRequest(
            "ajax/user/existsextended/",
            Check24.ul.ajax.existsParams,
            Check24.ul.ajax.existsCallback,
            Check24.ul.ajax.existsCallbackError
        );
    },
    existsCallback: null,
    existsCallbackError: null,
    exists: function (email, type, userData, callback, callbackError) {
        Check24.ul.ajax.existsParams = {
            a: encodeURIComponent(email),
            b: encodeURIComponent(type),
            c: encodeURIComponent(userData.exists),
            d: configService.getApiProduct(),
            e: configService.getPoints(),
            f: configService.getTransactionType(),
            g: configService.getCustomParam(),
        };

        Check24.ul.ajax.existsCallback = callback;
        Check24.ul.ajax.existsCallbackError = callbackError;

        if (Check24.ul.useTurnstile) {
            if (Check24.ul.turnstileLoading) {
                return;
            }
            window.removeEventListener('message', Check24.ul.ajax.tsListener, false);
            window.addEventListener('message', Check24.ul.ajax.tsListener, false);
            var tsEle = Check24.document.querySelector('.c24-uli-turnstile');
            var tsCnt = tsEle.querySelector('iframe');
            var tsIframe = document.createElement('iframe');
            tsIframe.src = configService.getBasePath() + 'api/v1/turnstile/?tsMode=' + Check24.ul.turnstileMode;
            tsIframe.setAttribute('scrolling', 'no');
            if (tsCnt) {
                tsCnt.remove();
            }
            tsEle.appendChild(tsIframe);
            Check24.ul.resetHeight();
        } else {
            Check24.ul.ajax.existsRequest();
        }
    },
    login: function(email, password, loginType, tan, loginProcessId, userData, challengeId, challengeResponse, callback, callbackError, enableMfa) {
        sendRequest(
            "ajax/user/login/",
            {
                login: encodeURIComponent(email),
                password:  encodeURIComponent(password),
                type: loginType,
                tan: tan,
                login_process_id: loginProcessId,
                product: configService.getApiProduct(),
                points: configService.getPoints(),
                transaction_type: configService.getTransactionType(),
                terms: !userData.terms ? "yes" : undefined,
                custom_param: configService.getCustomParam(),
                challenge_id: challengeId,
                challenge_response: challengeResponse,
                auth_phone: configService.getLoginDataSet(),
                enable_mfa: enableMfa
            },
            callback,
            callbackError
        );
    },
    loginChallengeStart: function(challengeId, challengeType, login, type, callback, callbackError) {
        var requestAllowed = Check24.setTanSessionData("lc");
        if  (!requestAllowed) {
            callback(JSON.stringify({
                "status": 200
            }));
            return;
        }
        sendRequest(
            "ajax/user/loginchallenge/",
            {
                challenge_id: challengeId,
                challenge_type:  challengeType,
                product: configService.getApiProduct(),
                login: encodeURIComponent(login),
                type: encodeURIComponent(type)
            },
            callback,
            callbackError
        );
    },
    convertMobile: function(phone, password, callback, callbackError) {
        sendRequest(
            "ajax/user/phone/",
            {
                login: encodeURIComponent(phone),
                product: configService.getApiProduct()
            },
            callback,
            callbackError
        );
    },
    register: function(email, password, firstname, lastname, loginType, callback, callbackError) {
        sendRequest(
            "ajax/user/register/",
            {
                login: encodeURIComponent(email),
                password: encodeURIComponent(password),
                firstname: encodeURIComponent(firstname),
                lastname: encodeURIComponent(lastname),
                email: encodeURIComponent(email),
                type: loginType,
                product: configService.getApiProduct(),
                points: configService.getPoints(),
                transaction_type: configService.getTransactionType(),
                custom_param: configService.getCustomParam()
            },
            callback,
            callbackError
        );
    },
    pwResetTan: function(type, pwResetData, loginType, callback, callbackError) {
        if (pwResetData.method !== null && (Check24.ul.pwResetMode === "email" || Check24.ul.pwResetMode === "phone")) {
            var requestAllowed = Check24.setTanSessionData("pw_reset");
            if (!requestAllowed) {
                callback(JSON.stringify({
                    "data": {
                        "status_code": 668
                    }
                }));
                return;
            }
        }
        sendRequest(
            "ajax/user/passwordresetauth/",
            {
                login: encodeURIComponent(pwResetData.id),
                tan_method: encodeURIComponent(pwResetData.method) === "null" ? "" : encodeURIComponent(pwResetData.method),
                tan_code: encodeURIComponent(pwResetData.mcode) === "null" ? "" : encodeURIComponent(pwResetData.mcode),
                secure_tan_code: encodeURIComponent(pwResetData.ecode) === "null" ? "" : encodeURIComponent(pwResetData.ecode),
                zipcode: encodeURIComponent(pwResetData.zipcode) === "null" ? "" : encodeURIComponent(pwResetData.zipcode),
                birthday: encodeURIComponent(pwResetData.birthday) === "null" ? "" : encodeURIComponent(pwResetData.birthday),
                product: configService.getApiProduct(),
                type: loginType
            },
            callback,
            callbackError
        );
    },
    pwResetPw: function (pwResetData, loginType, callback, callbackError) {
        sendRequest(
            "ajax/user/passwordreset/",
            {
                login: encodeURIComponent(pwResetData.id),
                password: encodeURIComponent(pwResetData.password) === "null" ? "" : encodeURIComponent(pwResetData.password),
                tan_code: encodeURIComponent(pwResetData.mcode) === "null" ? "" : encodeURIComponent(pwResetData.mcode),
                secure_tan_code: encodeURIComponent(pwResetData.ecode) === "null" ? "" : encodeURIComponent(pwResetData.ecode),
                zipcode: encodeURIComponent(pwResetData.zipcode) === "null" ? "" : encodeURIComponent(pwResetData.zipcode),
                birthday: encodeURIComponent(pwResetData.birthday) === "null" ? "" : encodeURIComponent(pwResetData.birthday),
                login_instead: encodeURIComponent(pwResetData.loginInstead) === "null" ? "no" : encodeURIComponent(pwResetData.loginInstead),
                points_user: encodeURIComponent(Check24.ul.userData.points),
                type: loginType,
                product: configService.getApiProduct(),
                points: configService.getPoints(),
                transaction_type: configService.getTransactionType(),
                custom_param: configService.getCustomParam()
            },
            callback,
            callbackError
        );
    },
    sqValidate: function(sqData, sqCurrent, loginType, callback, callbackError) {
        sendRequest(
            "ajax/user/sqvalidate/",
            {
                response: encodeURIComponent(sqData.sq),
                product: encodeURIComponent(sqCurrent.product),
                qid: encodeURIComponent(sqCurrent.id),
                type: loginType,
                login: encodeURIComponent(sqData.id),
                mode: sqData.mode ? encodeURIComponent(sqData.mode) : ""
            },
            callback,
            callbackError
        );
    },
    tanNew: function(tanData, callback, callbackError) {
        var requestAllowed = Check24.setTanSessionData(tanData.context === "null" ? "" : tanData.context);
        if  (!requestAllowed) {
            callback(JSON.stringify({
                "status": 200
            }));
            return;
        }
        sendRequest(
            "ajax/user/tannew/",
            {
                login: encodeURIComponent(tanData.id),
                tan_context: encodeURIComponent(tanData.context) === "null" ? "" : encodeURIComponent(tanData.context),
                method: encodeURIComponent(tanData.method) === "null" ? "" : encodeURIComponent(tanData.method),
                product: configService.getApiProduct(),
                type: encodeURIComponent(tanData.type),
                methodOverwrite: encodeURIComponent(tanData.methodOverwrite)
            },
            callback,
            callbackError
        );
    },
    tanValidate: function(tanData, callback, callbackError) {
        sendRequest(
            "ajax/user/tanvalidate/",
            {
                login: encodeURIComponent(tanData.id),
                tan_context: encodeURIComponent(tanData.context) === "null" ? "" : encodeURIComponent(tanData.context),
                tan: encodeURIComponent(tanData.code) === "null" ? "" : encodeURIComponent(tanData.code),
                product: configService.getApiProduct(),
                type: encodeURIComponent(tanData.type),
                methodOverwrite: encodeURIComponent(tanData.methodOverwrite)
            },
            callback,
            callbackError
        );
    },
    userNew: function(tanData, tanContext, email, callback, callbackError) {
        sendRequest(
            "ajax/user/usernew/",
            {
                login: encodeURIComponent(tanData.id),
                password: encodeURIComponent(tanData.pw),
                firstname: tanData.firstname === null ? "" : encodeURIComponent(tanData.firstname),
                lastname: tanData.lastname === null ? "" : encodeURIComponent(tanData.lastname),
                email: encodeURIComponent(email),
                tan: tanContext === "new" ? encodeURIComponent(tanData.code) : encodeURIComponent(tanData.consecutiveTan),
                product: configService.getApiProduct(),
                points: configService.getPoints(),
                transaction_type: configService.getTransactionType(),
                custom_param: configService.getCustomParam()
            },
            callback,
            callbackError
        );
    },
    deactivate: function(tanData, callback, callbackError) {
        sendRequest(
            "ajax/user/deactivate/",
            {
                login: encodeURIComponent(tanData.id),
                tan_code: encodeURIComponent(tanData.code),
                product: configService.getApiProduct(),
            },
            callback,
            callbackError
        );
    },
    validate: function(callback, callbackError) {
        sendRequest(
            "ajax/user/validate/",
            {
                product: configService.getApiProduct(),
                points: configService.getPoints(),
                transaction_type: configService.getTransactionType(),
                custom_param: configService.getCustomParam()
            },
            callback,
            callbackError
        );
    },
    pointsRegister: function(startPointsRegister, callback, callbackError, timeout, callbackTimeout) {
        sendRequest(
            "ajax/user/pointsdoi/",
            {
                product: configService.getApiProduct(),
                points_register: startPointsRegister
            },
            callback,
            callbackError,
            timeout,
            callbackTimeout
        );
    },
    config: function(callback, callbackError) {
        let data = {};
        if (configService.getApiProduct() !== null) {
            data.product = configService.getApiProduct();
        }
        if (configService.getConfigKey() !== null) {
            data.config_key = configService.getConfigKey();
        }
        sendRequest(
            "config.json",
            data,
            callback,
            callbackError
        );
    },
    csrfToken: function(callback, callbackError) {
        sendRequest(
            "ajax/csrftoken/",
            {},
            callback,
            callbackError
        );
    },
    editLogin: function(login, email, tan, callback, callbackError) {
        sendRequest(
            "ajax/user/editlogin/",
            {
                login: encodeURIComponent(login),
                email: encodeURIComponent(email),
                tan_code: encodeURIComponent(tan),
                product: configService.getApiProduct(),
            },
            callback,
            callbackError
        );
    },
    loginProcess: function(login, loginProvider, callback, callbackError) {
        sendRequest(
            "ajax/user/loginprocess/",
            {
                login: encodeURIComponent(login),
                login_provider: encodeURIComponent(loginProvider),
                product: configService.getApiProduct(),
            },
            callback,
            callbackError
        );
    },
};
