import {configService} from "../services/configService";
import {sanitizer} from "../helpers/sanitizer";
import {poJM} from "../po/po.js_mobile";
import Check24 from "../unifiedlogin_mobile";

export default {
    fillHtml: function() {
        var iconEles = Check24.document.querySelectorAll(".c24-uli-tan-text-icon");
        iconEles.forEach(function (iconEle) {
            var iconPwrEmail = Check24.document.querySelector(".c24-uli-pwr-mtan .c24-uli-pwr-icon-email").cloneNode(true);
            var iconPwrPhone = Check24.document.querySelector(".c24-uli-pwr-mtan .c24-uli-pwr-icon-phone").cloneNode(true);
            iconEle.insertBefore(iconPwrEmail, iconEle.childNodes[0]);
            iconEle.insertBefore(iconPwrPhone, iconEle.childNodes[0]);
        });
        var iconElesEmail = Check24.document.querySelectorAll(".c24-uli-tan-text-icon-email");
        iconElesEmail.forEach(function (iconEle) {
            var iconPwrEmail = Check24.document.querySelector(".c24-uli-pwr-mtan .c24-uli-pwr-icon-email").cloneNode(true);
            iconEle.insertBefore(iconPwrEmail, iconEle.childNodes[0]);
        });
        var iconElesPhone = Check24.document.querySelectorAll(".c24-uli-tan-text-icon-phone");
        iconElesPhone.forEach(function (iconEle) {
            var iconPwrPhone = Check24.document.querySelector(".c24-uli-pwr-mtan .c24-uli-pwr-icon-phone").cloneNode(true);
            iconEle.insertBefore(iconPwrPhone, iconEle.childNodes[0]);
        });
    },
    change: function(state, option) {
        if (option === "check") {
            return;
        }
        var layer = state.toString();
        Check24.ul.layout.reset();
        Check24.show([".c24-uli", ".c24-uli-" + layer]);
        Check24.document.querySelector(".c24-uli").style.visibility = "visible";
        Check24.ul.layout.ux(state, option);
    },
    reset: function(type) {
        Check24.hide([".c24-uli-ele", ".c24-uli-error", ".c24-uli-hover"]);
        Check24.ul.layout.removeSpinner();
        Check24.removeClass("input", "c24-uli-input-error");
        Check24.removeClass(".c24-uli-input-inner-label", "c24-uli-label-error");
        if (Check24.ul.pwInputDeleteEnabled) {
            var inputsPw = Check24.document.querySelectorAll('.c24-uli-input-pw');
            inputsPw.forEach(function (inputPw) {
                inputPw.value = "";
                inputPw.type = "password";
            });
            Check24.removeClass(".c24-uli-pw-toggle", "cleartext");
            Check24.removeClass(".c24-uli-cl-pw-strength-info-icon", "open");
        }
        var inputs = Check24.document.querySelectorAll('.c24-uli-input');
        inputs.forEach(function(input){
            if (input.value === "") {
                input.classList.remove("active");
                var parentEles = input.parentNode.childNodes;
                parentEles.forEach(function(parentEle){
                    if(parentEle.classList && parentEle.classList.contains("c24-uli-input-inner-label")) {
                        parentEle.remove();
                    }
                });
            }
        });
        if (type && type === "hard") {
            Check24.hide([".c24-uli", ".c24-uli-bottom-toggle"]);
            Check24.ul.resetHeight();
        }
    },
    spinnerHtml: "<div class=\"c24-uli-loading\"><div class=\"c24-uli-loading-ring\"><div><div></div><div></div><div></div><div></div></div></div></div>",
    setSpinner: function (e, ele) {
        if (ele.querySelector(".c24-uli-loading") === null) {
            var spinnerWrapper = ele.querySelector("span");
            if (spinnerWrapper != null) {
                spinnerWrapper.insertAdjacentHTML("beforeend", Check24.ul.layout.spinnerHtml);
            }
        }
    },
    removeSpinner: function () {
        var buttons = Check24.document.querySelectorAll(".c24-uli-button .c24-uli-loading");
        buttons.forEach(function(button) {
            button.remove();
        });
    },
    setPwInfo: function() {
        var lists = Check24.document.querySelectorAll(".c24-uli-list-pw");
        var secureList = Check24.ul.pwInfoListStrong;

        Check24.change("html", ".c24-uli-list-title-pw", poJM.pw_reset.secure_password_strong_title);

        lists.forEach(function(list) {
            list.innerHTML = "";
            secureList.forEach(function (el) {
                var listEle = document.createElement("li");
                listEle.innerHTML = el;
                list.appendChild(listEle);
            });
        });
    },
    updateTsFrame: function(data) {
        if (!data.hasOwnProperty('height') || data.height === 0) {
            return;
        }
        var tsIframe = Check24.document.querySelector('.c24-uli-turnstile iframe');
        tsIframe.style.height = data.height + 'px';
        tsIframe.style.width = data.width + 'px';
        Check24.ul.resetHeight();
    },
    ux: function(state, option) {
        Check24.hide([".c24-uli-footer", ".c24-uli-bottom-help", ".c24-uli-bottom-toggle", ".c24-uli-bottom-l-anonymous", ".c24-uli-bottom-l-anonymous-pwr", ".c24-uli-product-wrapper-email", ".c24-uli-product-wrapper-phone", ".c24-uli-mfa-wrapper"]);
        Check24.show([".c24-uli-bottom-ele", ".c24-uli-bottom-title-new"]);
        Check24.removeClass(".c24-uli-bottom-help", "c24-uli-nospace");
        Check24.removeClass(".c24-uli-bottom-l-anonymous", "c24-uli-nospace");
        Check24.ul.userName = null;
        if (Check24.ul.userLastName !== null) {
            if (Check24.ul.userGender !== null) {
                Check24.ul.userGender = (Check24.ul.userGender !== null) ?
                    (Check24.ul.userGender === "female" ? (poJM.layout.ux.gender.female + " ") : (poJM.layout.ux.gender.male + " ")) : "";
            }
            if (Check24.ul.userFirstName !== null) {
                Check24.ul.userName = Check24.ul.userFirstName + " " + Check24.ul.userLastName;
            } else {
                Check24.ul.userName = (Check24.ul.userGender !== null ? Check24.ul.userGender : "") + Check24.ul.userLastName;
            }
        }
        switch (state) {
            case "login":
                switch (option) {
                    case "nocookie":
                        Check24.hide([".c24-uli-login .c24-uli-form-ele-pw", ".c24-uli-login .c24-uli-cl-pwreset-trigger", ".c24-uli-user-wrapper", ".c24-uli-top-title", ".c24-uli-bottom-title", ".c24-uli-turnstile iframe"]);
                        Check24.show([".c24-uli-login .c24-uli-form-ele-login", ".c24-uli-form-ele-login .c24-uli-input-wrapper-login"]);
                        if (Check24.document.querySelector(".c24-uli-product-wrapper-email").innerHTML !== "") {
                            Check24.show(".c24-uli-product-wrapper-email");
                        } else {
                            Check24.hide(".c24-uli-product-wrapper-email");
                        }
                        Check24.removeClass(".c24-uli-login", "c24-uli-login-phone");
                        Check24.change("html", ".c24-uli-title", poJM.layout.ux.login.nocookie.title);
                        var deviceWidth = (window.innerWidth > 0) ? window.innerWidth : screen.width;
                        if (deviceWidth < 390) {
                            var input = Check24.document.querySelector("#cl_login");
                            input.dataset.placeholder = input.placeholder;
                            Check24.change("placeholder", "#cl_login", poJM.layout.ux.login.nocookie.email_address_short);
                        }

                        Check24.addClass(".c24-uli-login .c24-uli-back-init", "c24-uli-back-browser");
                        if (Check24.ul.userChange === true) {
                            Check24.ul.userChange = false;
                            Check24.hide([".c24-uli-login .c24-uli-back-init"]);
                        } else {
                            Check24.show([".c24-uli-login .c24-uli-back-init"]);
                        }
                        Check24.removeClass('.c24-uli-login', 'c24-uli-register-social');
                        if (configService.getSocialRegistration() === "true") {
                            Check24.addClass('.c24-uli-login', 'c24-uli-register-social');
                        }
                        Check24.ul.setText();
                        break;
                    default:
                        console.log("No ux option given");
                }
                break;
            case "loginOther":
                switch (option) {
                    case "email":
                        Check24.hide([".c24-uli-user-wrapper", ".c24-uli-login-other .c24-uli-input-wrapper-phone", ".c24-uli-top-title", ".c24-uli-bottom-l-phone", ".c24-uli-bottom-l-email", ".c24-uli-bottom-toggle"]);
                        Check24.show([".c24-uli-login-other", ".c24-uli-login-other .c24-uli-input-wrapper-email", ".c24-uli-user-wrapper"]);
                        if (configService.getShowAnonymous() === "true") {
                            Check24.show([".c24-uli-bottom-l-anonymous", ".c24-uli-bottom-toggle"]);
                            Check24.addClass(".c24-uli-bottom-l-anonymous", "c24-uli-nospace");
                        }
                        Check24.change("html", "#c24-uli-login-other-text", poJM.layout.ux.login_other.email.title);
                        Check24.change(
                            "html",
                            ".c24-uli-title",
                            Check24.ul.userName !== null ? poJM.layout.ux.login_other.email.salutation.replace("%s", Check24.ul.userName) : poJM.layout.ux.login_other.email.welcome
                        );
                        Check24.change("html", ".c24-uli-login-other .c24-uli-form-ele-login label", poJM.layout.ux.login_other.email.email_address);
                        Check24.hide(".c24-uli-bottom-email");
                        Check24.show([".c24-uli-footer", ".c24-uli-bottom-phone"]);
                        break;
                    case "phone":
                        Check24.hide([".c24-uli-user-wrapper", ".c24-uli-login-other .c24-uli-input-wrapper-email", ".c24-uli-top-title", ".c24-uli-bottom-l-phone", ".c24-uli-bottom-l-email", ".c24-uli-bottom-toggle"]);
                        Check24.show([".c24-uli-login-other", ".c24-uli-login-other .c24-uli-input-wrapper-phone", ".c24-uli-user-wrapper"]);
                        if (configService.getShowAnonymous() === "true") {
                            Check24.show([".c24-uli-bottom-l-anonymous", ".c24-uli-bottom-toggle"]);
                            Check24.addClass(".c24-uli-bottom-l-anonymous", "c24-uli-nospace");
                        }
                        Check24.change("html", "#c24-uli-login-other-text", poJM.layout.ux.login_other.phone.title);
                        Check24.change(
                            "html",
                            ".c24-uli-title",
                            Check24.ul.userName !== null ? poJM.layout.ux.login_other.phone.salutation.replace("%s", Check24.ul.userName) : poJM.layout.ux.login_other.phone.welcome
                        );
                        Check24.change("html", ".c24-uli-login-other .c24-uli-form-ele-login label", poJM.layout.ux.login_other.phone.mobile_number);
                        Check24.hide(".c24-uli-bottom-phone");
                        Check24.show([".c24-uli-footer", ".c24-uli-bottom-email"]);
                        break;
                    default:
                        console.log("No ux option given");
                }
                break;
            case "loginchallenge":
                switch (option) {
                    case "start":
                        Check24.change("html", ".c24-uli-title", poJM.layout.ux.login_challenge.verification);
                        Check24.hide([".c24-uli-lc-code", ".c24-uli-top-title", ".c24-uli-lc-birthday", ".c24-uli-lc-zipcode"]);
                        Check24.show([".c24-uli-lc-start", ".c24-uli-pwr-icon-email", ".c24-uli-pwr-icon-phone"]);

                        if (configService.getShowAnonymous() === "true") {
                            Check24.show([".c24-uli-bottom-l-anonymous-pwr", ".c24-uli-bottom-toggle"]);
                            if (Check24.ul.loginType === "phone") {
                                Check24.change("html", ".c24-uli-bottom-l-anonymous-pwr-text", poJM.layout.ux.login_challenge.your_phone);
                            } else {
                                Check24.change("html", ".c24-uli-bottom-l-anonymous-pwr-text", poJM.layout.ux.login_challenge.your_mails);
                            }
                        } else {
                            Check24.hide(".c24-uli-bottom-toggle");
                        }

                        if (Check24.ul.loginType === "phone" && !Check24.ul.registercheckTriggered) {
                            Check24.show([".c24-uli-footer", ".c24-uli-bottom-phone", ".c24-uli-bottom-r-samephone", ".c24-uli-pwr-icon-phone"]);
                        } else {
                            Check24.show([".c24-uli-footer", ".c24-uli-bottom-email", ".c24-uli-bottom-r-sameemail", ".c24-uli-pwr-icon-email"]);
                        }

                        if (Check24.ul.registercheckTriggered) {
                            Check24.change("html", ".c24-uli-lc-single-rcpt-text .c24-uli-user-data", Check24.ul.userData.email)
                        }
                        break;
                    case "submit":
                        Check24.change("html", ".c24-uli-title", poJM.layout.ux.submit.verification);
                        Check24.hide([".c24-uli-lc-start", ".c24-uli-top-title", ".c24-uli-lc-birthday", ".c24-uli-lc-zipcode"]);
                        Check24.show([".c24-uli-lc-code", ".c24-uli-pwr-icon-email", ".c24-uli-pwr-icon-phone"]);

                        if (configService.getShowAnonymous() === "true") {
                            Check24.show([".c24-uli-bottom-l-anonymous-pwr", ".c24-uli-bottom-toggle"]);
                            if (Check24.ul.loginType === "phone") {
                                Check24.change("html", ".c24-uli-bottom-l-anonymous-pwr-text", poJM.layout.ux.submit.your_phone);
                            } else {
                                Check24.change("html", ".c24-uli-bottom-l-anonymous-pwr-text", poJM.layout.ux.submit.your_mails);
                            }
                        } else {
                            Check24.hide(".c24-uli-bottom-toggle");
                        }

                        Check24.hide([".c24-uli-bottom-r-sameemail", ".c24-uli-bottom-r-samephone"]);
                        if (Check24.ul.loginChallengeData.type.indexOf("email") !== -1) {
                            Check24.hide(".c24-uli-info-text-phone");
                            Check24.show(".c24-uli-info-text-email");
                            Check24.change("html", "#c24-uli-cl_lc-label", poJM.tan.enter_code_email);
                        } else {
                            Check24.hide(".c24-uli-info-text-email");
                            Check24.show(".c24-uli-info-text-phone");
                            Check24.change("html", "#c24-uli-cl_lc-label", poJM.tan.enter_code_sms);
                        }
                        if (Check24.ul.loginType === "phone") {
                            Check24.hide([".c24-uli-bottom-email"]);
                            Check24.show([".c24-uli-footer", ".c24-uli-bottom-phone", ".c24-uli-bottom-r-email"]);
                        } else {
                            Check24.hide([".c24-uli-bottom-phone"]);
                            Check24.show([".c24-uli-footer", ".c24-uli-bottom-email", ".c24-uli-bottom-r-phone"]);
                        }
                        break;
                    case "zipcode":
                        Check24.change("html", ".c24-uli-title", poJM.layout.ux.submit.verification);
                        Check24.hide([".c24-uli-lc-start", ".c24-uli-lc-code", ".c24-uli-lc-birthday", ".c24-uli-top-title", ".c24-uli-pwr-icon-email", ".c24-uli-pwr-icon-phone"]);
                        Check24.show(".c24-uli-lc-zipcode");
                        if (Check24.ul.loginChallengeData.questions.birthday !== null) {
                            Check24.show(".c24-uli-lc-birthday-trigger");
                        } else {
                            Check24.hide(".c24-uli-lc-birthday-trigger");
                        }
                        break;
                    case "birthday":
                        Check24.change("html", ".c24-uli-title", poJM.layout.ux.submit.verification);
                        Check24.hide([".c24-uli-lc-start", ".c24-uli-lc-code", ".c24-uli-lc-zipcode", ".c24-uli-top-title", ".c24-uli-pwr-icon-email", ".c24-uli-pwr-icon-phone"]);
                        Check24.show(".c24-uli-lc-birthday");
                        if (Check24.ul.loginChallengeData.questions.zipcode !== null) {
                            Check24.show(".c24-uli-lc-zipcode-trigger");
                        } else {
                            Check24.hide(".c24-uli-lc-zipcode-trigger");
                        }
                        break;
                    default:
                        console.log("No ux option given");
                }
                break;
            case "social":
                if (Check24.ul.loginType === "phone") {
                    Check24.show([".c24-uli-bottom-toggle", ".c24-uli-bottom-l-email"]);
                    Check24.hide(".c24-uli-bottom-l-phone");
                } else {
                    Check24.show([".c24-uli-bottom-toggle", ".c24-uli-bottom-l-phone"]);
                    Check24.hide(".c24-uli-bottom-l-email");
                }
                switch (option) {
                    case "facebook":
                        Check24.hide([".c24-uli-social-form .c24-uli-social-google", ".c24-uli-top-title"]);
                        Check24.show([".c24-uli-social-form .c24-uli-social-facebook", ".c24-uli-login .c24-uli-form-ele-pw", ".c24-uli-user-wrapper", ".c24-uli-bottom-toggle"]);
                        if (configService.getShowAnonymous() === "true") {
                            Check24.show([".c24-uli-bottom-l-anonymous"]);
                        }
                        Check24.change(
                            "html",
                            ".c24-uli-title",
                            Check24.ul.userName !== null ? poJM.layout.ux.social.facebook.salutation.replace("%s", Check24.ul.userName) : poJM.layout.ux.social.facebook.welcome
                        );
                        if (Check24.ul.loginType === "phone") {
                            Check24.hide(".c24-uli-bottom-email");
                            Check24.show([".c24-uli-footer", ".c24-uli-bottom-phone"]);
                        } else {
                            Check24.hide(".c24-uli-bottom-phone");
                            Check24.show([".c24-uli-footer", ".c24-uli-bottom-email"]);
                        }
                        break;
                    case "google":
                        Check24.hide([".c24-uli-social-form .c24-uli-social-facebook"]);
                        Check24.show([".c24-uli-footer", ".c24-uli-social-form .c24-uli-social-google", ".c24-uli-login .c24-uli-form-ele-pw", ".c24-uli-user-wrapper", ".c24-uli-top-title", ".c24-uli-bottom-toggle"]);
                        if (configService.getShowAnonymous() === "true") {
                            Check24.show([".c24-uli-bottom-l-anonymous"]);
                        }
                        Check24.change(
                            "html",
                            ".c24-uli-title",
                            Check24.ul.userName !== null ? poJM.layout.ux.social.google.salutation.replace("%s", Check24.ul.userName) : poJM.layout.ux.social.google.welcome
                        );
                        if (Check24.ul.loginType === "phone") {
                            Check24.hide(".c24-uli-bottom-email");
                            Check24.show([".c24-uli-footer", ".c24-uli-bottom-phone"]);
                        } else {
                            Check24.hide(".c24-uli-bottom-phone");
                            Check24.show([".c24-uli-footer", ".c24-uli-bottom-email"]);
                        }
                        if ((configService.getPoints() === "checkbox" || configService.getPoints() === "radio") && Check24.ul.userPoints === "yes") {
                            if (Check24.ul.pointsXtreme.content !== "") {
                                Check24.change("html", ".c24-uli-top-title", Check24.ul.pointsXtreme.content);
                            } else {
                                Check24.change("html", ".c24-uli-top-title", poJM.layout.ux.social.google.collect_points);
                            }
                        }
                        break;
                    default:
                        console.log("No ux option given");
                }
                break;
            case "pw":
                switch (option) {
                    case "start":
                        Check24.change(
                            "html",
                            ".c24-uli-title",
                            Check24.ul.userName !== null ? poJM.layout.ux.pw.salutation.replace("%s", Check24.ul.userName) : poJM.layout.ux.pw.welcome
                        );
                        Check24.show(".c24-uli-user-wrapper");
                        Check24.hide([".c24-uli-login .c24-uli-form-ele-login"]);
                        Check24.show([".c24-uli-login .c24-uli-form-ele-pw", ".c24-uli-cl-pwreset-trigger", ".c24-uli-top-title", ".c24-uli-login .c24-uli-back", ".c24-uli-bottom-toggle"]);
                        if (configService.getShowAnonymous() === "true") {
                            Check24.show([".c24-uli-bottom-l-anonymous"]);
                        }
                        if (Check24.ul.loginType === "phone") {
                            Check24.hide([".c24-uli-bottom-email", ".c24-uli-bottom-l-phone"]);
                            Check24.show([".c24-uli-footer", ".c24-uli-bottom-phone", ".c24-uli-bottom-toggle", ".c24-uli-bottom-l-email"]);
                        } else {
                            Check24.hide([".c24-uli-bottom-phone", ".c24-uli-bottom-l-email"]);
                            Check24.show([".c24-uli-footer", ".c24-uli-bottom-email", ".c24-uli-bottom-toggle", ".c24-uli-bottom-l-phone"]);
                        }
                        if (Check24.ul.loginEmail === "other" || Check24.ul.loginPhone === "other") {
                            Check24.change("html", ".c24-uli-top-title", poJM.layout.ux.pw.start.login_other_accout);
                        } else {
                            if (Check24.ul.userLoginType !== null) {
                                Check24.change("html", ".c24-uli-top-title", poJM.layout.ux.pw.start.login_with_account);
                            } else {
                                Check24.change("html", ".c24-uli-top-title", poJM.layout.ux.pw.start.account_exists);
                            }
                            if (Check24.ul.userData.loginProvider.includes("google")) {
                                Check24.change("html", ".c24-uli-top-title", poJM.social.sign_in_with_account);
                            }
                        }
                        if ((configService.getPoints() === "checkbox" || configService.getPoints() === "radio") && Check24.ul.userPoints === "yes") {
                            if (Check24.ul.pointsXtreme.content !== "") {
                                Check24.change("html", ".c24-uli-top-title", Check24.ul.pointsXtreme.content);
                            } else {
                                Check24.change("html", ".c24-uli-top-title", poJM.layout.ux.pw.start.collect_points);
                            }
                        }
                        Check24.ul.pwInputDeleteEnabled = true;
                        if (Check24.ul.registercheckTriggered) {
                            Check24.change("html", ".c24-uli-top-title", poJM.layout.ux.pw.registercheck.text);
                            Check24.change("value", "#cl_login_ph", Check24.ul.userData.email);
                            Check24.change("html", ".c24-uli-user-data", Check24.ul.userData.phone);
                            Check24.change("html", ".c24-uli-top-title p .c24-uli-registercheck-email", Check24.ul.userData.email);
                            Check24.hide([".c24-uli-bottom-phone", ".c24-uli-bottom-l-email", ".c24-uli-bottom-r-phone", ".c24-uli-bottom-l-phone"]);
                            Check24.show([".c24-uli-footer", ".c24-uli-bottom-email", ".c24-uli-bottom-toggle"]);
                        }
                        if (configService.getForceLogin() === "true" && Check24.ul.prefilled === true) {
                            Check24.hide([".c24-uli-user-change", ".c24-uli-back-start", ".c24-uli-footer", ".c24-uli-bottom-toggle"]);
                        }
                        break;
                    default:
                        console.log("No ux option given");
                }
                break;
            case "register":
                if (Check24.ul.loginType === "phone") {
                    Check24.change("html", ".c24-uli-cl-l-trigger", poJM.layout.ux.register.with_email);
                } else {
                    Check24.change("html", ".c24-uli-cl-l-trigger", poJM.layout.ux.register.with_mobile_number);
                }
                if (configService.getShowAnonymous() === "false") {
                    Check24.addClass(".c24-uli-register", "c24-uli-noanonymous");
                }
                if ((configService.getPoints() === "checkbox" || configService.getPoints() === "radio") && Check24.ul.pointsXtreme.content !== "") {
                    Check24.change("html", ".c24-uli-register-points", Check24.ul.pointsXtreme.content);
                    Check24.show(".c24-uli-register-points");
                } else {
                    Check24.hide(".c24-uli-register-points");
                }
                if (configService.getContext() === "loginlayer" || configService.getContext() === "kb" || configService.getRegisterUsername() === "true") {
                    Check24.show([".c24-uli-form-ele-name"]);
                }
                Check24.change(
                    "html",
                    ".c24-uli-title",
                    Check24.ul.userName !== null ? poJM.layout.ux.register.email.salutation.replace("%s", Check24.ul.userName) : poJM.layout.ux.register.email.welcome
                );
                var configData = Check24.ul.configData.data;
                if (
                    configData.hasOwnProperty('title_register') &&
                    configData.title_register !== null &&
                    configData.title_register !== ''
                ) {
                    Check24.change("html", ".c24-uli-title", Check24.ul.configData.data["title_register"]);
                }
                switch (option) {
                    case "email":
                        Check24.hide([".c24-uli-bottom-help", ".c24-uli-register-form .c24-uli-form-ele-login", ".c24-uli-top-title", ".c24-uli-footer"]);
                        Check24.show([".c24-uli-login .c24-uli-cl-pwreset-trigger", ".c24-uli-login .c24-uli-form-ele-pw", ".c24-uli-user-wrapper"]);
                        break;
                    case "sameemail":
                        Check24.hide([".c24-uli-bottom-help", ".c24-uli-register-form .c24-uli-form-ele-login", ".c24-uli-top-title", ".c24-uli-footer"]);
                        Check24.show([".c24-uli-login .c24-uli-cl-pwreset-trigger", ".c24-uli-login .c24-uli-form-ele-pw", ".c24-uli-user-wrapper"]);
                        break;
                    case "phone":
                        Check24.hide([".c24-uli-bottom-help", ".c24-uli-register-form .c24-uli-form-ele-login", ".c24-uli-top-title", ".c24-uli-footer"]);
                        Check24.show([".c24-uli-login .c24-uli-cl-pwreset-trigger", ".c24-uli-login .c24-uli-form-ele-pw", ".c24-uli-user-wrapper"]);
                        if (Check24.ul.registercheckTriggered) {
                            Check24.hide([".c24-uli-bottom-toggle"]);
                        }
                        break;
                    case "superaccount":
                        Check24.hide([".c24-uli-bottom-help", ".c24-uli-register-form .c24-uli-input-wrapper-email", ".c24-uli-top-title", ".c24-uli-footer"]);
                        Check24.show([".c24-uli-login .c24-uli-cl-pwreset-trigger", ".c24-uli-login .c24-uli-input-wrapper-pw", ".c24-uli-user-wrapper"]);
                        break;
                }
                break;
            case "registercheck":
                switch (option) {
                    default:
                        Check24.show('.c24-uli-user-wrapper');
                        Check24.change("html", ".c24-uli-title", poJM.layout.ux.registercheck.title);
                }
                break;
            case "pwreset":
                Check24.hide(".c24-uli-top-title");
                switch (option) {
                    case "check":
                        break;
                    case "choose":
                        Check24.hide([".c24-uli-pwr-email", ".c24-uli-pwr-phone", ".c24-uli-pwr-mtan", ".c24-uli-pwr-etan", ".c24-uli-pwr-zipcode", ".c24-uli-pwr-pw", ".c24-uli-pwr-sq", ".c24-uli-pwr-birthday", ".c24-uli-bottom-phone", ".c24-uli-bottom-email", ".c24-uli-pwr-login"]);
                        Check24.show([".c24-uli-pwr-choose", ".c24-uli-user-wrapper"]);
                        Check24.change("html", ".c24-uli-title", poJM.layout.ux.pwreset.email.forgot_password);
                        Check24.change("html", ".c24-uli-login-wrapper", poJM.layout.ux.pwreset.email.label);
                        if (Check24.ul.loginType === "phone") {
                            Check24.show([".c24-uli-footer", ".c24-uli-bottom-phone"]);
                        } else {
                            Check24.show([".c24-uli-footer", ".c24-uli-bottom-email"]);
                        }
                        if (configService.getShowAnonymous() === "true") {
                            Check24.show(".c24-uli-bottom-l-anonymous-pwr");
                            if (Check24.ul.loginType === "phone") {
                                Check24.change("html", ".c24-uli-bottom-l-anonymous-pwr-text", poJM.layout.ux.pwreset.email.phone);
                            } else {
                                Check24.change("html", ".c24-uli-bottom-l-anonymous-pwr-text", poJM.layout.ux.pwreset.email.emails);
                            }
                        }
                        if (configService.getForceLogin() === "true" && Check24.ul.prefilled === true) {
                            Check24.hide([".c24-uli-user-change", ".c24-uli-back-start", ".c24-uli-footer", ".c24-uli-bottom-toggle"]);
                        }
                        break;
                    case "email":
                        Check24.hide([".c24-uli-pwr-phone", ".c24-uli-pwr-mtan", ".c24-uli-pwr-etan", ".c24-uli-pwr-zipcode", ".c24-uli-pwr-pw", ".c24-uli-pwr-emailsend", ".c24-uli-pwr-birthday", ".c24-uli-pwr-sq", ".c24-uli-pwr-choose", ".c24-uli-pwr-login"]);
                        Check24.show([".c24-uli-pwr-email", ".c24-uli-bottom-toggle", ".c24-uli-user-wrapper"]);
                        Check24.change("html", ".c24-uli-title", poJM.layout.ux.pwreset.email.forgot_password);
                        Check24.change("html", ".c24-uli-login-wrapper", poJM.layout.ux.pwreset.email.your_email_address);
                        Check24.hide(".c24-uli-bottom-phone");
                        Check24.show([".c24-uli-footer", ".c24-uli-bottom-email"]);
                        if (configService.getShowAnonymous() === "true") {
                            Check24.show([".c24-uli-bottom-l-anonymous"]);
                            Check24.addClass(".c24-uli-bottom-l-anonymous", "c24-uli-nospace");
                        } else {
                            Check24.hide(".c24-uli-bottom-toggle");
                        }
                        if (configService.getForceLogin() === "true" && Check24.ul.prefilled === true) {
                            Check24.hide([".c24-uli-user-change", ".c24-uli-back-start", ".c24-uli-footer", ".c24-uli-bottom-toggle"]);
                        }
                        break;
                    case "phone":
                        Check24.hide([".c24-uli-pwr-email", ".c24-uli-pwr-mtan", ".c24-uli-pwr-etan", ".c24-uli-pwr-zipcode", ".c24-uli-pwr-pw", ".c24-uli-pwr-sq", ".c24-uli-pwr-birthday", ".c24-uli-pwr-choose", ".c24-uli-pwr-login"]);
                        Check24.show([".c24-uli-pwr-phone", ".c24-uli-bottom-toggle", ".c24-uli-user-wrapper"]);
                        Check24.change("html", ".c24-uli-title", poJM.layout.ux.pwreset.phone.forgot_password);
                        Check24.hide(".c24-uli-bottom-email");
                        Check24.show([".c24-uli-footer", ".c24-uli-bottom-phone"]);
                        if (configService.getShowAnonymous() === "true") {
                            Check24.show([".c24-uli-bottom-l-anonymous"]);
                            Check24.addClass(".c24-uli-bottom-l-anonymous", "c24-uli-nospace");
                        } else {
                            Check24.hide(".c24-uli-bottom-toggle");
                        }
                        if (configService.getForceLogin() === "true" && Check24.ul.prefilled === true) {
                            Check24.hide([".c24-uli-user-change", ".c24-uli-back-start", ".c24-uli-footer", ".c24-uli-bottom-toggle"]);
                        }
                        break;
                    case "mcode":
                        Check24.hide([".c24-uli-pwr-email", ".c24-uli-pwr-phone", ".c24-uli-pwr-etan", ".c24-uli-pwr-zipcode", ".c24-uli-pwr-pw", ".c24-uli-pwr-sq", ".c24-uli-pwr-birthday", ".c24-uli-bottom-l-anonymous", ".c24-uli-pwr-choose", ".c24-uli-pwr-login"]);
                        Check24.show([".c24-uli-pwr-mtan", ".c24-uli-bottom-help"]);
                        if (configService.getShowAnonymous() === "true") {
                            Check24.show([".c24-uli-bottom-l-anonymous-pwr", ".c24-uli-bottom-toggle"]);
                        }
                        Check24.change("html", ".c24-uli-title", poJM.layout.ux.pwreset.mcode.verification);
                        if ((Check24.ul.loginType === "phone" || Check24.ul.pwResetData.method === "account_sms") && Check24.ul.pwResetData.method !== "account_email") {
                            Check24.change("html", ".c24-uli-login-wrapper", poJM.layout.ux.pwreset.mcode.mobile_number);
                            Check24.hide([".c24-uli-bottom-email", ".c24-uli-info-text-email", ".c24-uli-pwr-icon-email"]);
                            Check24.show([".c24-uli-footer", ".c24-uli-bottom-phone", ".c24-uli-info-text-phone", ".c24-uli-pwr-icon-phone"]);
                            Check24.change("html", ".c24-uli-bottom-l-anonymous-pwr-text", poJM.layout.ux.pwreset.mcode.mobile_device);
                            Check24.change("html", "#c24-uli-mtan-label", poJM.tan.enter_code_sms);
                        } else {
                            Check24.change("html", ".c24-uli-login-wrapper", poJM.layout.ux.pwreset.mcode.email_address);
                            Check24.hide([".c24-uli-bottom-phone", ".c24-uli-info-text-phone", ".c24-uli-pwr-icon-phone"]);
                            Check24.show([".c24-uli-footer", ".c24-uli-bottom-email", ".c24-uli-info-text-email", ".c24-uli-pwr-icon-email"]);
                            Check24.change("html", ".c24-uli-bottom-l-anonymous-pwr-text", poJM.layout.ux.pwreset.mcode.mails);
                            Check24.change("html", "#c24-uli-mtan-label", poJM.tan.enter_code_email);
                        }
                        Check24.hide([".c24-uli-bottom-r-sameemail", ".c24-uli-bottom-r-samephone"]);
                        if (configService.getForceLogin() === "true" && Check24.ul.prefilled === true) {
                            Check24.hide([".c24-uli-user-change", ".c24-uli-back-start", ".c24-uli-footer", ".c24-uli-bottom-toggle"]);
                        }
                        Check24.change("html", ".c24-uli-pwr-mtan .c24-uli-login-wrapper-type", Check24.ul.pwResetData.recipient);
                        break;
                    case "ecode":
                        Check24.hide([".c24-uli-pwr-email", ".c24-uli-pwr-mtan", ".c24-uli-pwr-phone", ".c24-uli-pwr-zipcode", ".c24-uli-pwr-pw", ".c24-uli-pwr-sq",".c24-uli-pwr-birthday", ".c24-uli-bottom-l-anonymous", ".c24-uli-pwr-choose", ".c24-uli-pwr-login"]);
                        Check24.show([".c24-uli-pwr-etan", ".c24-uli-bottom-help"]);
                        if (configService.getShowAnonymous() === "true") {
                            Check24.show([".c24-uli-bottom-l-anonymous-pwr", ".c24-uli-bottom-toggle"]);
                        }
                        Check24.change("html", ".c24-uli-title", poJM.layout.ux.pwreset.ecode.verification);
                        Check24.change("html", ".c24-uli-login-wrapper", sanitizer.html(Check24.ul.pwResetRecipient));
                        if (Check24.ul.pwResetData.method === "account_sms") {
                            Check24.hide([".c24-uli-bottom-email", ".c24-uli-info-text-phone", ".c24-uli-pwr-icon-phone"]);
                            Check24.show([".c24-uli-footer", ".c24-uli-bottom-phone", ".c24-uli-info-text-email", ".c24-uli-pwr-icon-email"]);
                            Check24.change("html", ".c24-uli-bottom-l-anonymous-pwr-text", poJM.layout.ux.pwreset.ecode.mails);
                            Check24.change("html", "#c24-uli-etan-label", poJM.tan.enter_code_email);
                        } else {
                            Check24.hide([".c24-uli-bottom-phone", ".c24-uli-info-text-email", ".c24-uli-pwr-icon-email"]);
                            Check24.show([".c24-uli-footer", ".c24-uli-bottom-email", ".c24-uli-info-text-phone", ".c24-uli-pwr-icon-phone"]);
                            Check24.change("html", ".c24-uli-bottom-l-anonymous-pwr-text", poJM.layout.ux.pwreset.ecode.mobile_device);
                            Check24.change("html", "#c24-uli-etan-label", poJM.tan.enter_code_sms);
                        }
                        Check24.show([".c24-uli-bottom-r-sameemail", ".c24-uli-bottom-r-samephone"]);
                        if (configService.getForceLogin() === "true" && Check24.ul.prefilled === true) {
                            Check24.hide([".c24-uli-user-change", ".c24-uli-back-start", ".c24-uli-footer", ".c24-uli-bottom-toggle"]);
                        }
                        break;
                    case "sq":
                        Check24.hide([".c24-uli-pwr-email", ".c24-uli-pwr-mtan", ".c24-uli-pwr-phone", ".c24-uli-pwr-zipcode", ".c24-uli-pwr-pw", ".c24-uli-pwr-etan",".c24-uli-pwr-birthday", ".c24-uli-pwr-choose", ".c24-uli-pwr-login"]);
                        Check24.show([".c24-uli-pwr-sq", ".c24-uli-bottom-help"]);
                        if (configService.getShowAnonymous() === "true") {
                            Check24.show([".c24-uli-bottom-l-anonymous", ".c24-uli-bottom-toggle"]);
                        }
                        Check24.change("html", ".c24-uli-title", poJM.layout.ux.pwreset.sq.verification);
                        if (Check24.ul.loginType === "phone") {
                            Check24.hide([".c24-uli-bottom-email", ".c24-uli-info-text-phone"]);
                            Check24.show([".c24-uli-footer", ".c24-uli-bottom-phone", ".c24-uli-info-text-email"]);
                        } else {
                            Check24.hide([".c24-uli-bottom-phone", ".c24-uli-info-text-email"]);
                            Check24.show([".c24-uli-footer", ".c24-uli-bottom-email", ".c24-uli-info-text-phone"]);
                        }
                        Check24.show([".c24-uli-bottom-r-sameemail", ".c24-uli-bottom-r-samephone"]);
                        if (configService.getForceLogin() === "true" && Check24.ul.prefilled === true) {
                            Check24.hide([".c24-uli-user-change", ".c24-uli-back-start", ".c24-uli-footer", ".c24-uli-bottom-toggle"]);
                        }
                        break;
                    case "zipcode":
                        Check24.hide([".c24-uli-pwr-email", ".c24-uli-pwr-mtan", ".c24-uli-pwr-etan", ".c24-uli-pwr-phone", ".c24-uli-pwr-pw", ".c24-uli-pwr-sq", ".c24-uli-pwr-birthday", ".c24-uli-pwr-choose", ".c24-uli-pwr-login"]);
                        Check24.show([".c24-uli-pwr-zipcode", ".c24-uli-bottom-help"]);
                        Check24.change("html", ".c24-uli-title", poJM.layout.ux.pwreset.zipcode.verification);
                        Check24.change("value", "#cl_zipcode", "");
                        var ele = Check24.document.querySelector("#cl_zipcode");
                        ele.classList.remove("active");
                        if (ele.parentElement.childNodes[0] &&
                            ele.parentElement.childNodes[0].classList &&
                            ele.parentElement.childNodes[0].classList.contains("c24-uli-input-inner-label")) {
                            ele.parentElement.removeChild(ele.parentElement.childNodes[0]);
                        }
                        if (Check24.ul.loginType === "email") {
                            Check24.hide([".c24-uli-bottom-email", ".c24-uli-bottom-phone"]);
                            Check24.show([".c24-uli-footer", ".c24-uli-bottom-email", ".c24-uli-bottom-toggle"]);
                            Check24.change("html", ".c24-uli-pwr-zipcode-text .c24-uli-login-wrapper", poJM.layout.ux.pwreset.zipcode.email_address);
                        } else {
                            Check24.hide([".c24-uli-bottom-email", ".c24-uli-bottom-phone"]);
                            Check24.show([".c24-uli-footer", ".c24-uli-bottom-phone", ".c24-uli-bottom-toggle"]);
                            Check24.change("html", ".c24-uli-pwr-zipcode-text .c24-uli-login-wrapper", poJM.layout.ux.pwreset.zipcode.mobile_number);
                        }
                        Check24.show([".c24-uli-bottom-r-sameemail", ".c24-uli-bottom-r-samephone"]);
                        if (configService.getShowAnonymous() === "true") {
                            Check24.show([".c24-uli-bottom-l-anonymous"]);
                            Check24.addClass(".c24-uli-bottom-l-anonymous", "c24-uli-nospace");
                        } else {
                            Check24.hide(".c24-uli-bottom-toggle");
                        }
                        if (configService.getForceLogin() === "true" && Check24.ul.prefilled === true) {
                            Check24.hide([".c24-uli-user-change", ".c24-uli-back-start", ".c24-uli-footer", ".c24-uli-bottom-toggle"]);
                        }
                        break;
                    case "birthday":
                        Check24.hide([".c24-uli-pwr-email", ".c24-uli-pwr-mtan", ".c24-uli-pwr-etan", ".c24-uli-pwr-phone", ".c24-uli-pwr-pw", ".c24-uli-pwr-sq", ".c24-uli-pwr-zipcode", ".c24-uli-pwr-choose", ".c24-uli-pwr-login"]);
                        Check24.show([".c24-uli-pwr-birthday", ".c24-uli-bottom-help"]);
                        Check24.change("html", ".c24-uli-title", poJM.layout.ux.pwreset.birthday.verification);
                        Check24.change("value", "#cl_birthday", "");
                        var ele = Check24.document.querySelector("#cl_birthday");
                        ele.classList.remove("active");
                        if (ele.parentElement.childNodes[0] &&
                            ele.parentElement.childNodes[0].classList &&
                            ele.parentElement.childNodes[0].classList.contains("c24-uli-input-inner-label")) {
                            ele.parentElement.removeChild(ele.parentElement.childNodes[0]);
                        }
                        if (Check24.ul.loginType === "email") {
                            Check24.hide([".c24-uli-bottom-email", ".c24-uli-bottom-phone"]);
                            Check24.show([".c24-uli-footer", ".c24-uli-bottom-email", ".c24-uli-bottom-toggle"]);
                            Check24.change("html", ".c24-uli-pwr-birthday-text .c24-uli-login-wrapper", poJM.layout.ux.pwreset.birthday.email_address);
                        } else {
                            Check24.hide([".c24-uli-bottom-email", ".c24-uli-bottom-phone"]);
                            Check24.show([".c24-uli-footer", ".c24-uli-bottom-phone", ".c24-uli-bottom-toggle"]);
                            Check24.change("html", ".c24-uli-pwr-birthday-text .c24-uli-login-wrapper", poJM.layout.ux.pwreset.birthday.mobile_number);
                        }
                        Check24.show([".c24-uli-bottom-r-sameemail", ".c24-uli-bottom-r-samephone"]);
                        if (configService.getShowAnonymous() === "true") {
                            Check24.show([".c24-uli-bottom-l-anonymous"]);
                            Check24.addClass(".c24-uli-bottom-l-anonymous", "c24-uli-nospace");
                        } else {
                            Check24.hide(".c24-uli-bottom-toggle");
                        }
                        if (configService.getForceLogin() === "true" && Check24.ul.prefilled === true) {
                            Check24.hide([".c24-uli-user-change", ".c24-uli-back-start", ".c24-uli-footer", ".c24-uli-bottom-toggle"]);
                        }
                        break;
                    case "pw":
                        Check24.hide([".c24-uli-pwr-email", ".c24-uli-pwr-mtan", ".c24-uli-pwr-etan", ".c24-uli-pwr-phone", ".c24-uli-pwr-zipcode", ".c24-uli-pwr-sq", ".c24-uli-pwr-birthday", ".c24-uli-footer", ".c24-uli-pwr-choose", ".c24-uli-pwr-login"]);
                        Check24.show(".c24-uli-pwr-pw");
                        Check24.change("html", ".c24-uli-title", poJM.layout.ux.pwreset.pw.create_new_password);
                        if (Check24.ul.loginType === "phone") {
                            Check24.change("html", ".c24-uli-login-type", poJM.layout.ux.pwreset.pw.mobile_number);
                        } else {
                            Check24.change("html", ".c24-uli-login-type", poJM.layout.ux.pwreset.pw.email_address);
                        }
                        if (configService.getForceLogin() === "true" && Check24.ul.prefilled === true) {
                            Check24.hide([".c24-uli-user-change", ".c24-uli-back-start", ".c24-uli-footer", ".c24-uli-bottom-toggle"]);
                        }
                        break;
                    case "login":
                        Check24.hide([".c24-uli-pwr-email", ".c24-uli-pwr-mtan", ".c24-uli-pwr-etan", ".c24-uli-pwr-phone", ".c24-uli-pwr-zipcode", ".c24-uli-pwr-sq", ".c24-uli-pwr-birthday", ".c24-uli-footer", ".c24-uli-pwr-choose", ".c24-uli-pwr-pw"]);
                        Check24.show(".c24-uli-pwr-login");
                        Check24.change("html", ".c24-uli-title", poJM.layout.ux.pwreset.login.title);
                        break;
                    default:
                        Check24.hide([".c24-uli-pwr-email", ".c24-uli-pwr-mtan", ".c24-uli-pwr-etan", ".c24-uli-pwr-phone", ".c24-uli-pwr-birthday", ".c24-uli-pwr-pw", ".c24-uli-pwr-sq", ".c24-uli-pwr-zipcode", ".c24-uli-pwr-choose", ".c24-uli-pwr-login"]);
                        Check24.show(".c24-uli-pwr-email");
                }
                break;
            case "renew":
                switch (option) {
                    case "start":
                        Check24.hide([".c24-uli-top-title", ".c24-uli-renew-form .c24-uli-renew-code", ".c24-uli-renew-form .c24-uli-renew-pw", ".c24-uli-bottom-title", ".c24-uli-bottom-email", ".c24-uli-bottom-phone", ".c24-uli-bottom-title-new"]);
                        Check24.show([".c24-uli-renew-form .c24-uli-renew-start", ".c24-uli-footer", ".c24-uli-bottom-help", ".c24-uli-bottom-toggle"]);
                        if (configService.getShowAnonymous() === "true") {
                            Check24.show([".c24-uli-bottom-l-anonymous"]);
                            Check24.addClass(".c24-uli-bottom-l-anonymous", "c24-uli-nospace");
                        } else {
                            Check24.hide(".c24-uli-bottom-toggle");
                            Check24.addClass(".c24-uli-bottom-help", "c24-uli-nospace");
                        }
                        Check24.change("html", ".c24-uli-title", "<div class=\"c24-uli-title-attention clearfix\">" +
                            "<div class=\"c24-uli-exclamation-mark\"></div>" +
                            "<div>" + poJM.layout.ux.renew.start.check_account +"</div>" +
                            "</div>");
                        break;
                    case "pw":
                        Check24.hide([".c24-uli-top-title", ".c24-uli-renew-form .c24-uli-renew-code", ".c24-uli-renew-form .c24-uli-renew-start", ".c24-uli-bottom-title", ".c24-uli-bottom-help"]);
                        Check24.show([".c24-uli-renew-form .c24-uli-renew-pw", ".c24-uli-user-wrapper", ".c24-uli-agb-wrapper"]);
                        Check24.change("html", ".c24-uli-title", poJM.layout.ux.renew.pw.new_password);
                        if (Check24.ul.loginType === "phone") {
                            Check24.change("html", ".c24-uli-login-type", poJM.layout.ux.renew.pw.mobile_number);
                        } else {
                            Check24.change("html", ".c24-uli-login-type", poJM.layout.ux.renew.pw.email_address);
                        }
                        if (configService.getContext() === "loginlayer" || configService.getContext() === "kb" || configService.getRegisterUsername() === "true") {
                            Check24.show([".c24-uli-form-ele-name"]);
                        }
                        break;
                }
                break;
            case "tan":
                switch (option) {
                    default:
                        Check24.hide([".c24-uli-top-title", ".c24-uli-footer", ".c24-uli-tan .c24-uli-button-sld", ".c24-uli-disable-mfa-checkbox", ".c24-uli-mfa-wrapper"]);
                        Check24.show([".c24-uli-tan-form", ".c24-uli-bottom-help", ".c24-uli-bottom-toggle"]);
                        if (configService.getShowAnonymous() === "true") {
                            Check24.show([".c24-uli-bottom-l-anonymous-pwr"]);
                        }
                        Check24.change("html", ".c24-uli-title", poJM.layout.ux.tan.verification);
                        if (Check24.ul.loginType === "phone" || Check24.ul.tanContext === "editphone") {
                            Check24.change("html", ".c24-uli-tan-form .c24-uli-login-wrapper", poJM.layout.ux.tan.mobile_number);
                            Check24.change("html", "#c24-uli-logintan-label", poJM.tan.enter_code_sms);
                            Check24.hide([".c24-uli-bottom-email", ".c24-uli-info-text-email", ".c24-uli-renew-text-email", ".c24-uli-pwr-icon-email"]);
                            Check24.show([".c24-uli-footer", ".c24-uli-bottom-phone", ".c24-uli-info-text-phone", ".c24-uli-pwr-icon-phone"]);
                            Check24.change("html", ".c24-uli-bottom-l-anonymous-pwr-text", poJM.layout.ux.tan.mobile_device);
                        } else {
                            Check24.change("html", ".c24-uli-tan-form .c24-uli-login-wrapper", poJM.layout.ux.tan.email_address);
                            Check24.change("html", "#c24-uli-logintan-label", poJM.tan.enter_code_email);
                            Check24.hide([".c24-uli-bottom-phone", ".c24-uli-info-text-phone", ".c24-uli-pwr-icon-phone"]);
                            Check24.show([".c24-uli-footer", ".c24-uli-bottom-email", ".c24-uli-info-text-email", ".c24-uli-renew-text-email", ".c24-uli-pwr-icon-email"]);
                            Check24.change("html", ".c24-uli-bottom-l-anonymous-pwr-text", poJM.layout.ux.tan.mails);
                        }
                        Check24.hide([".c24-uli-bottom-r-sameemail", ".c24-uli-bottom-r-samephone"]);
                        if (configService.getShowAnonymous() === "true") {
                        } else {
                            Check24.hide(".c24-uli-bottom-toggle");
                        }
                        if (Check24.ul.tanContext === "editphone") {
                            Check24.show(".c24-uli-tan .c24-uli-button-sld");
                            Check24.hide([".c24-uli-bottom", ".c24-uli-bottom-help", ".c24-uli-footer"]);
                            Check24.change("html", ".c24-uli-tan-text p", poJM.tan.security_code_sent_sld);
                            Check24.change("html", ".c24-uli-tan-text p .c24-uli-login-wrapper-type", Check24.ul.convertedPhone);
                            if (Check24.ul.registercheckTriggered) {
                                Check24.hide(".c24-uli-button-sld");
                                Check24.show(".c24-uli-user-change");
                            } else {
                                Check24.show(".c24-uli-button-sld");
                                Check24.hide(".c24-uli-user-change");
                            }
                        }
                        if (Check24.ul.tanContext === "user_login_mfa") {
                            Check24.change("html", ".c24-uli-title", poJM.layout.ux.tan.mfa_title);
                            Check24.hide([".c24-uli-pwr-icon-email", ".c24-uli-footer", ".c24-uli-info-text-email"]);
                            Check24.show([".c24-uli-disable-mfa-checkbox", ".c24-uli-pwr-icon-phone", ".c24-uli-info-text-phone"]);
                            Check24.change("html", ".c24-uli-tan-text p", poJM.tan.security_code_sent_sld);
                            Check24.change("html", ".c24-uli-tan-text p .c24-uli-login-wrapper-type", Check24.ul.mfaRecipent);
                            Check24.change("html", "#c24-uli-logintan-label", poJM.tan.enter_code_sms);
                            if (Check24.ul.mfaSqEnabled) {
                                Check24.show( ".c24-uli-mfa-wrapper");
                            }
                        }
                }
                break;
            case "loginprocess":
                switch (option) {
                    case "send":
                        Check24.hide([".c24-uli-loginprocess-tan", ".c24-uli-top-title"]);
                        Check24.show([".c24-uli-loginprocess-send"]);
                        Check24.change("html", ".c24-uli-title", poJM.layout.ux.loginprocess.title);
                        if (Check24.ul.userData.loginProviderOTP === "check24_tan_phone") {
                            Check24.change("html", ".c24-uli-title", poJM.layout.ux.loginprocess.titlesms)
                        }
                        var loginElement = Check24.document.getElementById('cl_login_ph');
                        Check24.change("html", ".c24-uli-loginprocess .c24-uli-login-wrapper-explicit", loginElement.value);
                        if (Check24.ul.loginType === "phone") {
                            Check24.hide([".c24-uli-bottom-email", ".c24-uli-info-text-email", ".c24-uli-pwr-icon-email"]);
                            Check24.show([".c24-uli-footer", ".c24-uli-bottom-phone", ".c24-uli-info-text-phone", ".c24-uli-pwr-icon-phone"]);
                        } else {
                            Check24.hide([".c24-uli-bottom-phone", ".c24-uli-info-text-phone", ".c24-uli-pwr-icon-phone"]);
                            Check24.show([".c24-uli-footer", ".c24-uli-bottom-email", ".c24-uli-info-text-email", ".c24-uli-pwr-icon-email"]);
                        }
                        break;
                    case "tan":
                        Check24.hide([".c24-uli-loginprocess-send", ".c24-uli-top-title"]);
                        Check24.show([".c24-uli-loginprocess-tan"]);
                        Check24.removeClass(".c24-uli-button-wrapper-tan", "c24-uli-button-wrapper-sld");
                        Check24.removeClass(".c24-uli-tan .c24-uli-back", "c24-uli-back-sld");
                        Check24.show([".c24-uli-bottom", ".c24-uli-footer"]);
                        Check24.hide([".c24-uli-top-title", ".c24-uli-tan .c24-uli-button-sld"]);
                        Check24.change("html", ".c24-uli-title", poJM.layout.ux.loginprocess.tantitle);
                        if (Check24.ul.loginType === "phone" || Check24.ul.tanContext === "editphone") {
                            Check24.change("html", ".c24-uli-lptan-form .c24-uli-login-wrapper", poJM.layout.ux.tan.phone.mobile_number);
                            Check24.change("html", "#c24-uli-lptan-label", poJM.tan.enter_code_sms);
                            Check24.hide([".c24-uli-bottom-email", ".c24-uli-info-text-email", ".c24-uli-pwr-icon-email"]);
                            Check24.show([".c24-uli-footer", ".c24-uli-bottom-phone", ".c24-uli-info-text-phone", ".c24-uli-pwr-icon-phone"]);
                            Check24.change("html", ".c24-uli-bottom-l-anonymous-pwr-text", poJM.layout.ux.tan.phone.mobile_device);
                        } else {
                            Check24.change("html", ".c24-uli-lptan-form .c24-uli-login-wrapper", poJM.layout.ux.tan.email.email_address);
                            Check24.change("html", "#c24-uli-lptan-label", poJM.tan.enter_code_email);
                            Check24.hide([".c24-uli-bottom-phone", ".c24-uli-info-text-phone", ".c24-uli-pwr-icon-phone"]);
                            Check24.show([".c24-uli-footer", ".c24-uli-bottom-email", ".c24-uli-info-text-email", ".c24-uli-pwr-icon-email"]);
                            Check24.change("html", ".c24-uli-bottom-l-anonymous-pwr-text", poJM.layout.ux.tan.email.emails);
                        }
                        Check24.hide([".c24-uli-bottom-r-sameemail", ".c24-uli-bottom-r-samephone"]);
                        break;
                }
                break;
            case "points":
                switch (option) {
                    default:
                        Check24.hide([".c24-uli-top-title", ".c24-uli-footer", ".c24-uli-user-wrapper", ".c24-uli-title-wrapper", ".c24-uli-header"]);
                        Check24.addClass(".c24-uli-points-title", "c24-uli-points-title-ns");
                        if (configService.getPoints() !== "checkbox" && configService.getPoints() !== "radio") {
                            break;
                        }
                        if (Check24.ul.pointsXtreme.title !== "") {
                            Check24.change("html", ".c24-uli-points-title", Check24.ul.pointsXtreme.title);
                            Check24.removeClass(".c24-uli-points-title", "c24-uli-points-title-ns");
                        } else {
                            Check24.change("html", ".c24-uli-points-title", poJM.layout.ux.points.collect_points);
                        }
                        if (Check24.ul.pointsXtreme.content !== "") {
                            Check24.change("html", ".c24-uli-points-cnt", Check24.ul.pointsXtreme.content);
                        } else {
                            Check24.change("html", ".c24-uli-points-cnt", "");
                        }
                }
                break;
            case "setlogindata":
                switch (option) {
                    default:
                        Check24.hide([".c24-uli-top-title", ".c24-uli-footer"]);
                        Check24.show([".c24-uli-tan-form", ".c24-uli-bottom-help", ".c24-uli-bottom-toggle"]);
                        Check24.change("html", ".c24-uli-title", poJM.sld.headline);
                        Check24.hide(['.c24-uli-bottom', 'c24-uli-bottom-help']);
                        Check24.addClass(".c24-uli-user-known", "inactive");
                        if (!Check24.ul.registercheckTriggered) {
                            Check24.hide('.c24-uli-user-change');
                        }
                }
                break;
            case "mfasq":
                switch (option) {
                    default:
                        Check24.hide([".c24-uli-top-title", ".c24-uli-footer"]);
                        Check24.show([".c24-uli-bottom-help", ".c24-uli-bottom-toggle"]);
                }
                break;
            default:
                Check24.hide([".c24-uli-input-wrapper-pw", ".c24-uli-cl-pwreset-trigger", ".c24-uli-user-wrapper", ".c24-uli-top-title", ".c24-uli-bottom-title"]);
                Check24.show(".c24-uli-input-wrapper-email");
                Check24.change("html", ".c24-uli-title", poJM.layout.ux.points.email_address_required);
        }
        if (configService.getContext() === "loginlayer" || configService.getContext() === "kb") {
            if (typeof Check24.ul.layout.postFunctions[configService.getContext()] === "function") {
                Check24.ul.layout.postFunctions[configService.getContext()](state, option);
            }
        }
    },
    postFunctions: {
        loginlayer: function (state, option) {
            switch (state) {
                case "login":
                    Check24.change("html", ".c24-uli-login-faq", poJM.set_text.login_faq);
                    switch (option) {
                        case "nocookie":
                            Check24.show([".c24-uli-footer", ".c24-uli-bottom-toggle"]);
                            Check24.hide([".c24-uli-bottom-phone", ".c24-uli-bottom-email", ".c24-uli-bottom-title-new", ".c24-uli-bottom-l-phone"]);
                            Check24.change("html", ".c24-uli-title", poJM.layout.post_functions.login_layer.nocookie.sign_in);
                            if (configService.getMode() === "register") {
                                Check24.change("html", ".c24-uli-title", poJM.layout.post_functions.login_layer.nocookie.sign_in_register);
                                Check24.show([".c24-uli-bottom-l-start"]);
                            } else {
                                Check24.show([".c24-uli-bottom-r-start"]);
                            }
                            break;
                    }
                    break;
                case "loginOther":
                    Check24.show([".c24-uli-bottom", ".c24-uli-footer"]);
                    break;
                case "social":
                    switch (option) {
                        case "facebook":
                            Check24.hide([".c24-uli-top-title"]);
                            Check24.show([".c24-uli-bottom", ".c24-uli-footer"]);
                            break;
                        case "google":
                            Check24.show([".c24-uli-bottom", ".c24-uli-footer"]);
                            break;
                    }
                    break;
                case "pw":
                    Check24.show([".c24-uli-bottom", ".c24-uli-footer"]);
                    break;
                case "register":
                    Check24.hide([".c24-uli-bottom", ".c24-uli-bottom-title-new", ".c24-uli-bottom-email", ".c24-uli-bottom-phone"]);
                    Check24.show([".c24-uli-footer"]);
                    break;
                case "pwreset":
                    Check24.show([".c24-uli-bottom", ".c24-uli-footer"]);
                    switch (option) {
                        case "pw":
                        case "login":
                            Check24.hide([".c24-uli-bottom", ".c24-uli-footer"]);
                            break;
                    }
                    break;
                case "tan":
                    Check24.show([".c24-uli-bottom", ".c24-uli-footer"]);
                    break;
            }
        },
        kb: function(state, option){
            switch (state) {
                case "login":
                    Check24.change("html", ".c24-uli-login-faq", poJM.set_text.login_faq);
                    switch (option) {
                        case "nocookie":
                            Check24.show([".c24-uli-footer", ".c24-uli-bottom-toggle", ".c24-uli-footer"]);
                            Check24.hide([".c24-uli-bottom-phone", ".c24-uli-bottom-email", ".c24-uli-bottom-title-new", ".c24-uli-bottom-l-phone"]);
                            Check24.change("html", ".c24-uli-title", poJM.layout.post_functions.login_layer.nocookie.sign_in);
                            if (configService.getMode() === "register") {
                                Check24.change("html", ".c24-uli-title", poJM.layout.post_functions.login_layer.nocookie.sign_in_register);
                                Check24.show([".c24-uli-bottom-l-start"]);
                            } else {
                                Check24.show([".c24-uli-bottom-r-start"]);
                            }
                            break;
                    }
                    break;
                case "loginOther":
                    Check24.show([".c24-uli-bottom", ".c24-uli-footer"]);
                    break;
                case "social":
                    switch (option) {
                        case "facebook":
                            Check24.hide([".c24-uli-top-title"]);
                            Check24.show([".c24-uli-bottom", ".c24-uli-footer"]);
                            break;
                        case "google":
                            Check24.show([".c24-uli-bottom", ".c24-uli-footer"]);
                            break;
                    }
                    break;
                case "pw":
                    Check24.show([".c24-uli-bottom", ".c24-uli-footer"]);
                    break;
                case "register":
                    Check24.hide([".c24-uli-bottom", ".c24-uli-bottom-title-new", ".c24-uli-bottom-email", ".c24-uli-bottom-phone"]);
                    Check24.show([".c24-uli-footer"]);
                    break;
                case "pwreset":
                    Check24.show([".c24-uli-bottom", ".c24-uli-footer"]);
                    switch (option) {
                        case "pw":
                        case "login":
                            Check24.hide([".c24-uli-bottom", ".c24-uli-footer"]);
                            break;
                    }
                    break;
                case "tan":
                    if (Check24.ul.tanContext !== "editphone") {
                        Check24.show([".c24-uli-bottom", ".c24-uli-footer"]);
                    }
                    break;
            }
        }
    }
}