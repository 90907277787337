export default {
    document: document,
    doctype: "default",
    domain: '',
    vars: [],
    delays: [],
    set: function set(name, value) {
        this.vars[name] = value;
    },
    get: function get(name) {
        return this.vars[name];
    },
    popup: function popup() {
        var w = arguments[0] ? arguments[0] : '580';
        var h = arguments[1] ? arguments[1] : '360';
        var u = arguments[2] ? arguments[2] : '';
        var popup = window.open(u, 'popup', 'width=' + w + ',height=' + h + ',scrollbars=yes, toolbar=no,status=no,resizable=yes,menubar=no,location=no,directories=no,top=10,left=10');
        if (popup) popup.focus();
    },
    getCookie: function getCookie(name) {
        var value = "; " + this.document.cookie;
        var parts = value.split("; " + name + "=");
        if (parts.length == 2) return parts.pop().split(";").shift();
    },
    isNumeric: function(n) {
        return !isNaN(parseFloat(n)) && isFinite(n);
    },
    htmlEntities: function htmlEntities(str) {
        return String(str).replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;');
    },
    validate: {
        email: function email(_email) {
            return _email.indexOf('@') !== -1;
        },
        emailStrict: function emailStrict(_email) {
            return _email.indexOf('@') !== -1;
        },
        phone: function phone(mobile) {
            var re = /[+]{0,1}[(]{0,1}[0-9]{1,5}[)]{0,1}[\/]{0,1}[-\s\./0-9]*/g;
            return re.test(mobile);
        },
        ecode: function ecode(_ecode) {
            var re = /^[a-zA-Z0-9_.-]*$/g;
            return re.test(_ecode);
        },
        mcode: function mcode(_mcode) {
            var re = /^[a-zA-Z0-9_.-]*$/g;
            return re.test(_mcode);
        },
        code: function code(_mcode) {
            var re = /^[A-Z0-9_.-]*$/g;
            return re.test(_mcode);
        },
        zipcode: function zipcode(_zipcode) {
            var re = /^[0-9]{5}$/g;
            return re.test(_zipcode);
        }
    },
    hasClass: function hasClass(element, className) {
        var el = this.document.querySelector(element);

        if (el) {
            if (el.classList) {
                return el.classList.contains(className);
            } else {
                return !!el.className.match(new RegExp('(\\s|^)' + className + '(\\s|$)'));
            }
        }
    },
    addClass: function addClass(element, className) {
        var ele = this.document.querySelectorAll(element);

        if (ele) {
            ele.forEach(function (el) {
                if (el.classList) {
                    el.classList.add(className);
                } else if (!this.hasClass(el, className)) {
                    el.className += " " + className;
                }
            });
        }
    },
    removeClass: function removeClass(element, className) {
        var ele = this.document.querySelectorAll(element);

        if (ele) {
            ele.forEach(function (el) {
                if (el.classList) {
                    el.classList.remove(className);
                } else if (this.hasClass(el, className)) {
                    var reg = new RegExp('(\\s|^)' + className + '(\\s|$)');
                    el.className = el.className.replace(reg, ' ');
                }
            });
        }
    },
    show: function show(element, mode) {
        var that = this;
        if (Array.isArray(element)) {
            element.forEach(function (elem) {
                var ele = that.document.querySelectorAll(elem);
                ele.forEach(function (el) {
                    if (el) {
                        if (mode) {
                            el.style.display = mode;
                        } else {
                            el.style.display = 'block';
                        }
                    }
                });
            });
        } else {
            var ele = this.document.querySelectorAll(element);
            ele.forEach(function (el) {
                if (mode) {
                    el.style.display = mode;
                } else {
                    el.style.display = 'block';
                }
            });
        }
    },
    hide: function hide(element) {
        var that = this;
        if (Array.isArray(element)) {
            element.forEach(function (elem) {
                var ele = that.document.querySelectorAll(elem);
                ele.forEach(function (el) {
                    if (el) {
                        el.style.display = 'none';
                    }
                });
            });
        } else {
            var ele = this.document.querySelectorAll(element);
            ele.forEach(function (el) {
                if (el) {
                    el.style.display = 'none';
                }
            });
        }
    },
    isObject: function isObject(obj) {
        return obj === Object(obj);
    },
    addEvent: function addEvent(ele, event, callback) {
        if (typeof callback !== "function") {
            console.log("addEvent: no callback function given");
            return false;
        }

        if (ele.indexOf(/\./)) {
            var className = this.document.querySelectorAll(ele);

            for (var i = 0; i < className.length; i++) {
                className[i].removeEventListener(event, callback, false);
                className[i].addEventListener(event, callback, false);
            }
        } else if (ele.indexOf("#")) {
            this.document.querySelector(ele).removeEventListener(event, callback, false);
            this.document.querySelector(ele).addEventListener(event, callback, false);
        } else {
            console.log("addEvent: no class or id given");
        }
    },
    removeEvent: function removeEvent(element, event, callback) {
        var el = this.document.querySelector(element);
        if (el && event && callback) {
            el.removeEventListener(event, callback, false);
        } else {
            console.log("removeEvent: missing parameters");
        }
    },
    getValue: function getValue(element) {
        var ele = this.document.querySelector(element);

        if (ele) {
            return ele.value;
        }
    },
    getValueType: function getValueType(value) {
        if (value.indexOf('@') !== -1) {
            return 'email';
        } else {
            return 'phone';
        }
    },
    change: function change(type, element, value, callback) {
        if (element.indexOf(/\./)) {
            var ele = this.document.querySelectorAll(element);
            ele.forEach(function (el) {
                if (el) {
                    if (type === "value") {
                        el.value = value;
                    } else if (type === "html") {
                        el.innerHTML = value;
                    } else if (type === "type") {
                        el.type = value;
                    } else if (type === "placeholder") {
                        el.placeholder = value;
                    } else if (type === "href") {
                        el.href = value;
                    } else {
                        console.log("change: missing parameters");
                    }
                }
            });
        } else {
            var ele = this.document.querySelector(element);
            if (type === "value") {
                ele.value = value;
            } else if (type === "html") {
                ele.innerHTML = value;
            } else if (type === "type") {
                ele.type = value;
            } else if (type === "placeholder") {
                ele.placeholder = value;
            } else if (type === "href") {
                ele.href = value;
            } else {
                console.log("change: missing parameters");
            }
        }

        if (typeof callback === "function") {
            callback();
        }
    },
    exists: function(element) {
        if (this.document.querySelector(element) !== null) {
            return this.document.contains(this.document.querySelector(element));
        } else {
            return false;
        }
    },
    closest: function closest(area, element) {
        return this.document.querySelector(area).closest(element);
    },
    find: function find(area, element) {
        // Final found elements
        var found_elements = []; // Find all the outer matched elements

        var outers = this.document.querySelectorAll(area);

        for (var i = 0; i < outers.length; i++) {
            var elements_in_outer = outers[i].querySelectorAll(element);
            elements_in_outer = Array.prototype.slice.call(elements_in_outer);
            found_elements = found_elements.concat(elements_in_outer);
        }

        return found_elements;
    },
    bdAc: function(type, event) {
        var bd = this.document.querySelector("#cl_birthday");
        var bdValue = this.getValue("#cl_birthday");
        if (isNaN(parseInt(event.key, 10))) {
            event.preventDefault();
            return false;
        }
        if (type === "keyup") {
            // Skip editing through backspace, delete, Ctrl+X and Ctrl+V
            if (event.keyCode == 8 || event.keyCode == 46 || (event.keyCode == 88 && event.ctrlKey) || (event.keyCode == 86 && event.ctrlKey)) {
                return false;
            }
            // No action on empty value
            if (bdValue == "") {
                return false;
            }
            // Initialize values
            var day = null;
            var month = null;
            var year = null;
            // No action on empty value
            if (bdValue == "") {
                return "";
            }
            if (event.key != ".") {
                bdValue = bdValue.replace(/\.$/, "");
            }
            // Split date into parts and correct month and day
            var parts = bdValue.split(/\./, 3);
            // Parse day
            day = parseInt(parts[0]);
            if (parts.length == 1 && parts[0].length == 1 && day > 3) {
                day = "0" + day;
            } else if ((parts[0].length > 1 && day < 10) || (parts.length > 1 && day > 0 && day < 10)) {
                day = "0" + day;
            } else if (parts.length == 1 && parts[0].length == 2 && day > 31) {
                var output = [],
                    sNumber = day.toString();
                for (var i = 0, len = sNumber.length; i < len; i += 1) {
                    output.push(+sNumber.charAt(i));
                }
                day = "0" + output[0];
                month = "0" + output[1];
            }
            day = "" + day;
            // Parse month
            if (parts.length > 1) {
                month = "";
                if (parts[1] != "") {
                    month = parseInt(parts[1]);
                    if (parts.length == 2 && ((parts[1].length == 1 && month > 1) || (parts[1].length > 1 && month < 10))) {
                        month = "0" + month;
                    } else if (parts.length == 2 && parts[1].length == 2 && month > 12) {
                        var output = [],
                            sNumber = month.toString();
                        for (var i = 0, len = sNumber.length; i < len; i += 1) {
                            output.push(+sNumber.charAt(i));
                        }
                        month = "0" + output[0];
                        year = output[1];
                    }
                    if (parts.length > 2) {
                        if (month > 0 && month < 10) {
                            month = "0" + month;
                        }
                    }
                    month = "" + month;
                }
            }
            // Parse year
            if (parts.length == 3) {
                year = "";
                if (parts[2] != "") {
                    year = parseInt(parts[2].replace(/\D/, ""));
                    year = ("" + year).padStart(parts[2].length, "0");
                }
            }
            // Compose value
            bdValue = day;
            if (month != null || day.length == 2) {
                bdValue += ".";
            }
            if (month != null && month != "") {
                bdValue += month;
                if (year != null || month.length == 2) {
                    bdValue += ".";
                }
                if (year != null && year != "") {
                    bdValue += year;
                }
            }
            // Return formatted date
            bd.value = bdValue;
        } else if (type === "blur") {
            // Let empty values
            if (bdValue == "") {
                return false;
            }
            // Apply regex (allows 1 digit day, 1 digit month and 2 digit year)
            var matches = bdValue.match(/^((0?[1-9]|[12]\d|3[01])\.(0?[1-9]|1[0-2])\.((19|20)?\d{1,2}))$/);
            if (matches == null) {
                // Wrong date format, do not edit value
                return false;
            }
            // Initialize date parts
            var day = ("" + parseInt(matches[2])).padStart(2, "0");
            var month = ("" + parseInt(matches[3])).padStart(2, "0");
            var year = parseInt(matches[4]);
            // Prepend century if we have a 2-digit year
            if (year < 100) {
                year += 1900;
                if (new Date().getFullYear() - year > 100) {
                    year += 100;
                }
            }
            // Return formatted string
            bdValue = day + "." + month + "." + year;
            // Return formatted date
            bd.value = bdValue;
        }
    },
    setStorageData: function(item, value) {
        try {
            localStorage.setItem(item, JSON.stringify(value));
        } catch (e) {
        }
    },
    removeTanSessionData: function() {
        var keys = ["tr_user_new", "tr_pw_reset", "tr_lc", "tr_pw_reset", "tr_user_deactivate", "tr_user_edit_login-tan", "tr_user_login_mfa-tan"],
            key;
        for (key of keys) {
            try {
                localStorage.removeItem(key);
            } catch (e) {
            }
        }
    },
    getStorageData: function(item) {
        try {
            if (typeof localStorage[item] !== 'undefined') {
                return JSON.parse(localStorage[item]);
            } else {
                return null;
            }
        } catch (e) {
            return null;
        }
    },
    setTanSessionData: function(context) {
        var tanRequested = this.getStorageData('tr_' + context),
            tanTimestamp = null,
            nowTimestamp = Date.now(),
            tanCount = 0,
            returnVal = true;

        if (tanRequested !== null) {
            tanTimestamp = tanRequested.t;
            tanCount = tanRequested.c;
        }
        // reset counter if last timestamp is older than 3h // =10800000ms
        if (nowTimestamp - tanTimestamp > 10800000) {
            tanTimestamp = nowTimestamp;
            tanCount = 0;
        }
        // check if tan limit reached
        if (tanCount >= 5) {
            // reset timestamp if last tan was sent over 10min ago // =600000ms
            if (nowTimestamp - tanTimestamp > 600000) {
                tanTimestamp = nowTimestamp;
            } else {
                // tan was requested too often and not over 10min ago
                returnVal = false;
            }
        } else {
            tanTimestamp = nowTimestamp;
        }
        this.setStorageData(
            'tr_' + context,
            {
                c: tanCount + 1,
                t: tanTimestamp
            }
        );
        return returnVal;
    },
    checkSldVisible: function() {
        var sldLater = this.getStorageData('ul_sld'),
            currentTime = Date.now();

        return sldLater === null || currentTime - sldLater > 2592000000;
    }
}