import {configService} from "../services/configService";
import Check24 from "../unifiedlogin_desktop";

export default function(state, option) {
    if (!Check24.ul.initialized) {
        Check24.ul.initialized = true;
        Check24.ul.layout.setWidth();
        if (configService.getPoints() === "radio") {
            Check24.show(".c24-uli-points .c24-uli-points-radio");
            Check24.hide(".c24-uli-points .c24-uli-points-checkbox");
        } else if (configService.getPoints() === "checkbox") {
            Check24.show(".c24-uli-points .c24-uli-points-checkbox");
            Check24.hide(".c24-uli-points .c24-uli-points-radio");
        } else {
            Check24.hide(".c24-uli-points .c24-uli-points-wrapper");
        }
        if (configService.getBackground() === "grey") {
            Check24.addClass(".c24-uli-body", "c24-uli-grey");
        }
        if (configService.getBorder() === "none") {
            Check24.addClass(".c24-uli-wrapper", "c24-uli-noborder");
        }
        if (configService.getPwMode() === "strong") {
            Check24.ul.layout.setPwInfo();
        }

        Check24.ul.trigger();
        Check24.ul.initListener();
        Check24.ul.setText();
        Check24.ul.setContext();
        Check24.ul.userPrefill();
        Check24.getClientHints();
        Check24.ul.layout.fillHtml();

        if (Check24.ul.prefilled && !Check24.ul.forcePoints) {
            return false;
        }
    }

    if (configService.getEnv() === "shadowDom" && configService.getUliReset() !== null && configService.getUliReset() > 0 && !Check24.ul.uliReseted) {
        Check24.ul.uliReseted = true;
        Check24.ul.userState = null;
        Check24.ul.prefilled = false;
        Check24.ul.userPrefill();
    }

    Check24.hide([".c24-uli-bottom-toggle", ".c24-uli-bottom-title"]);

    var s = state ? state : "login";
    Check24.ul.state = s;
    var o = option !== "" ? option : "nocookie";
    Check24.ul.stateOption = o;
    Check24.ul.viewChangeMsg(s, o);
    Check24.ul.view = "";
    if (s === "login" && o === "nocookie") {
        Check24.ul.view = s;
    } else {
        Check24.ul.view = "";
    }

    switch (s) {
        case "login":
            Check24.ul.login(o);
            break;
        case "loginOther":
            Check24.ul.loginOther(o);
            break;
        case "loginchallenge":
            Check24.ul.loginChallenge(o);
            break;
        case "social":
            Check24.ul.social(o);
            break;
        case "pw":
            Check24.ul.pw(o);
            break;
        case "register":
            Check24.ul.register(o);
            break;
        case "registercheck":
            Check24.ul.registercheck(o);
            break;
        case "pwreset":
            Check24.ul.pwReset(o);
            break;
        case "renew":
            Check24.ul.renew(o);
            break;
        case "tan":
            Check24.ul.tan(o);
            break;
        case "setlogindata":
            Check24.ul.setLoginData(o);
            break;
        case "loginprocess":
            Check24.ul.loginProcess(o);
            break;
        case "points":
            Check24.ul.pointsAction(o);
            break;
        case "mfasq":
            Check24.ul.mfasq(o);
            break;
        default:
            console.log("No valid state given");
            Check24.ul.login(o);
    }
    Check24.ul.layout.change(s, o);
    Check24.hide(".c24-uli-loading-screen");
    if (o === "nocookie") {
        var infoDim = Check24.document.querySelector(".c24-uli-form-ele-login").querySelector(".c24-uli-info-text").getBoundingClientRect();
        var ulDim = Check24.document.querySelector(".c24-uli-wrapper").getBoundingClientRect();
        var infoPos = infoDim.height + infoDim.top;
        var ulPos = ulDim.height + ulDim.top;
        var ulH = (infoDim.height + (infoDim.top - ulDim.top) + 20) + "px";
        if (infoPos > ulPos) {
            Check24.document.querySelector(".c24-uli-wrapper").style.minHeight = ulH;
        }
    }
    Check24.ul.resetHeight();
}