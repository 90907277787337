class CsrfTokenService {

    constructor() {
        this.token = null;
        this.identifier = null;
    }

    setIdentifier(identifier) {
        this.identifier = identifier;
    }

    getIdentifier() {
        return this.identifier;
    }

    setToken(token) {
        this.token = token;
    }

    getToken() {
        return this.token;
    }

    parseXhrResponse(json) {
        try {
            let data = JSON.parse(json);
            if (data.csrfToken) {
                this.setToken(data.csrfToken);
            }
            if (data.csrfIdentifier) {
                this.setIdentifier(data.csrfIdentifier);
            }
        } catch (e) {
        }
    }

}

export const csrfTokenService = new CsrfTokenService();