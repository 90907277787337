import header from '!!html-loader!translate-loader!./templates/partials/desktop/header.html';
import login from '!!html-loader!translate-loader!./templates/partials/desktop/login.html';
import pw from '!!html-loader!translate-loader!./templates/partials/desktop/pw.html';
import login_other from '!!html-loader!translate-loader!./templates/partials/desktop/login_other.html';
import login_challenge from '!!html-loader!translate-loader!./templates/partials/desktop/login_challenge.html';
import social from '!!html-loader!translate-loader!./templates/partials/desktop/social.html';
import register from '!!html-loader!translate-loader!./templates/partials/desktop/register.html';
import registercheck from '!!html-loader!translate-loader!./templates/partials/desktop/registercheck.html';
import tan from '!!html-loader!translate-loader!./templates/partials/desktop/tan.html';
import pw_reset from '!!html-loader!translate-loader!./templates/partials/desktop/pw_reset.html';
import renew from '!!html-loader!translate-loader!./templates/partials/desktop/renew.html';
import set_login_data from '!!html-loader!translate-loader!./templates/partials/desktop/set_login_data.html';
import mfasq from '!!html-loader!translate-loader!./templates/partials/desktop/mfa_sq.html';
import login_process from '!!html-loader!translate-loader!./templates/partials/desktop/login_process.html';
import points from '!!html-loader!translate-loader!./templates/partials/desktop/points.html';
import footer from '!!html-loader!translate-loader!./templates/partials/desktop/footer.html';
import footer_links from '!!html-loader!translate-loader!./templates/partials/desktop/footer_links.html';
import footer_info from '!!html-loader!translate-loader!./templates/partials/desktop/footer_info.html';
import loading from '!!html-loader!translate-loader!./templates/partials/desktop/loading.html';

import css from '!!html-loader!./style_desktop.css';
import Check24 from "./unifiedlogin/unifiedlogin_desktop";
import {initAttributeParams} from './initAttributeParams';

if (window.customElements) {
    window.customElements.forcePolyfill = true;
}
window.ShadyDOM = { force: true };
window.Check24Uli = Check24;

class unifiedLogin extends HTMLElement {
    constructor() {
        super();

        const template_string = `
        <style type="text/css">
        ${css}
        </style>
        <div class="c24-uli-body">
            <div class="c24-uli-wrapper">
                ${loading}
                <div class="c24-uli">
                    ${header}
                    <form class="c24-uli-form c24-uli-login" method="post" action="/" novalidate autocomplete="on">
                        ${login}
                        ${pw}
                    </form>
                    ${login_other}
                    ${login_challenge}
                    ${social}
                    ${register}
                    ${registercheck}
                    ${tan}
                    ${pw_reset}
                    ${renew}
                    ${set_login_data}
                    ${mfasq}
                    ${login_process}
                    ${points}
                    ${footer}
                    ${footer_links}
                </div>
                ${footer_info}
            </div>
        </div>
        `;

        const template = document.createElement('template');
        template.innerHTML = template_string;

        const shadowRoot = this.attachShadow({mode: 'open'})
            .appendChild(template.content.cloneNode(true));
    }

    connectedCallback() {
        console.log("ULI connected");

        Check24.document = this.shadowRoot;
        initAttributeParams(Check24, this, "desktop");

        let connectEvent = new Event('uli-connected');
        window.dispatchEvent(connectEvent);
    }

    disconnectedCallback() {
        console.log("ULI disconnected");

        let disconnectEvent = new Event('uli-disconnected');
        window.dispatchEvent(disconnectEvent);

        Check24.ul.initialized = false;
    }

    static get observedAttributes() {
        return ['uli_reset'];
    }

    attributeChangedCallback(name, oldValue, newValue) {
        console.log("ULI attribute changed");

        Check24.document = this.shadowRoot;
        initAttributeParams(Check24, this, "desktop");

        let attributeEvent = new Event('uli-attribute-changed');
        window.dispatchEvent(attributeEvent);
    }
}
customElements.get('unified-login') || customElements.define('unified-login', unifiedLogin);
