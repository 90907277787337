import {poJD} from "../po/po.js_desktop";
import Check24 from "../unifiedlogin_desktop";
import {configService} from "../services/configService";

export default {
    msg: {
        validate: {
            email: poJD.error.msg.validate.email,
            phone: poJD.error.msg.validate.phone,
            phoneUsed: poJD.error.msg.validate.mobile_used,
            email_or_phone: poJD.error.msg.validate.email_or_phone,
            blacklistedEmail: poJD.error.msg.validate.blacklisted_email
        },
        noValue: {
            email: poJD.error.msg.noValue.email,
            phone: poJD.error.msg.noValue.phone,
            emailOrPhone: poJD.error.msg.noValue.email_or_phone,
            pw: poJD.error.msg.noValue.pw,
            code: poJD.error.msg.noValue.code,
            sq: poJD.error.msg.noValue.sq,
            firstname: poJD.error.msg.noValue.firstname,
            lastname: poJD.error.msg.noValue.lastname
        },
        login: {
            last: poJD.error.msg.login.last,
            blocked: poJD.error.msg.login.blocked,
            fraud: poJD.error.msg.login.fraud,
            fail: poJD.error.msg.login.fail,
            failPhone: poJD.error.msg.login.fail_phone,
            failCode: poJD.error.msg.login.fail_code
        },
        pwReset: {
            noCode: poJD.error.msg.pw_reset.no_code,
            wrongCode: poJD.error.msg.pw_reset.wrong_code,
            noZipcode: poJD.error.msg.pw_reset.no_zip,
            falseZipcode: poJD.error.msg.pw_reset.false_zip,
            wrongZipcode: poJD.error.msg.pw_reset.wrong_zip,
            noPw: poJD.error.msg.pw_reset.no_pw,
            shortPw: poJD.error.msg.pw_reset.short_pw,
            noBirthday: poJD.error.msg.pw_reset.no_birthday,
            falseBirthday: poJD.error.msg.pw_wreset.false_birthday,
            wrongBirthday: poJD.error.msg.pw_reset.wrong_birthday,
            wrongAnswer: poJD.error.msg.pw_reset.wrong_answer,
        },
        loginChallenge: {
            noZipcode: poJD.error.msg.login_challenge.no_zip,
            falseZipcode: poJD.error.msg.login_challenge.false_zip,
            wrongZipcode: poJD.error.msg.login_challenge.wrong_zip,
            noBirthday: poJD.error.msg.login_challenge.no_birthday,
            falseBirthday: poJD.error.msg.login_challenge.false_birthday,
            wrongBirthday: poJD.error.msg.login_challenge.wrong_birthday,
        },
        register: {
            shortPw: poJD.error.msg.register.short_pw
        },
        pw: {
            length: poJD.error.msg.pw.length,
            repeat: poJD.error.msg.pw.repeat,
            repeatEmpty: poJD.error.msg.pw.repeat_empty,
            shortPw: poJD.error.msg.pw.short_pw,
            other: poJD.error.msg.pw.other,
            strong: poJD.error.msg.pw.strong
        },
        sq: {
            lastChance: poJD.error.msg.sq.last_chance,
            blocked: poJD.error.msg.sq.blocked
        }
    },
    set: function(inputEle, errorEle, msg) {
        Check24.ul.layout.removeSpinner();
        if (inputEle !== "") {
            var input = Check24.document.querySelector(inputEle);
            if (input) {
                Check24.addClass(inputEle, "c24-uli-input-error");
                if (
                    input.parentElement.classList &&
                    (configService.getContext() === "loginlayer" || configService.getContext() === "kb" || configService.getContext() === "as")
                ) {
                    input.parentElement.classList.add("c24-uli-input-error");
                }
            }
        }
        if (errorEle && msg) {
            Check24.change("html", errorEle, msg);
            Check24.show(errorEle);
        }
        Check24.ul.resetHeight();
    },
    unset: function(inputEle, errorEle) {
        if (inputEle && errorEle) {
            Check24.removeClass(inputEle, "c24-uli-input-error");
            Check24.removeClass(".c24-uli-input-ele", "c24-uli-input-error");
            Check24.hide(errorEle);
        } else {
            Check24.removeClass("input", "c24-uli-input-error");
            Check24.removeClass(".c24-uli-input-ele", "c24-uli-input-error");
            Check24.hide(".c24-uli-error");
            Check24.change("html", ".c24-uli-cl-pw-strength-value", "");
            var elements = Check24.document.querySelectorAll(".c24-uli-cl-pw-strength-status");
            for (var i = 0; i < elements.length; i++) {
                elements[i].style.width = "0";
            }
        }
        Check24.hide(".c24-uli-points-error");
        Check24.removeClass(".c24-uli-points-radio", "c24-uli-error-points");
        Check24.ul.resetHeight();
    }
}