import {configService} from "./services/configService";

export default function() {
    try {
        var url = configService.getBasePathTest() !== "" ? configService.getBasePathTest() : configService.getBasePath();
        var mtmId = 'kuW906du';
        if (url.includes('check24-int.de')) {
            mtmId = 'EfYTJUN1';
        } else if (url.includes('check24-test.de')) {
            mtmId = '6LsgX4zh';
        }

        var _mtm = window._mtm = window._mtm || [];
        _mtm.push({'mtm.startTime': (new Date().getTime()), 'event': 'mtm.Start'});
        var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
        g.async=true; g.src='https://uxlog.check24.de/js/container_' + mtmId + '.js'; s.parentNode.insertBefore(g,s);
    } catch (e) {
        console.log('tracking failed', e);
    }
};