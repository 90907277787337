import {poJP} from "../po/po.js_password_strength";
import {configService} from "../services/configService";

var Check24 = Check24 || {};

!function () {
    "use strict";
    Check24.PasswordStrength = {
        dictionary: ["ficken", "passwort", "schatz", "sommer", "frankfurt", "daniel", "nadine", "password", "iloveyou", "check24", "admin", "letmein", "monkey", "shadow", "sunshine", "princess", "trustno1", "testplan", "abc123", "test"],
        secondCure: null,
        indicatorTextElement: null,
        indicatorBarElement: null,
        emailElement: null,
        passwordElement: null,
        observer: [],
        numeric: 0,
        numericOnly: 0,
        consecutivNumeric: 0,
        init: function (a, b, c, d) {
            var e = new MinimumLength(8, 0), f = new Length(4), g = new Uppercase(2), h = new Lowercase(2),
                i = new Numeric(4), j = new Symbolic(6), k = new NumericSymbolBetween(2), l = [g, h, i, j],
                m = new Requirements(l, 2, 75), n = [m, e], o = new Requirements(n, 2, 100),
                p = new Dictionary(this.dictionary, 2), q = new AlphabeticOnly(1), r = new NumericOnly(1),
                s = new ConsecutivelyUppercase(2), t = new ConsecutivelyLowercase(2), u = new ConsecutivelyNumeric(2),
                v = new ConsecutivelySymbolic(2), w = new RepeatingUppercase(2), x = new RepeatingLowercase(2),
                y = new RepeatingNumeric(2), z = new RepeatingSymbolic(2), A = new Sequential(3),
                B = new AdjacentCharacters(2), C = [f, g, h, i, j, k, o, p, q, r, s, t, u, v, w, x, y, z, A, p, B];
            this.secondCure = new SecondCure(C), this.indicatorTextElement = a, this.indicatorBarElement = b, this.emailElement = c, this.passwordElement = d, this.observer = C, this.numeric = i, this.numericOnly = r, this.consecutivNumeric = u, d.addEventListener("input", this.onPasswordInput.bind(this)), this.onPasswordInput()
        },
        onPasswordInput: function () {
            var a = this.passwordElement.value,
                b = this.secondCure.validate(a),
                c = this.indicatorBarElement,
                d = this.indicatorBarElement.parentNode,
                e = this.indicatorTextElement,
                f = this.emailElement,
                g = this.numeric.score(a),
                h = this.numericOnly.score(a),
                i = this.consecutivNumeric.score(a);
            var msgShort = configService.getPwMode() === "strong" ? poJP.short_pwstrong : poJP.short;
            var msgWeak = configService.getPwMode() === "strong" ? poJP.weak_pwstrong : poJP.weak;
            var msgSufficient = configService.getPwMode() === "strong" ? poJP.sufficient_pwstrong : poJP.sufficient;
            var msgStrong = configService.getPwMode() === "strong" ? poJP.strong_pwstrong : poJP.strong;
            var msgVeryStrong = configService.getPwMode() === "strong" ? poJP.veryStrong_pwstrong : poJP.veryStrong;

            0 === h && 0 === i || (b -= g), a.length < 8 ?
                (e.innerHTML = msgShort, c.style.backgroundColor = "red", c.style.width = "0") :
            b < 30 || f.value && -1 !== a.indexOf(f.value) ?
                (e.innerHTML = msgWeak, c.style.backgroundColor = "red", c.style.width = d.offsetWidth / 4 + "px") :
            b >= 30 && b < 46 ?
                (e.innerHTML = msgSufficient, c.style.backgroundColor = "orange", c.style.width = d.offsetWidth / 4 * 2 + "px") :
            (b >= 46 && b < 90) || (b >= 90 && !this.passwordCheck.checkAll(this.passwordElement.value, this.emailElement.value)) ?
                (e.innerHTML = msgStrong, c.style.backgroundColor = "#0077b3", c.style.width = d.offsetWidth / 4 * 3 + "px") :
            b >= 90 && this.passwordCheck.checkAll(this.passwordElement.value, this.emailElement.value) ?
                (e.innerHTML = msgVeryStrong, c.style.backgroundColor = "green", c.style.width = d.offsetWidth / 4 * 4 + "px") :
            (e.innerHTML = msgWeak, c.style.backgroundColor = "red", c.style.width = d.offsetWidth / 4 + "px")
        },
        passwordCheck: {
            checkAll: function(pwString, email) {
                if (configService.getPwMode() === "strong") {
                    if (
                        !Check24.PasswordStrength.passwordCheck.checkLower(pwString) ||
                        !Check24.PasswordStrength.passwordCheck.checkUpper(pwString) ||
                        !Check24.PasswordStrength.passwordCheck.checkSpecial(pwString)
                    ) {
                        return false;
                    }
                    if (email && !Check24.PasswordStrength.passwordCheck.checkSimilarity(pwString, email)) {
                        return false;
                    }
                    return true;
                } else {
                    return true;
                }
            },
            checkLower: function(pwString) {
                var i = 0;
                var character = '';
                var sc = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~1234567890]/;
                while (i <= pwString.length){
                    character = pwString.charAt(i);
                    if (!isNaN(character * 1)) {
                        // character is nummeric
                    } else {
                        if (character == character.toLowerCase() && !sc.test(character)) {
                            return true;
                        }
                    }
                    i++;
                }
                return false;
            },
            checkUpper: function(pwString) {
                var i = 0;
                var character = '';
                var sc = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~1234567890]/;
                while (i <= pwString.length){
                    character = pwString.charAt(i);
                    if (!isNaN(character * 1)) {
                        // character is nummeric
                    } else {
                        if (character == character.toUpperCase() && !sc.test(character)) {
                            return true;
                        }
                    }
                    i++;
                }
                return false;
            },
            checkSpecial: function(pwString) {
                var i = 0;
                var character = '';
                var format = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~1234567890]/;
                while (i <= pwString.length){
                    character = pwString.charAt(i);
                    if (format.test(pwString)) {
                        return true;
                    }
                    i++;
                }
                return false;
            },
            checkSimilarity: function(pwString, email) {
                var emailName = email.split('@')[0];
                const firstSanitized = pwString.replace(/\s+/g, '');
                const secondSanitized = emailName.replace(/\s+/g, '');
                if (!firstSanitized.length && !secondSanitized.length) return false;
                if (!firstSanitized.length || !secondSanitized.length) return true;
                if (firstSanitized === secondSanitized) return false;
                if (firstSanitized.length === 1 && secondSanitized.length === 1) return true;
                if (firstSanitized.length < 2 || secondSanitized.length < 2) return true;
                const firstBigrams = new Map();
                for (let i = 0; i < firstSanitized.length - 1; i += 1) {
                    const bigram = firstSanitized.substr(i, 2);
                    const count = firstBigrams.has(bigram) ? firstBigrams.get(bigram) + 1 : 1;
                    firstBigrams.set(bigram, count);
                }
                let intersectionSize = 0;
                for (let i = 0; i < secondSanitized.length - 1; i += 1) {
                    const bigram = secondSanitized.substr(i, 2);
                    const count = firstBigrams.has(bigram) ? firstBigrams.get(bigram) : 0;
                    if (count > 0) {
                        firstBigrams.set(bigram, count - 1);
                        intersectionSize += 1;
                    }
                }
                return ((2.0 * intersectionSize) / (firstSanitized.length + secondSanitized.length - 2)) <= 0.75;
            }
        }
    }, Array.indexOf || (Array.prototype.indexOf = function (a) {
        var b;
        for (b = 0; b < this.length; b++) if (this[b] === a) return b;
        return -1
    })
}();
var __extends = this && this.__extends || function (a, b) {
    function d() {
        this.constructor = a
    }

    for (var c in b) b.hasOwnProperty(c) && (a[c] = b[c]);
    a.prototype = null === b ? Object.create(b) : (d.prototype = b.prototype, new d)
}, AbstractObserver = function () {
    function a() {
        this._weight = 0
    }

    return Object.defineProperty(a.prototype, "weight", {
        get: function () {
            return this._weight
        }, set: function (a) {
            this._weight = a
        }, enumerable: !0, configurable: !0
    }), a
}(), AlphabeticOnly = function (a) {
    function b(b) {
        void 0 === b && (b = 0), a.call(this), this._weight = b
    }

    return __extends(b, a), b.prototype.validate = function (a) {
        return /^[a-zA-ZäöüÃ„Ã–Ü]*$/.test(a)
    }, b.prototype.score = function (a) {
        return a.match(/^[a-zA-ZäöüÃ„Ã–Ü]*$/) ? -this._weight : 0
    }, b
}(AbstractObserver), ConsecutivelyLowercase = function (a) {
    function b(b) {
        void 0 === b && (b = 0), a.call(this), this._weight = b
    }

    return __extends(b, a), b.prototype.validate = function (a) {
        var b = new RegExp("[a-zäöü]{2}", "g");
        return !!a.match(b)
    }, b.prototype.score = function (a) {
        var b = new RegExp("[a-zäöü]{2}", "g"), c = a.match(b);
        return c ? -c.length * this._weight : 0
    }, b
}(AbstractObserver), ConsecutivelyNumeric = function (a) {
    function b(b) {
        void 0 === b && (b = 0), a.call(this), this._weight = b
    }

    return __extends(b, a), b.prototype.validate = function (a) {
        var b = new RegExp("(?=([0-9]{2}))", "g");
        return !!a.match(b)
    }, b.prototype.score = function (a) {
        var b = new RegExp("(?=([0-9]{2}))", "g"), c = a.match(b);
        return c ? -c.length * this._weight : 0
    }, b
}(AbstractObserver), ConsecutivelySymbolic = function (a) {
    function b(b) {
        void 0 === b && (b = 0), a.call(this), this._weight = b
    }

    return __extends(b, a), b.prototype.validate = function (a) {
        var b = new RegExp("(?=([^a-zA-ZäöüÃ„Ã–Ü0-9]{2}))", "g");
        return !!a.match(b)
    }, b.prototype.score = function (a) {
        var b = new RegExp("(?=([^a-zA-ZäöüÃ„Ã–Ü0-9]{2}))", "g"), c = a.match(b);
        return c ? -c.length * this._weight : 0
    }, b
}(AbstractObserver), ConsecutivelyUppercase = function (a) {
    function b(b) {
        void 0 === b && (b = 0), a.call(this), this._weight = b
    }

    return __extends(b, a), b.prototype.validate = function (a) {
        var b = new RegExp("[A-ZÃ„Ã–Ü]{2}", "g");
        return !!a.match(b)
    }, b.prototype.score = function (a) {
        var b = new RegExp("[A-ZÃ„Ã–Ü]{2}", "g"), c = a.match(b);
        return c ? -c.length * this._weight : 0
    }, b
}(AbstractObserver), RepeatingLowercase = function (a) {
    function b(b) {
        void 0 === b && (b = 0), a.call(this), this._weight = b
    }

    return __extends(b, a), b.prototype.validate = function (a) {
        for (var b = 0, c = 0, d = a.length - 1; c < d; c++) /[a-zäöü]/.test(a[c]) && a[c] === a[c + 1] && b++;
        return b > 0
    }, b.prototype.score = function (a) {
        for (var b = 0, c = 0, d = a.length - 1; c < d; c++) /[a-zäöü]/.test(a[c]) && a[c] === a[c + 1] && b++;
        return b > 0 ? -b * this._weight : 0
    }, b
}(AbstractObserver), RepeatingUppercase = function (a) {
    function b(b) {
        void 0 === b && (b = 0), a.call(this), this._weight = b
    }

    return __extends(b, a), b.prototype.validate = function (a) {
        for (var b = 0, c = 0, d = a.length - 1; c < d; c++) /[A-ZÃ„Ã–Ü]/.test(a[c]) && a[c] === a[c + 1] && b++;
        return b > 0
    }, b.prototype.score = function (a) {
        for (var b = 0, c = 0, d = a.length - 1; c < d; c++) /[A-ZÃ„Ã–Ü]/.test(a[c]) && a[c] === a[c + 1] && b++;
        return b > 0 ? -b * this._weight : 0
    }, b
}(AbstractObserver), RepeatingNumeric = function (a) {
    function b(b) {
        void 0 === b && (b = 0), a.call(this), this._weight = b
    }

    return __extends(b, a), b.prototype.validate = function (a) {
        for (var b = 0, c = 0, d = a.length - 1; c < d; c++) /[0-9]/.test(a[c]) && a[c] === a[c + 1] && b++;
        return b > 0
    }, b.prototype.score = function (a) {
        for (var b = 0, c = 0, d = a.length - 1; c < d; c++) /[0-9]/.test(a[c]) && a[c] === a[c + 1] && b++;
        return b > 0 ? -b * this._weight : 0
    }, b
}(AbstractObserver), RepeatingSymbolic = function (a) {
    function b(b) {
        void 0 === b && (b = 0), a.call(this), this._weight = b
    }

    return __extends(b, a), b.prototype.validate = function (a) {
        for (var b = 0, c = 0, d = a.length - 1; c < d; c++) /[^a-zA-ZäöüÃ„Ã–Ü0-9]/.test(a[c]) && a[c] === a[c + 1] && b++;
        return b > 0
    }, b.prototype.score = function (a) {
        for (var b = 0, c = 0, d = a.length - 1; c < d; c++) /[^a-zA-ZäöüÃ„Ã–Ü0-9]/.test(a[c]) && a[c] === a[c + 1] && b++;
        return b > 0 ? -b * this._weight : 0
    }, b
}(AbstractObserver), AdjacentCharacters = function (a) {
    function b(b) {
        void 0 === b && (b = 0), a.call(this), this._weight = b, this._adjacentCharacters = [["q", "w", "e", "r", "t", "z", "u", "i", "o", "p", "ü"], ["a", "s", "d", "f", "g", "h", "j", "k", "l", "ö", "ä"], ["y", "x", "c", "v", "b", "n", "m"]]
    }

    return __extends(b, a), b.prototype.validate = function (a) {
        if (a.length < 3) return !1;
        for (var b = 1, c = a.length - 1; b < c; b++) for (var d = 0, e = this._adjacentCharacters.length; d < e; d++) {
            var f = this._adjacentCharacters[d].indexOf(a[b]);
            if (a[b - 1].toLowerCase() === this._adjacentCharacters[d][f - 1] && a[b + 1].toLowerCase() === this._adjacentCharacters[d][f + 1] || a[b + 1].toLowerCase() === this._adjacentCharacters[d][f - 1] && a[b - 1].toLowerCase() === this._adjacentCharacters[d][f + 1]) return !0
        }
        return !1
    }, b.prototype.score = function (a) {
        var b = 0;
        if (a.length < 3) return 0;
        for (var c = 1, d = a.length - 1; c < d; c++) for (var e = 0, f = this._adjacentCharacters.length; e < f; e++) {
            var g = this._adjacentCharacters[e].indexOf(a[c]);
            (a[c - 1].toLowerCase() === this._adjacentCharacters[e][g - 1] && a[c + 1].toLowerCase() === this._adjacentCharacters[e][g + 1] || a[c + 1].toLowerCase() === this._adjacentCharacters[e][g - 1] && a[c - 1].toLowerCase() === this._adjacentCharacters[e][g + 1]) && b++
        }
        return -b * this._weight
    }, b
}(AbstractObserver), Dictionary = function (a) {
    function b(b, c) {
        void 0 === b && (b = []), void 0 === c && (c = 0), a.call(this), this._dictionary = b, this._weight = c
    }

    return __extends(b, a), b.prototype.validate = function (a) {
        for (var b in this._dictionary) {
            if (new RegExp(this._dictionary[b], "ig").test(a)) return !0
        }
        return !1
    }, b.prototype.score = function (a) {
        var b = 0;
        for (var c in this._dictionary) {
            var d = new RegExp(this._dictionary[c], "ig"), e = a.match(d);
            e && e.forEach(function (a) {
                b += 2 * a.length
            })
        }
        return -b
    }, b
}(AbstractObserver), Length = function (a) {
    function b(b) {
        void 0 === b && (b = 0), a.call(this), this._weight = b
    }

    return __extends(b, a), b.prototype.validate = function (a) {
        return a.length > 0
    }, b.prototype.score = function (a) {
        return a.length * this._weight
    }, b
}(AbstractObserver), Lowercase = function (a) {
    function b(b) {
        void 0 === b && (b = 0), a.call(this), this._weight = b
    }

    return __extends(b, a), b.prototype.validate = function (a) {
        var b = new RegExp("[a-zäöü]", "g");
        return !!a.match(b)
    }, b.prototype.score = function (a) {
        var b = new RegExp("[a-zäöü]", "g"), c = a.match(b);
        return c ? (a.length - c.length) * this._weight : 0
    }, b
}(AbstractObserver), MinimumLength = function (a) {
    function b(b, c) {
        void 0 === c && (c = 0), a.call(this), this._mininumLength = b, this._weight = c
    }

    return __extends(b, a), b.prototype.validate = function (a) {
        return a.length >= this._mininumLength
    }, b.prototype.score = function (a) {
        return a.length * this._weight
    }, Object.defineProperty(b.prototype, "minimumLength", {
        get: function () {
            return this._mininumLength
        }, set: function (a) {
            this._mininumLength = a
        }, enumerable: !0, configurable: !0
    }), b
}(AbstractObserver), Numeric = function (a) {
    function b(b) {
        void 0 === b && (b = 0), a.call(this), this._weight = b
    }

    return __extends(b, a), b.prototype.validate = function (a) {
        var b = new RegExp("[0-9]", "g");
        return !!a.match(b)
    }, b.prototype.score = function (a) {
        var b = new RegExp("[0-9]", "g"), c = a.match(b);
        return c ? c.length * this._weight : 0
    }, b
}(AbstractObserver), NumericOnly = function (a) {
    function b(b) {
        void 0 === b && (b = 0), a.call(this), this._weight = b
    }

    return __extends(b, a), b.prototype.validate = function (a) {
        var b = new RegExp("^[0-9]*$", "g");
        return !!a.match(b)
    }, b.prototype.score = function (a) {
        var b = new RegExp("[0-9]", "g"), c = a.match(b);
        return c ? -c.length * this._weight : 0
    }, b
}(AbstractObserver), NumericSymbolBetween = function (a) {
    function b(b) {
        void 0 === b && (b = 0), a.call(this), this._weight = b
    }

    return __extends(b, a), b.prototype.validate = function (a) {
        var b = new RegExp("[^a-zA-ZäöüÃ„Ã–Ü]{1}", "g");
        return !!a.substr(1, a.length - 2).match(b)
    }, b.prototype.score = function (a) {
        var b = new RegExp("[^a-zA-ZäöüÃ„Ã–Ü]{1}", "g"), c = a.substr(1, a.length - 2).match(b);
        return c ? c.length * this._weight : 0
    }, b
}(AbstractObserver), Requirements = function (a) {
    function b(b, c, d) {
        void 0 === c && (c = 0), void 0 === d && (d = 100), a.call(this), this._weight = c, this._requirements = b, this._tolerance = d
    }

    return __extends(b, a), b.prototype.validate = function (a) {
        var b = [];
        return this._requirements.forEach(function (c) {
            c.validate(a) && b.push(c)
        }), b.length >= this._requirements.length / 100 * this._tolerance
    }, b.prototype.score = function (a) {
        return this._requirements.length * this._weight
    }, Object.defineProperty(b.prototype, "requirements", {
        get: function () {
            return this._requirements
        }, set: function (a) {
            this._requirements = a
        }, enumerable: !0, configurable: !0
    }), Object.defineProperty(b.prototype, "tolerance", {
        set: function (a) {
            this._tolerance = a
        }, enumerable: !0, configurable: !0
    }), b
}(AbstractObserver), Sequential = function (a) {
    function b(b) {
        void 0 === b && (b = 0), a.call(this), this._weight = b
    }

    return __extends(b, a), b.prototype.validate = function (a) {
        var b = [];
        a.split("").map(function (a) {
            b.push(a.charCodeAt(0))
        });
        for (var c = 0, d = b.length; c < d; c++) if (b[c - 1] && b[c + 1] && b[c - 1] === b[c] - 1 && b[c + 1] === b[c] + 1) return !0;
        return !1
    }, b.prototype.score = function (a) {
        var b = [], c = 0;
        a.split("").map(function (a) {
            b.push(a.charCodeAt(0))
        });
        for (var d = 0, e = b.length; d < e; d++) b[d - 1] && b[d + 1] && b[d - 1] === b[d] - 1 && b[d + 1] === b[d] + 1 && (c -= this._weight);
        return c
    }, b
}(AbstractObserver), Symbolic = function (a) {
    function b(b) {
        void 0 === b && (b = 0), a.call(this), this._weight = b
    }

    return __extends(b, a), b.prototype.validate = function (a) {
        var b = new RegExp("[^a-zA-ZäöüÃ„Ã–Ü0-9]+", "g");
        return !!a.match(b)
    }, b.prototype.score = function (a) {
        var b = new RegExp("[^a-zA-ZäöüÃ„Ã–Ü0-9]+", "g"), c = a.match(b);
        return c ? c.length * this._weight : 0
    }, b
}(AbstractObserver), Uppercase = function (a) {
    function b(b) {
        void 0 === b && (b = 0), a.call(this), this._weight = b
    }

    return __extends(b, a), b.prototype.validate = function (a) {
        var b = new RegExp("[A-ZÃ„Ã–Ü]", "g");
        return !!a.match(b)
    }, b.prototype.score = function (a) {
        var b = new RegExp("[A-ZÃ„Ã–Ü]", "g"), c = a.match(b);
        return c ? (a.length - c.length) * this._weight : 0
    }, b
}(AbstractObserver), SecondCure = function () {
    function a(a) {
        this._observer = a, this._weight = 0
    }

    return a.prototype.validate = function (a) {
        if (0 === a.length) return 0;
        var b = 0;
        return this._observer.forEach(function (c) {
            c.validate(a) && (b += c.score(a))
        }), this._weight = b, b
    }, a
}();

export default Check24.PasswordStrength;