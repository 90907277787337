// Remove undefined and null values from an object
export default function clean(obj) {
    for (const propName in obj) {
        if(!obj.hasOwnProperty(propName)) {
            continue;
        }
        if (obj[propName] === null || obj[propName] === undefined) {
            delete obj[propName];
        }
    }
}
