// FILE CREATED AUTOMATICALLY - DO NOT EDIT!

export const poJP = {
    short: "Zu kurz",
    weak: "Zu schwach",
    sufficient: "Ausreichend",
    strong: "Stark",
    veryStrong: "Sehr stark",
    short_pwstrong: "Zu kurz",
    weak_pwstrong: "Sehr schwach",
    sufficient_pwstrong: "Schwach",
    strong_pwstrong: "Stark",
    veryStrong_pwstrong: "Sehr stark"
};